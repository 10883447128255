import axios from 'axios'

const AXIOS = process.env.NODE_ENV === 'production' ? axios.create({ baseURL: `/api2` }) : axios.create({ baseURL: `/` })

export default {
  getCustomAccountSettings (accountuuid, customSetting) {
    return AXIOS.get('/getCustomSettings/' + accountuuid + '/' + customSetting)
  },
  setCustomAccountSettings (params) {
    return AXIOS.post('/setCustomSettings', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  checkDashboardStatus (accountuuid) {
    return AXIOS.get('/checkDashboardStatus/' + accountuuid)
  },
  setDashboardStatus (params) {
    return AXIOS.post('/setDashboardStatus', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  setDefaultMasterUser (params) {
    return AXIOS.post('/setDefaultMasterUser', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ======================== SaaS-Manager APIs ========================
  createSaasManager (params) {
    return AXIOS.post('/createSaasManager', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  checkSaasManager (accountuuid) {
    return AXIOS.get('/checkSaasManager/' + accountuuid)
  },
  requestNewIpRange (params) {
    return AXIOS.post('/requestNewIpRange', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getNewIPSForAccount (accountUUID, count) {
    return AXIOS.get('/getNewIPSForAccount/' + accountUUID + '/' + count)
  },
  // ======================== Proxy APIs ========================
  createProxy (params) {
    return AXIOS.post('/createProxy', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteProxy (params) {
    return AXIOS.post('/deleteProxy', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getProxySettings (params) {
    return AXIOS.post('/getProxySettings', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  setProxySettings (params) {
    return AXIOS.post('/setProxySettings', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  addProxyClient  (params) {
    return AXIOS.post('/addProxyClient', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getProxyClients (params) {
    return AXIOS.post('/getProxyClients', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  delProxyClient (params) {
    return AXIOS.post('/delProxyClient', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getDomainInfo (params) {
    return AXIOS.post('/getDomainInfo', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ======================== Acronis APIs ========================
  createAcronisTenant (params) {
    return AXIOS.post('/createacronistenant', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  generateOneTimeUrl (params) {
    return AXIOS.post('/getonetimeurl', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteAcronisUser (params) {
    return AXIOS.post('/deleteAcronisAccount', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  addAcronisVM (params) {
    return AXIOS.post('/addAcronisVM', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteAcronisVM (params) {
    return AXIOS.post('/deleteAcronisVM', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAcronisUser (params) {
    return AXIOS.post('/getAcronisUser', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAcronisVM (params) {
    return AXIOS.post('/getAcronisVM', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  addAcronisEndpoint (params) {
    return AXIOS.post('/addAcronisEndpoint', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteAcronisEndpoint (params) {
    return AXIOS.post('/deleteAcronisEndpoint', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAcronisEndpoint (params) {
    return AXIOS.post('/getAcronisEndpoints', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAcronisVMs (params) {
    return AXIOS.post('/getAcronisVMs', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  setCssMaintenance (params) {
    return AXIOS.post('/setAcronisEndpointMaintenance', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  uuid2InstanceName (params) {
    return AXIOS.post('/uuid2instancename', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getDiskofferingList () {
    return AXIOS.get('/diskoffering', {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ======================== ALERT Api's ========================
  createScheduler (params) {
    return AXIOS.post('/createScheduler', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  editScheduler (params) {
    return AXIOS.post('/editScheduler', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteAlert (params) {
    return AXIOS.post('/deleteAlert', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteSchedule (params) {
    return AXIOS.post('/deleteSchedule', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAlertList (vmID) {
    return AXIOS.get('/getAlertView/' + vmID)
  },
  addAlert (params) {
    return AXIOS.post('/saveAccountAlert', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  editAlert (params) {
    return AXIOS.post('/updateConfigAlert', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getSchedulesByAccount (accountID) {
    return AXIOS.get('/getScheduler/' + accountID)
  },
  getAlertLogs (vmID) {
    return AXIOS.get('/getLogs/' + vmID)
  },
  checkForTelegramUser (telegramUsername) {
    return AXIOS.get('/checkForTelegramUser/' + telegramUsername)
  },
  getSchedulesInUse (accountID) {
    return AXIOS.get('/areSchedulesInUse/' + accountID)
  },
  getPrometheusDiskNames (vmUUID) {
    return AXIOS.get('/getPrometheusDiskNames/' + vmUUID)
  },
  getChatIdOfExistingTelegramUser (telegramUsername) {
    return AXIOS.get('/getChatIdOfExistingTelegramUser/' + telegramUsername)
  },
  // ======================== VM Scheduling Timer ========================
  getInstanceTimer (vmUUID) {
    return AXIOS.get('/instancetimer/' + vmUUID)
  },
  updateInstanceTimer (params) {
    return AXIOS.put('/instancetimer', params)
  },
  postInstanceTimer (params) {
    return AXIOS.post('/instancetimer', params)
  },
  deleteInstanceTimer (vmUUID) {
    return AXIOS.delete('/instancetimer/' + vmUUID)
  },
  getTimerLogs (vmUUID) {
    return AXIOS.get('/timerlog/' + vmUUID)
  },
  getCurrentServerTime () {
    return AXIOS.get('/currentServerTime')
  },
  // ========================vGPU APIs ========================
  startVirtualMachine (params) {
    return AXIOS.post('/startVirtualMachine', params)
  },
  destroyVirtualMachine (params) {
    return AXIOS.post('/destroyVirtualMachine', params)
  },
  asyncJob (params) {
    return AXIOS.post('/queryAsyncJobResult', params)
  },

  // ======================== GPU apis ========================
  getGpuList () {
    return AXIOS.post('/listgputypes')
  },
  allocateGpu (params) {
    return AXIOS.post('/allocategpu', params)
  },
  deallocateGpu (params) {
    return AXIOS.post('/deallocategpu', params)
  },
  getAllocatedGpu (params) {
    return AXIOS.post('/getallocatedgpu', params)
  },
  restoreGpuSettings (params) {
    return AXIOS.post('/restoregpusettings', params)
  },
  requestUnavailableGpu (params) {
    return AXIOS.post('/requestUnavailableGpu', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },

  /* ======================== PArtner Portal APIs in API2 ======================== */
  createPartner (params) {
    return AXIOS.post('/insertPartner', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  enablePartner (params) {
    return AXIOS.post('/enablePartner', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  disablePartner (params) {
    return AXIOS.post('/disablePartner', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  setPartner (params) {
    return AXIOS.post('/setPartnerSubscription', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getPartnerSub (accountuuid) {
    return AXIOS.get('/getPartnerSubscription/' + accountuuid)
  },
  getDefaultPartnerSub (accountuuid) {
    return AXIOS.get('/getDefaultPartnerSubscription/' + accountuuid)
  },
  getAllPartnerPlans () {
    return AXIOS.get('/getAllPlans')
  },
  insertUpdateLicenseDiscountPlan (params) {
    return AXIOS.post('/insertUpdateLicenseDiscountPlan', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getAllLicenseDiscountPlan () {
    return AXIOS.get('/getAllLicenseDiscountPlan')
  },
  removeLicenseDiscountPlan (params) {
    return AXIOS.post('/removeLicenseDiscountPlan', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  checkLogin (params) {
    return AXIOS.post('/goCloudUI', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  checkSession (params) {
    return AXIOS.post('/checkSession', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ======================== check active session and refresh apis ========================
  checkActiveSession () {
    return AXIOS.get('/sessionCheck')
  },
  checkValidSession () {
    return AXIOS.get('/checkValidSession')
  },
  refreshSession () {
    return AXIOS.get('/refreshSession')
  },
  logoutLoginPortal (params) {
    return AXIOS.post('/logoutLoginPortal', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ======================== Kubernetes API2 Apis: ========================
  getK8sDeploymentList (params) {
    return AXIOS.post('/listk8sdeployments', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getK8sTemplateList (params) {
    return AXIOS.post('/listk8stemplates', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  createK8sDeployment (params) {
    return AXIOS.post('/createk8sdeployment', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteK8sDeployment (params) {
    return AXIOS.post('/deletek8sdeployment', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  updateK8sDeployment (params) {
    return AXIOS.post('/updatek8sdeployment', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  listFreeIps (params) {
    return AXIOS.post('/listFreeIpAddresses', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getK8sTemplateImages () {
    return AXIOS.get('/getK8sTemplateImages')
  },
  // ======================== cloudManagement APIs ===========================
  publicIPAdresses (accountUuid) {
    return AXIOS.get('/publicIPAdresses/' + accountUuid)
  },
  listFreePublicIPsSingleAcc (accountUuid) {
    return AXIOS.get('/listFreePublicIPsSingleAcc/' + accountUuid)
  },
  listAllSubaccounts (accountUuid) {
    return AXIOS.get('/listAllSubaccounts/' + accountUuid)
  },
  getTSPlusInformation (params) {
    return AXIOS.post('/getTSPlusInformation', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }, { responseType: 'blob' })
  },
  getExampleTsPlusFile () {
    return AXIOS.get('/getExampleTsPlusFile', {
      headers: { 'Content-Type': 'multipart/form-data' }
    }, { responseType: 'blob' })
  },
  GetAccountDetails (accountuuid) {
    return AXIOS.get('/GetAccountDetails/' + accountuuid)
  },
  createVmSnapshotApi2 (params) {
    return AXIOS.post('/createVmSnapshot', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ============================ distributors apis ==============================
  listDistributors () {
    return AXIOS.get('/listDistributors')
  },
  createDistributor (params) {
    return AXIOS.post('/createDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  editDistributor (params) {
    return AXIOS.post('/editDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteDistributor (params) {
    return AXIOS.post('/deleteDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  assignDistributor (params) {
    return AXIOS.post('/assignDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  getResellerDistributor (params) {
    return AXIOS.post('/getResellerDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  listAllAssignedDistributor (params) {
    return AXIOS.post('/listAllAssignedDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  removeResellerDistributor (params) {
    return AXIOS.post('/removeResellerDistributor', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  // ======================== distributor article APIs ========================
  createArticle (params) {
    return AXIOS.post('/createArticle', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  editArticle (params) {
    return AXIOS.post('/editArticle', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  deleteArticle (params) {
    return AXIOS.post('/deleteArticle', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  listArticlesByDistributor (distributorUuid) {
    return AXIOS.get('/listArticlesByDistributor/' + distributorUuid)
  },
  listAllArticles () {
    return AXIOS.get('/listAllArticles')
  },
  downloadDistributorCsv (distributorUuid) {
    return AXIOS.get('/csv/' + distributorUuid)
  },
  downloadDistributorFakturaCsv (params) {
    return AXIOS.post('/fakturaCsv', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  downloadDistributorUmsatzCsv (params) {
    return AXIOS.post('/artikelUmsatzCsv', params, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  }
}
