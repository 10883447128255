<template>
  <a-spin :spinning="loading">
    <div style="display: flex; justify-content: flex-end;">
  </div>
    <div :class="['card-grey',{'vpcInstanceView': this.$route.path.includes('/vpc/')}]" style="padding: 20px 0 20px 0; max-height: 730px;">
      <div style="position: relative;">
        <a-tabs class="monitoringTabs" type="card"  @change="handleTabChange">
          <a-tab-pane class="monitoringTabPane" key="cpuChartTab">
            <template #tab>
              <span>
                <i class='cloud-icon-monitor is--blue'></i>
                  Cpu Graph
              </span>
            </template>
            <div>
              <canvas id="CpuGraph" ref="cpuChart" width="400" height="200"></canvas>
            </div>
          </a-tab-pane>
          <a-tab-pane class="monitoringTabPane" key="memoryChartTab">
            <template #tab>
              <span>
                <i class='cloud-icon-monitor is--blue'></i>
                  Memory Graph
              </span>
            </template>
            <div>
              <canvas id="MemGraph" ref="memoryChart" width="400" height="200"></canvas>
            </div>
          </a-tab-pane>
          <a-tab-pane class="monitoringTabPane" key="diskUsageChartTab">
            <template #tab>
              <span>
                <i class='cloud-icon-monitor is--blue'></i>
                  Disk Usage
              </span>
            </template>
            <div>
              <canvas id="DiskUsage" ref="diskUsageChart" width="400" height="200"></canvas>
            </div>
          </a-tab-pane>
          <a-tab-pane class="monitoringTabPane" key="diskUtilChartTab">
            <template #tab>
              <span>
                <i class='cloud-icon-monitor is--blue'></i>
                  Disk Utilatizion
              </span>
            </template>
            <div>
              <canvas id="Utilatizion" ref="diskUtilChart" width="400" height="200"></canvas>
            </div>
          </a-tab-pane>
          <a-tab-pane class="monitoringTabPane" key="netChartTab">
            <template #tab>
              <span>
                <i class='cloud-icon-monitor is--blue'></i>
                  Network
              </span>
            </template>
            <div>
              <canvas id="Network" ref="netChart" width="400" height="200"></canvas>
            </div>
          </a-tab-pane>
        </a-tabs>
        <a-select
      style="position: absolute; right: 20px; top: 1px; width: 9em;"
      @change="onTimeChange"
      default-value="1h"
      size="middle">
      <a-select-option value="1h">
        {{ '1 ' + $t('label.hour') }}
      </a-select-option>
      <a-select-option value="24h">
        {{ '24 ' + $t('label.hours') }}
      </a-select-option>
      <a-select-option value="3d">
        {{ '3 ' + $t('label.days') }}
      </a-select-option>
      <a-select-option value="7d">
        {{ '7 ' + $t('label.days') }}
      </a-select-option>
      <a-select-option value="14d">
        {{ '14 ' + $t('label.days') }}
      </a-select-option>
      <a-select-option value="30d">
        {{ '30 ' + $t('label.days') }}
      </a-select-option>
    </a-select>
      </div>
    </div>
  </a-spin>
</template>

<script>
import api2 from '@/wpApi/api2'
import moment from 'moment'
import { Chart } from 'chart.js'
import ChartDatasourcePrometheusPlugin from 'chartjs-plugin-datasource-prometheus'

export default {
  name: 'VmMonitoring',
  components: { Chart, ChartDatasourcePrometheusPlugin, moment },
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      instanceName: '',
      cpuChart: null,
      memoryChart: null,
      diskUsageChart: null,
      diskUtilChart: null,
      netChart: null,
      startTimeRangeIndicator: undefined,
      activeTab: 'cpuChartTab'
    }
  },
  computed: {
    getCurrentDate: function () {
      var d = new Date()
      // d.subtract(1, 'hours')
      return d
    }
  },
  watch: {
    resource: {
      deep: true,
      async handler (newItem, oldItem) {
        if (!newItem || !newItem.id) {
          return
        }
        if (newItem.id === oldItem.id) {
          return
        }
        await this.getInstanceName()
        this.initChart(this.activeTab)
      }
    }
  },
  async mounted () {
    await this.getInstanceName()
    this.initChart(this.activeTab)
  },
  methods: {
    onTimeChange (value) {
      this.startTimeRangeIndicator = value
      this.initChart(this.activeTab)
    },
    async getInstanceName () {
      var uuidParams = new FormData()
      uuidParams.append('uuid', this.$route.path.startsWith('/vpc/') ? this.resource.id : this.$route.params.id)
      await api2.uuid2InstanceName(uuidParams).then(response => {
        if (response.data && response.data.status && response.data.status === 'error') {
          this.instanceName = 'noInstanceName'
        } else if (response.data && response.data.status && response.data.status === 'ok') {
          this.instanceName = response.data.instancename
        }
      }).catch((error) => {
        this.instanceName = 'noInstanceName'
        console.log(error)
        console.log('Instance Name of the VM could not be fetched!')
      })
    },
    handleTabChange (key) {
      this.activeTab = key // Aktualisiere den aktiven Tab
      this.$nextTick(() => { // Warte auf die nächste DOM-Aktualisierung
        this.initChart(key) // Zeichne das Diagramm für den neuen aktiven Tab
      })
    },
    initChart (tabKey) {
      var startTime = 0
      if (this.startTimeRangeIndicator === undefined) {
        startTime = -1 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '1h') {
        startTime = -1 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '24h') {
        startTime = -24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '3d') {
        startTime = -3 * 24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '7d') {
        startTime = -7 * 24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '14d') {
        startTime = -14 * 24 * 60 * 60 * 1000
      } else if (this.startTimeRangeIndicator === '30d') {
        startTime = -30 * 24 * 60 * 60 * 1000
      }

      if (tabKey === 'cpuChartTab') {
        const canvas = this.$refs.cpuChart
        if (canvas) {
          const cpu = canvas.getContext('2d')
          this.cpuChart = new Chart(cpu, {
            type: 'line',
            plugins: [ChartDatasourcePrometheusPlugin],
            options: {
              legend: {
                display: false
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) => {
                    return 'CPU Usage: ' + data.datasets[0].data[tooltipItems.index].y + ' %'
                  }
                }
              },
              title: {
                display: true,
                text: 'CPU usage in %'
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      max: 100
                    }
                  }
                ]
              },
              plugins: {
                'datasource-prometheus': {
                  prometheus: {
                    endpoint: 'https://cloud.mitteldeutsche-it.de',
                    baseURL: '/metrics/api/v1'
                  },
                  query: 'round(100 * sum(irate(libvirt_domain_info_cpu_time_seconds_total{domain="' + this.instanceName + '"}[5m])) by (domain) / sum(libvirt_domain_info_virtual_cpus{domain="' + this.instanceName + '"}) by (domain) )',
                  timeRange: {
                    type: 'relative',
                    start: startTime,
                    end: 0
                  },
                  fill: true,
                  fillGaps: true,
                  stepped: true,
                  tension: 0
                }
              }
            }
          })
        }
      } else if (tabKey === 'memoryChartTab') {
        const canvas = this.$refs.memoryChart
        if (canvas) {
          const mem = canvas.getContext('2d')
          this.memoryChart = new Chart(mem, {
            type: 'line',
            plugins: [ChartDatasourcePrometheusPlugin],
            options: {
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) => {
                    var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024 / 1024 / 1024
                    return myval.toFixed(2) + ' GB'
                  }
                }
              },
              legend: {
                display: false
              },
              title: {
                display: true,
                text: 'Memory usage'
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: function (label) {
                        var num = label / 1024 / 1024 / 1024
                        return num.toFixed(2) + 'GB'
                      },
                      beginAtZero: true
                    }
                  }
                ]
              },
              plugins: {
                'datasource-prometheus': {
                  prometheus: {
                    endpoint: 'https://cloud.mitteldeutsche-it.de',
                    baseURL: '/metrics/api/v1'
                  },
                  query: ['sum(libvirt_domain_info_maximum_memory_bytes{domain=~"' + this.instanceName + '"} - libvirt_domain_memory_stats_usable_bytes{domain=~"' + this.instanceName + '"}) by (domain)', 'sum(libvirt_domain_info_maximum_memory_bytes{domain=~"' + this.instanceName + '"}) by (domain)'],
                  timeRange: {
                    type: 'relative',
                    start: startTime,
                    end: 0
                  },
                  fill: true,
                  fillGaps: true,
                  stepped: true,
                  tension: 0
                }
              }
            }
          })
        }
      } else if (tabKey === 'diskUsageChartTab') {
        const canvas = this.$refs.diskUsageChart
        if (canvas) {
          const disk = canvas.getContext('2d')
          this.diskUsageChart = new Chart(disk, {
            type: 'line',
            plugins: [ChartDatasourcePrometheusPlugin],
            options: {
              legend: {
                display: true
              },
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) => {
                    var mystr = data.datasets[tooltipItems.datasetIndex].label.replace('{', '').replace('}', '').replace(/"/g, '').split('=')
                    var newlabel = mystr[0] + ' (' + mystr[1] + '): '
                    var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024 / 1024 / 1024
                    return newlabel + myval.toFixed(2) + ' GB'
                  }
                }
              },
              title: {
                display: true,
                text: 'Disk usage'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function (label) {
                      var num = label / 1024 / 1024 / 1024
                      return num.toFixed(2) + ' GB'
                    },
                    beginAtZero: true
                  }
                }]
              },
              plugins: {
                'datasource-prometheus': {
                  prometheus: {
                    endpoint: 'https://cloud.mitteldeutsche-it.de',
                    baseURL: '/metrics/api/v1'
                  },
                  query: ['sum(label_replace(libvirt_domain_block_stats_capacity_bytes{domain=~"' + this.instanceName + '"}, "Max", "$1", "target_device", "(.*)")) by (Max)', 'sum(label_replace(libvirt_domain_block_stats_allocation {domain=~"' + this.instanceName + '"}, "Used", "$1", "target_device", "(.*)")) by (Used)'],
                  timeRange: {
                    type: 'relative',
                    start: startTime,
                    end: 0
                  },
                  fill: true,
                  fillGaps: true,
                  stepped: true,
                  tension: 0
                }
              }
            }
          })
        }
      } else if (tabKey === 'diskUtilChartTab') {
        const canvas = this.$refs.diskUtilChart
        if (canvas) {
          const disk2 = canvas.getContext('2d')
          this.diskUtilChart = new Chart(disk2, {
            type: 'line',
            plugins: [ChartDatasourcePrometheusPlugin],
            options: {
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) => {
                    var mystr = data.datasets[tooltipItems.datasetIndex].label.replace('{', '').replace('}', '').replace(/"/g, '').split('=')
                    var newlabel = mystr[0] + ' (' + mystr[1] + '): '
                    var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024
                    var mylabel = newlabel + myval.toFixed(2) + ' kB'
                    return mylabel
                  }
                }
              },
              title: {
                display: true,
                text: 'Disk Read/Write utilization'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function (label) {
                      var num = label / 1024
                      return num.toFixed(2) + ' kb'
                    }
                  }
                }]
              },
              plugins: {
                'datasource-prometheus': {
                  prometheus: {
                    endpoint: 'https://cloud.mitteldeutsche-it.de',
                    baseURL: '/metrics/api/v1'
                  },
                  query: ['sum(label_replace(rate(libvirt_domain_block_stats_read_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "Read", "$1", "target_device", "(.*)")) by (Read)', 'sum(label_replace(rate(libvirt_domain_block_stats_write_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "Write", "$1", "target_device", "(.*)")) by (Write)'],
                  timeRange: {
                    type: 'relative',
                    start: startTime,
                    end: 0
                  },
                  fill: true,
                  fillGaps: true,
                  stepped: true,
                  tension: 0
                }
              }
            }
          })
        }
      } else if (tabKey === 'netChartTab') {
        const canvas = this.$refs.netChart
        if (canvas) {
          const net = canvas.getContext('2d')
          this.netChart = new Chart(net, {
            type: 'line',
            plugins: [ChartDatasourcePrometheusPlugin],
            options: {
              tooltips: {
                callbacks: {
                  label: (tooltipItems, data) => {
                    var mystr = data.datasets[tooltipItems.datasetIndex].label.replace('{', '').replace('}', '').replace(/"/g, '').split('=')
                    var newlabel = mystr[1] + ' (' + mystr[0] + '): '
                    var myval = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].y / 1024
                    var mylabel = newlabel + myval.toFixed(2) + ' kB'
                    return mylabel
                  }
                }
              },
              title: {
                display: true,
                text: 'Network usage TX/RX in kB'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    callback: function (label) {
                      var num = label / 1024
                      return num.toFixed(2) + ' kb'
                    }
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Network TX/RX in kbyte'
                  }
                }]
              },
              plugins: {
                'datasource-prometheus': {
                  prometheus: {
                    endpoint: 'https://cloud.mitteldeutsche-it.de',
                    baseURL: '/metrics/api/v1'
                  },
                  query: ['sum(label_replace(rate(libvirt_domain_interface_stats_transmit_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "TX", "$1", "target_device", "(.*)")) by (TX)', 'sum(label_replace(rate(libvirt_domain_interface_stats_receive_bytes_total{domain=~"' + this.instanceName + '"}[5m]), "RX", "$1", "target_device", "(.*)")) by (RX)'],
                  timeRange: {
                    type: 'relative',
                    start: startTime,
                    end: 0
                  },
                  fill: true,
                  fillGaps: true,
                  stepped: true,
                  tension: 0
                }
              }
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">

.title {
  margin-bottom: 5px;
  font-weight: bold;
}
.monitoringTabs .ant-tabs-nav .ant-tabs-tab {
    padding:0;
}
.monitoringTabs .ant-tabs-bar {
  padding-left: 20px;
}
.monitoringTabs.ant-tabs.ant-tabs-card .ant-tabs-tab{
    border-radius: 15px 15px 0 0 !important;
  }
.monitoringTabs.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #4a7bae
}
.monitoringTabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: #4a7bae
  }
.monitoringTabPane {
  width: 100%;
  border-top: 1px solid #b5b5b5;
  padding: 0 20px 0 20px;
}

.activatePbsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12em;
  margin-top: 20px;
}

.shieldIcon {
  width:1.5rem;
  height:1.7rem;
  margin-left: 10px;
}
</style>
