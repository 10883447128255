// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        @finish="handleSubmit"
        layout="vertical">
        <a-form-item name="name" ref="name">
          <template #label>
            <tooltip-label :title="$t('label.name')" :tooltip="apiParams.name.description"/>
          </template>
          <a-input
            v-model:value="form.name"
            :placeholder="apiParams.name.description"/>
        </a-form-item>
        <a-form-item name="includememory" ref="includememory">
          <template #label>
            <tooltip-label :title="$t('label.snapshotmemory')" :tooltip="apiParams.snapshotmemory.description"/>
          </template>
          <a-switch
            v-model:checked="form.includememory"
            :placeholder="apiParams.snapshotmemory.description"/>
        </a-form-item>

        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { ref, reactive, toRaw } from 'vue'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import api2 from '@/wpApi/api2'
import wpapi from '@/wpApi/myApi'

export default {
  name: 'CreateVmSnapshot',
  components: {
    TooltipLabel
  },
  props: {
    resource: {
      type: Object,
      required: true
    },
    fromVpcSettings: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      pods: [],
      clusters: [],
      hosts: [],
      podsLoading: false,
      clustersLoading: false,
      hostsLoading: false,
      loading: false,
      getVgpuInfoResponse: ''
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('createVMSnapshot')
  },
  created () {
    this.initForm()
    console.log(this.resource)
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        name: undefined,
        includememory: false
      })
      this.rules = reactive({
        name: [{ required: true, message: this.$t('label.required') }]
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)
        this.loading = true
        var params = new FormData()
        params.append('vmuuid', this.resource.id)
        params.append('snapshotname', values.name)
        params.append('includememory', values.includememory)
        api2.createVmSnapshotApi2(params).then(response => {
          const jobId = response.data
          this.$pollJob({
            jobId,
            callApi2: true,
            title: this.$t('label.action.vmsnapshot.create'),
            description: values.name,
            loadingMessage: `${this.$t('message.vmsnapshot.state.creating')} ${values.name}`,
            catchMessage: this.$t('error.fetching.async.job.result'),
            successMessage: `${this.$t('label.action.vmsnapshot.create') + ' - ' + this.$t('label.success')} ${values.name}`,
            successMethod: async result => {
              console.log('RESULT: ', result)
              const volumeId = this.resource.volumes.filter(x => x.type === 'ROOT')[0].id
              const vmId = this.resource.id
              const snapshotId = result.jobresult.vmsnapshot.id
              var wpApiParams = new FormData()
              wpApiParams.append('snapshotuuid', snapshotId)
              wpApiParams.append('vmuuid', vmId)
              wpApiParams.append('volumeuuid', volumeId)
              wpapi.createVmSnapshot(wpApiParams).then(response => {
                console.log('WP VM Snapshot was sucessfully created')
              })
              const message = `${this.$t('label.create.snapshot.for.volume')} ${volumeId} ${this.$t('label.with.snapshotid')} ${result.jobresult.vmsnapshot.displayname}`
              this.$notification.success({
                message: message,
                duration: 6
              })
              if (this.fromVpcSettings) {
                this.$emit('reloadVpcSettings')
              }
            }
          })
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('label.action.vmsnapshot.create') + ' - ' + this.$t('label.error'),
            description: error.response.data,
            duration: 0
          })
          this.loading = false
        }).finally(() => {
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>

<style scoped lang="less">
  .form-layout {
    width: 60vw;

    @media (min-width: 500px) {
      width: 450px;
    }
  }
</style>
