<template>
  <a-spin :spinning="loading" tip="Loading...">
<!--    <hr style="color: #3D7CAF">-->
    <div class="myContainer">
      <div class="chartContainer">
        <div class="hourlyChart" id="testChart"></div>
        <div class="buttonsAndSelections">
          <a-select
            @change="onResourceChange"
            style="z-index: 3; width: 14em; margin-bottom: 5px;"
            default-value="Resource"
            size="small">
            <a-select-option value="Resource">
              {{ $t('label.resource.infrastructure') }}
            </a-select-option>
            <a-select-option value="Lizenz">
              {{ $t('label.licences') }}
            </a-select-option>
          </a-select>
          <a-select
            v-if="showWhichRessource === 'Resource'"
            @change="selectChangeUsageType"
            style="width: 14em; margin-bottom: 5px;"
            :default-value="16"
            size="small">
            <a-select-option v-for="usageType in billingListFilteredResource" :key="usageType.usageType" :value="usageType.usageType">
              <span>{{ usageType.usageName === 'MEMORY' ? 'vRAM': usageType.usageName }}</span>
            </a-select-option>
          </a-select>
          <a-select
            v-if="showWhichRessource === 'Lizenz'"
            @change="selectChangeUsageType"
            style="width: 14em; margin-bottom: 5px;"
            :default-value="billingListFilteredLicense[0].usageType"
            size="small">
            <a-select-option v-for="usageType in billingListFilteredLicense" :key="usageType.usageType" :value="usageType.usageType">
              <span >{{ usageType.usageName }}</span>
            </a-select-option>
          </a-select>
          <a-select
            @change="onYearChange"
            style="width: 14em; margin-bottom: 5px;"
            :default-value="getCurrentYear"
            size="small">
            <a-select-option v-for="year in getCurrentYearList" :key="year" :value="year">
              {{ year }}
            </a-select-option>
          </a-select>
          <a-select
            size="small"
            @change="selectChangeMonth"
            style="width: 14em; margin-bottom: 5px;"
            :default-value="getCurrentMonth">
            <a-select-option value="January" :disabled="checkMonths('January')">
              {{ $t('label.January') }}
            </a-select-option>
            <a-select-option value="February" :disabled="checkMonths('February')">
              {{ $t('label.February') }}
            </a-select-option>
            <a-select-option value="March" :disabled="checkMonths('March')">
              {{ $t('label.March') }}
            </a-select-option>
            <a-select-option value="April" :disabled="checkMonths('April')">
              {{ $t('label.April') }}
            </a-select-option>
            <a-select-option value="May" :disabled="checkMonths('May')">
              {{ $t('label.May') }}
            </a-select-option>
            <a-select-option value="June" :disabled="checkMonths('June')">
              {{ $t('label.June') }}
            </a-select-option>
            <a-select-option value="July" :disabled="checkMonths('July')">
              {{ $t('label.July') }}
            </a-select-option>
            <a-select-option value="August" :disabled="checkMonths('August')">
              {{ $t('label.August') }}
            </a-select-option>
            <a-select-option value="September" :disabled="checkMonths('September')">
              {{ $t('label.September') }}
            </a-select-option>
            <a-select-option value="October" :disabled="checkMonths('October')">
              {{ $t('label.October') }}
            </a-select-option>
            <a-select-option value="November" :disabled="checkMonths('November')">
              {{ $t('label.November') }}
            </a-select-option>
            <a-select-option value="December" :disabled="checkMonths('December')">
              {{ $t('label.December') }}
            </a-select-option>
          </a-select>
          <a-date-picker
            size="small"
            style="width: 14em; margin-bottom: 5px;"
            format="DD.MM.YYYY"
            v-model:value="selectedDay"
            placeholder="Tag auswählen"
            :disabledDate="disabledDate"
            :defaultPickerValue="pickerDate"
            @change="onDateChange"
          />
          <div class="timePickers">
            <a-time-picker
              size="small"
              mode="time"
              v-model:value="startValue"
              :defaultValue="moment('00:00', 'HH:mm')"
              format="HH:mm"
              :minute-step="60"
              placeholder="Von"
              :open="openStartTimePicker"
              :disabled="selectedDay === null ? true : false"
              @change="onTimeChange"
              @openChange="handleStartOpenChange"
            />
            <a-time-picker
              size="small"
              mode="time"
              v-model:value="endValue"
              :defaultValue="moment('00:00', 'HH:mm')"
              format="HH:mm"
              :minute-step="60"
              placeholder="Bis"
              :disabledHours="getDisabledHours"
              :disabled="selectedDay === null || startValue === null ? true : false"
              :open="endOpen"
              @change="onTimeChange"
              @openChange="handleEndOpenChange"
            />
            <!-- <a-button type="primary" size="small" @click="onFilter">Filter Data</a-button> -->
          </div>
          <a-button style="width: 14em; margin-bottom: 10px;" type="primary" size="small" @click="resetFilter"><span style="font-size: 11px;">{{ $t('label.reset.days') }}</span></a-button>
          <div class="summedValues" v-if="billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0] !== undefined">
            <p style="font-weight: bold;">{{ $t('label.sum.monthly') + ':' }}</p>
            <a-tag a-tag color="red">{{ (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'MEMORY' ? (summedTotal / 1024).toFixed(2) : ( billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'NextGenerationFirewall' ? rightChartData.NextGenerationFirewall_RawCounter: (summedTotal === null ? summedTotal : summedTotal.toFixed(2)))) + (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'vCPU' ? ' [vCPU * h]' : billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'MEMORY' ? ' [GB * h]' : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? ' [GB * h]' : ' [Lizenzen * h]') }}</a-tag>
            <a-tag color="red">{{ (( billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'NextGenerationFirewall' ? rightChartData.NextGenerationFirewall_RawCounter: summedTotal) * 0.00138889 * ((1 - (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].discount / 100)) * (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].quotaTariff))).toFixed(2) + ' \u20AC' }}</a-tag>
            <div v-if="filteredSummedTotal !== null" style="margin-top: 20px;">
              <p style="font-weight: bold;">{{ $t('label.sum.daily') + ':' }}</p>
              <a-tag color="red">{{ (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'MEMORY' ? (filteredSummedTotal / 1024).toFixed(2) : filteredSummedTotal) + (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'vCPU' ? ' [vCPU * h]' : billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].usageName === 'MEMORY' ? ' [GB * h]' : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? ' [GB * h]' : ' [Lizenzen * h]') }}</a-tag>
              <a-tag color="red">{{ (filteredSummedTotal * 0.00138889 * ((1 - (billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].discount / 100)) * billingList.filter(x => x.usageType === selectedUsageType && x.month === getSelectedOrCurrentMonth)[0].quotaTariff)).toFixed(2) + ' \u20AC' }}</a-tag>
            </div>
          </div>
        </div>
      </div>
      <div class="totalCostChart" id="finalListChart"></div>
    </div>
  </a-spin>
</template>

<script>
// import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import wpapi from '../../wpApi/myApi'
import VueApexCharts from 'apexcharts'
export default {
  name: 'ApexChart',
  props: {
    finalChartCostSummary: {
      type: Array,
      required: true
    },
    billingList: {
      type: Array,
      required: true
    },
    accountUUID: {
      type: String,
      required: true
    },
    domainUUID: {
      type: String,
      required: true
    }
  },
  watch: {
    billingList: async function (newVal, oldVal) { // watch it
      this.renderEmptyChart()
      // this.selectedMonth = this.getCurrentMonth
      // this.selectedYear = this.getCurrentYear
      var month = this.selectedMonth
      /* if (this.selectedYear === this.getCurrentYear) {
        month = this.getCurrentMonth
      } else { month = 'January' } */
      await this.fetchChartData(this.selectedYear, month, this.selectedUsageType)
    },
    finalChartCostSummary: async function (newVal, oldVal) { // watch it
      // this.renderEmptyChart()
      // this.selectedMonth = this.getCurrentMonth
      // this.selectedYear = this.getCurrentYear
      await this.renderRightChart()
    }
  },
  computed: {
    billingListFilteredResource: function () {
      var filteredList = []
      for (var usageType of this.billingList.filter(x => x.month === 'January')) {
        if (this.infrastructureResourceStringList.includes(usageType.usageName)) {
          filteredList.push(usageType)
        }
      }
      return filteredList
    },
    billingListFilteredLicense: function () {
      var filteredList = []
      for (var usageType of this.billingList.filter(x => x.month === 'January')) {
        if (usageType.usageName !== 'VOLUME' && !this.infrastructureResourceStringList.includes(usageType.usageName)) {
          filteredList.push(usageType)
        }
      }
      return filteredList
    },
    getSelectedOrCurrentMonth: function () {
      if (this.selectedMonth === null) {
        return this.getCurrentMonth
      } else { return this.selectedMonth }
    },
    getCurrentMonth: function () {
      var d = new Date()
      return this.months[d.getMonth()]
    },
    getCurrentYear: function () {
      var d = new Date()
      return d.getFullYear()
    },
    getCurrentYearList: function () {
      // get current year and last year as a list
      var currentYear = this.getCurrentYear
      return [currentYear, currentYear - 1]
    }
  },
  data () {
    return {
      showTime: {
        format: 'HH',
        defaultValue: moment('00:00', 'HH:mm')
      },
      infrastructureResourceStringList: ['vCPU', 'MEMORY', 'SSD', 'HDD', 'TEMPLATE', 'ISO', 'SNAPSHOT', 'IP_ADDRESS', 'AcronisCSS', 'AcronisRBS', 'Cyber Cloud Endpoint', 'NextGenerationFirewall'],
      showWhichRessource: 'Resource',
      pickerDate: moment(),
      startValue: null,
      endValue: null,
      selectedDay: null,
      selectedYear: '',
      endOpen: false,
      chartData: [],
      filteredData: [],
      rightChartData: [],
      startDate: {},
      endDate: {},
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      selectedUsageType: 16,
      selectedMonth: null,
      domainID: this.$store.getters.userInfo.domainid,
      accountUuid: this.$store.getters.userInfo.accountid,
      inputList: [],
      summedInputList: [],
      summedTotal: null,
      filteredSummedTotal: null,
      loading: false,
      openStartTimePicker: false,
      options2: {
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: 'top'
            }
          }
        },
        colors: '#FFA131',
        dataLabels: {
          enabled: true,
          position: 'top',
          offsetY: -15,
          formatter: function (val) {
            if (val !== 0) {
              return val.toFixed(2) + ' \u20AC'
            }
          },
          style: {
            colors: ['#000000'],
            fontWeight: 'bold'
          }
        },
        noData: {
          text: this.loading === true ? 'Lade Daten...' : 'Keine Daten vorhanden!'
        },
        series: [{
          data: []
        }],
        title: {
          style: {
            fontWeight: 700
          }
        },
        subtitle: {
          style: {
            fontWeight: 700
          }
        },
        legend: {
          fontWeight: 700
        },
        chart: {
          id: 'finalListChart',
          fontFamily: 'Gill Sans, sans-serif',
          width: '100%',
          type: 'bar',
          toolbar: {
            show: false
          }
        },
        yaxis: {
          title: {
            text: '\u20AC'
          }
        }
      },
      options3: {
        dataLabels: {
          enabled: false,
          style: {
            fontWeight: 700
          }
        },
        noData: {
          text: this.loading === true ? 'Lade Daten...' : 'Keine Daten vorhanden!'
        },
        series: [{
          data: []
        }],
        title: {
          style: {
            fontWeight: 700
          }
        },
        subtitle: {
          style: {
            fontWeight: 700
          }
        },
        legend: {
          fontWeight: 700
        },
        chart: {
          id: 'testChart',
          width: '100%',
          type: 'line',
          fontFamily: 'Gill Sans, sans-serif',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 15,
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true,
              customIcons: []
            }
          }
        },
        yaxis: {
          title: {
            style: {
              fontWeight: 700
            }
          },
          labels: {
            style: {
              fontWeight: 700
            }
          }
        },
        xaxis: {
          title: {
            text: 'Zeit',
            style: {
              fontWeight: 700
            }
          },
          labels: {
            datetimeUTC: false
          },
          type: 'datetime'
        },
        tooltip: {
          x: {
            format: 'dd. MMM yyyy HH:mm'
          }
        }
      }
    }
  },
  async mounted () {
    this.renderEmptyChart()
    this.selectedMonth = this.getCurrentMonth
    this.selectedYear = this.getCurrentYear
    var month
    if (this.selectedYear === this.getCurrentYear) {
      month = this.getCurrentMonth
    } else { month = 'January' }
    await this.fetchChartData(this.selectedYear, month, this.selectedUsageType)
    this.renderRightChart()
  },
  methods: {
    moment,
    checkForChartElements () {
      if (this.$store.getters.getChartObject !== null) {
        this.$store.getters.getChartObject.destroy()
        this.$store.commit('setChartObject', null)
      }
      if (this.$store.getters.getTotalChartObject !== null) {
        this.$store.getters.getTotalChartObject.destroy()
        this.$store.commit('setTotalCostChartObject', null)
      }
    },
    checkMonths (month) {
      // only check if current year is selected
      if (this.selectedYear === this.getCurrentYear) {
        const inputMonth = this.months.indexOf(month)
        const currentMonth = this.months.indexOf(this.getCurrentMonth)
        if (currentMonth < inputMonth) {
          return true
        } else {
          return false
        }
      }
    },
    disabledDate (current) {
      var date = moment()
      date.year(this.selectedYear)
      date.month(this.selectedMonth)
      return current > date.endOf('month') || current < date.startOf('month')
    },
    handleStartOpenChange (open) {
      this.openStartTimePicker = open
      /*  if (!this.openStartTimePicker) {
        this.endOpen = true
      } */
    },
    handleEndOpenChange (open) {
      this.endOpen = open
    },
    selectChangeUsageType (value) {
      this.selectedUsageType = value
      this.fetchChartData(this.selectedYear, this.selectedMonth, value)
    },
    async selectChangeMonth (value) {
      this.loading = true
      this.selectedMonth = value
      if (this.selectedDay !== null) {
        this.selectedDay.month(value)
      }
      this.pickerDate.month(value)
      await this.fetchChartData(this.selectedYear, value, this.selectedUsageType)
      this.renderRightChart()
    },
    async onYearChange (value) {
      this.loading = true
      await this.$emit('emit-reload-final-chart-data', value)
      this.pickerDate.year(value)
      this.selectedYear = value
      if (this.selectedDay !== null) {
        this.selectedDay.year(value)
      }
      setTimeout(async () => {
        this.renderRightChart()
        await this.fetchChartData(value, this.selectedMonth, this.selectedUsageType)
      }, 1500)
    },
    onDateChange (value) {
      if (value === null) {
        this.startValue = null
        this.endValue = null
      }
      this.selectedDay = value
      if (this.startValue !== null && this.endValue !== null) {
        this.onFilter(true)
      } else {
        this.onFilter()
      }
    },
    onTimeChange () {
      if (this.endOpen) {
        this.endOpen = false
      }
      if (this.openStartTimePicker) {
        this.openStartTimePicker = false
      }
      if (this.startValue !== null && this.endValue !== null) {
        this.onFilter(true)
      }
    },
    getDisabledHours () {
      var hours = []
      for (var i = 0; i < (this.startValue !== null ? this.startValue.hour() : 24); i++) {
        hours.push(i)
      }
      return hours
    },
    onResourceChange (value) {
      this.showWhichRessource = value
      this.renderRightChart()
      this.selectChangeUsageType(this.billingListFilteredLicense[0].usageType)
    },
    resetFilter () {
      this.startValue = null
      this.endValue = null
      this.filteredSummedTotal = null
      this.selectedDay = null
      if (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0] !== undefined) {
        VueApexCharts.exec('testChart', 'updateOptions', {
          series: [{
            name: this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 16 ? (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [vCPU * h]') : this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 17 ? ('vRAM [GB * h]') : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [GB * h]') : (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [Lizenzen * h]'),
            data: this.inputList
          }],
          title: {
            text: this.selectedDay ? this.$t('label.resource.usage.on') + this.selectedDay.format('DD.MM.yyyy') : ((this.startValue && this.endValue) ? this.$t('label.resource.usage.selectedTime') : this.$t('label.resource.usage.in') + this.$t('label.' + this.selectedMonth) + ' ' + this.selectedYear)
          }
        })
      }
    },
    onFilter (isTimeSelected) {
      var oldData = []
      oldData = this.inputList
      this.filteredData = []
      var sum = 0
      for (var item in oldData) {
        var temp = {}
        var dataDateObj = new Date(oldData[item].x)
        dataDateObj = moment(dataDateObj, 'YYYY-MM-DD HH:mm:ss')
        if (isTimeSelected) {
          var startDate = this.selectedDay.clone()
          var endDate = this.selectedDay.clone()
          const startTime = startDate.set({ h: this.startValue.hour(), m: 0, s: 0 })
          const endTime = endDate.set({ h: this.endValue.hour(), m: 0, s: 0 })
          if ((dataDateObj.isBefore(startTime) === false && dataDateObj.isBefore(endTime) === true)) {
            temp.x = oldData[item].x
            temp.y = oldData[item].y
            this.filteredData.push(temp)
            sum += oldData[item].y
          }
        } else {
          if (dataDateObj.isSame(this.selectedDay, 'day') === true) {
            temp.x = oldData[item].x
            temp.y = oldData[item].y
            this.filteredData.push(temp)
            sum += oldData[item].y
          }
        }
      }
      this.filteredSummedTotal = sum
      const usageType = this.selectedUsageType
      if (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0] !== undefined) {
        VueApexCharts.exec('testChart', 'updateOptions', {
          series: [{
            name: this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 16 ? (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [vCPU * h]') : this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 17 ? ('vRAM [GB * h]') : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [GB * h]') : (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [Lizenzen * h]'),
            data: this.filteredData
          }],
          yaxis: {
            title: {
              text: this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 16 ? 'RawUsage [vCPU * h]' : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? 'RawUsage [GB * h]' : 'RawUsage [Lizenzen * h]',
              style: {
                fontWeight: 700
              }
            },
            labels: {
              formatter: function (value) {
                if (usageType === 17) {
                  return value.toFixed(2)
                } else { return value.toFixed(0) }
              }
            }
          },
          title: {
            text: isTimeSelected ? this.$t('label.resource.usage.on') + this.selectedDay.format('DD.MM.yyyy') + ' ' + startDate.format('HH:mm') + ' - ' + endDate.format('HH:mm') + this.$t('label.resource.time') : (this.selectedDay ? this.$t('label.resource.usage.on') + this.selectedDay.format('DD.MM.yyyy') : this.$t('label.resource.usage.in') + this.$t('label.' + this.selectedMonth) + ' ' + this.selectedYear)
          }
        })
      }
    },
    renderEmptyChart () {
      this.checkForChartElements()
      this.$store.commit('setChartObject', new VueApexCharts(document.querySelector('#testChart'), this.options3))
      this.$store.getters.getChartObject.render()

      this.$store.commit('setTotalCostChartObject', new VueApexCharts(document.querySelector('#finalListChart'), this.options2))
      this.$store.getters.getTotalChartObject.render()
    },
    async renderRightChart () {
      this.loading = true
      const finalList = await this.finalChartCostSummary.filter(x => x.Month === this.selectedMonth)[0]
      this.rightChartData = finalList
      var totalResourceCostData = []
      // this list contains all UsageType strings that should be displayed under "Infrastruktur Ressourcen"
      var infrastructureResourceStrings = ['vCPU, MEMORY, SSD, HDD, TEMPLATE, ISO, SNAPSHOT, IP_ADDRESS, AcronisCSS, AcronisRBS, Cyber Cloud Endpoint, NextGenerationFirewall']
      if (finalList !== undefined) {
        for (var usageType in finalList) {
          if (finalList[usageType] === 0 || typeof finalList[usageType] !== 'number') {
            continue
          }
          var temp = {}
          if (this.showWhichRessource === 'Resource') {
            if (infrastructureResourceStrings.some(el => el.includes(usageType.toString()))) {
              if (usageType.toString() === 'MEMORY') {
                // Rename MEMORY TO vRAM
                temp.y = finalList[usageType]
                temp.x = 'vRAM'
              } else if (usageType.toString() === 'AcronisCSS') {
                // Add the AcronisPBS Usagetype onto the Acronis CSS UsageType and rename the x label
                temp.y = finalList[usageType] + finalList.AcronisRBS + finalList['Cyber Cloud Endpoint']
                temp.x = 'Acronis CSS/RBS/CSM'
              } else if (usageType.toString() === 'AcronisRBS') {
                // skip Acronis PBS USage Type because its inside the Acronis CSS Usage Type for Display allready
                continue
              } else if (usageType.toString() === 'Cyber Cloud Endpoint') {
                // skip Acronis Maintenance USage Type because its inside the Acronis CSS Usage Type for Display allready
                continue
              } else {
                // for all other cases use the normal data
                temp.y = finalList[usageType]
                temp.x = usageType.toString()
              }
              totalResourceCostData.push(temp)
            }
          } else if (this.showWhichRessource === 'Lizenz') {
            infrastructureResourceStrings.push('VOLUME')
            if (!usageType.toString().includes('_RawCounter') && !usageType.toString().includes('TOTAL') && !infrastructureResourceStrings.some(el => el.includes(usageType.toString()))) {
              temp.x = usageType.toString()
              temp.y = finalList[usageType]
              totalResourceCostData.push(temp)
            }
          }
        }
      }
      totalResourceCostData = totalResourceCostData.sort((a, b) => a.y < b.y)
      VueApexCharts.exec('finalListChart', 'updateOptions', {
        series: [{
          name: 'Gesamtpreis',
          data: totalResourceCostData
        }],
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2) + ' \u20AC'
            }
          }
        },
        xaxis: {
          labels: {
            formatter: function (value) {
              if (value.length > 19) {
                return value.toString().substring(0, 19) + '...'
              } else { return value }
            }
          }
        },
        title: {
          text: this.$t('label.resource.totalCost') + this.$t('label.' + this.selectedMonth) + ' ' + this.selectedYear
        }
      })
      this.loading = false
    },
    async fetchChartData (year, month, usageType) {
      this.loading = true
      var params = new FormData()
      params.append('accountuuid', this.accountUUID)
      params.append('domainid', this.domainUUID)
      params.append('monthName', [month])
      params.append('year', year)
      params.append('usageType', usageType)
      await wpapi.fetchBillingChartData(params).then(response => {
        this.loading = true
        this.chartData = response.data
        this.inputList = []
        this.summedInputList = []
        var sum = 0
        // otherwise load Ajax Data as normal
        for (var item in this.chartData) {
          var temp = {}
          var summedTemp = {}
          // push to normal list
          temp.x = this.chartData[item].startDate
          if (usageType === 17) {
            temp.y = (this.chartData[item].rawUsage / 1024)
          } else { temp.y = this.chartData[item].rawUsage }
          this.inputList.push(temp)
          // push to List with summed values
          sum += this.chartData[item].rawUsage
          summedTemp.x = this.chartData[item].startDate
          summedTemp.y = sum
          this.summedInputList.push(summedTemp)
        }
        this.summedTotal = sum
        if (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0] !== undefined) {
          VueApexCharts.exec('testChart', 'updateOptions', {
            series: [{
              name: this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 16 ? (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [vCPU * h]') : this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 17 ? ('vRAM [GB * h]') : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [GB * h]') : (this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName + ' [Lizenzen * h]'),
              data: this.inputList
            }],
            yaxis: {
              title: {
                text: this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageType === 16 ? 'RawUsage [vCPU * h]' : this.infrastructureResourceStringList.includes(this.billingList.filter(x => x.usageType === this.selectedUsageType)[0].usageName) ? 'RawUsage [GB * h]' : 'RawUsage [Lizenzen * h]',
                style: {
                  fontWeight: 700
                }
              },
              labels: {
                formatter: function (value) {
                  if (usageType === 17) {
                    return value.toFixed(2)
                  } else { return value.toFixed(0) }
                }
              }
            },
            title: {
              text: this.selectedDay ? this.$t('label.resource.usage.on') + this.selectedDay.format('DD.MM.yyyy') : ((this.startValue && this.endValue) ? this.$t('label.resource.usage.on') + this.selectedDay.format('DD.MM.yyyy') + ' ' + this.startValue.format('HH:mm') + ' - ' + this.endValue.format('HH:mm') + this.$t('label.resource.time') : this.$t('label.resource.usage.in') + this.$t('label.' + this.selectedMonth) + ' ' + this.selectedYear)
            }
          })
        }
      })

      if (this.startValue !== null && this.endValue !== null) {
        this.onFilter(true)
      } else if (this.selectedDay !== null) {
        this.onFilter()
      }
      this.loading = false
    }
  }
}
</script>
<style>
.myContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

.chartContainer {
  display: grid;
  grid-template-columns: auto 240px;
  gap: 15px;
  width: 55%;
}
.hourlyChart {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
  background: #EEEEEE;
  padding: 20px;
}

.totalCostChart {
  width: 44.2%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
  background: #EEEEEE;
  padding: 20px;
  margin-left: 16px;
}

.buttonsAndSelections {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 200px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
  box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.59);
  background: #EEEEEE;
  padding: 20px;
  margin-right: 10px;
}

.timePickers {
  display: flex;
  flex-direction: row;
  margin-bottom: 1px;
  width: 14em;
}

.summedValues {
  text-align: left;
}

.ant-picker-footer {
  display: none;
}

.ant-btn-primary {
  background-color: #3D7CAF;
}

@media only screen and (max-width: 1220px) {
  .myContainer {
    flex-direction: column;
  }
  .chartContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
  .totalCostChart {
    width: 100%;
  }
  .hourlyChart {
    width: 80%;
  }
  .buttonsAndSelections {margin-right: 0}
  .totalCostChart {margin-left: 0;}
}
@media only screen and (max-width: 768px) {
  .accountDisplaySelection {
    width: 100%;
  }

  .quotaStatementContainer {
    flex-wrap: wrap;
  }

  .quotaStatement {
    margin-top: 20px;
  }
  .metricsContainer {flex-wrap: wrap;}

  .resourceList {
    width: 100% !important;
  }

  .domainMetricsTable {
    width: 100% !important;
  }
  .chartContainer {
    flex-wrap: wrap;
  }
  .hourlyChart {
    width: 100%;
  }
  .buttonsAndSelections {margin-right: 0}
  .totalCostChart {margin-left: 0; min-height: 300px}
}
</style>
