// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <a-form
        :ref="formRef"
        :model="form"
        :rules="rules"
        :loading="loading"
        layout="vertical"
        @finish="handleSubmit">
        <a-form-item ref="roleid" name="roleid">
          <template #label>
            <tooltip-label :title="$t('label.role')" :tooltip="apiParams.roleid.description"/>
          </template>
          <a-select
            v-model:value="form.roleid"
            :loading="roleLoading"
            :placeholder="apiParams.roleid.description"
            v-focus="true"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }">
            <a-select-option v-for="role in roles" :key="role.id">
              {{ role.name + ' (' + role.type + ')' }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- WP Implementation -->
        <a-form-item ref="account" name="account">
          <template #label>
            <tooltip-label :title="$t('label.account.name.of')" :tooltip="$t('label.account.name.of.tooltip')"/>
          </template>
          <a-input v-model:value="form.account" :placeholder="apiParams.account.description" />
        </a-form-item>
        <a-form-item ref="username" name="username">
          <template #label>
            <tooltip-label :title="$t('label.company.name')" :tooltip="$t('label.company.name.tooltip')"/>
          </template>
          <a-input
            v-model:value="form.username"
            placeholder="Company name for invoices" />
        </a-form-item>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="password" name="password">
              <template #label>
                <tooltip-label :title="$t('label.password')" :tooltip="$t('label.account.password.tooltip')"/>
              </template>
              <a-input-password
                v-model:value="form.password"
                :placeholder="apiParams.password.description"/>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item ref="confirmpassword" name="confirmpassword">
              <template #label>
                <tooltip-label :title="$t('label.confirmpassword')" :tooltip="$t('label.account.password.confirm.tooltip')"/>
              </template>
              <a-input-password
                v-model:value="form.confirmpassword"
                :placeholder="apiParams.password.description"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- WP Implementation -->
        <!-- Customer NR -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="customerID" name="customerID">
              <template #label>
                <tooltip-label :title="$t('label.customernumber')" :tooltip="$t('label.account.customerID.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.customerID"
                :placeholder="$t('label.customernumber')" />
            </a-form-item>
          </a-col>
        <!-- Contract Number-->
          <a-col :md="24" :lg="12">
            <a-form-item ref="contractNumber" name="contractNumber">
              <template #label>
                <tooltip-label :title="$t('label.contractnumber')" :tooltip="$t('label.account.contractNumber.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.contractNumber"
                :placeholder="$t('label.contractnumber')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- Street + Nr -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="17">
            <a-form-item ref="street" name="street">
              <template #label>
                <tooltip-label :title="$t('label.streetname.nr')" :tooltip="$t('label.account.street.tooltip')"/>
              </template>
              <a-input
              v-model:value="form.street"
                :placeholder="$t('label.streetname.nr')"/>
            </a-form-item>
          </a-col>
          <!-- Postal Code -->
          <a-col :md="24" :lg="7">
            <a-form-item ref="postalcode" name="postalcode">
              <template #label>
                <tooltip-label :title="$t('label.postalcode')" :tooltip="$t('label.account.postalcode.tooltip')"/>
              </template>
              <a-input
              v-model:value="form.postalcode"
                :placeholder="$t('label.postalcode')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- City -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="city" name="city">
              <template #label>
                <tooltip-label :title="$t('label.city')" :tooltip="$t('label.account.city.tooltip')"/>
              </template>
              <a-input
              v-model:value="form.city"
                :placeholder="$t('label.city')"/>
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item ref="country" name="country">
              <template #label>
                <tooltip-label :title="$t('label.country')" :tooltip="$t('label.account.country.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.country"
                :placeholder="$t('label.country')"/>
            </a-form-item>
          </a-col>
        </a-row>
        <!-- Email -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="24">
            <a-form-item ref="email" name="email">
              <template #label>
                <tooltip-label :title="$t('label.email')" :tooltip="$t('label.account.email.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.email"
                :placeholder="apiParams.email.description" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item ref="firstname" name="firstname">
              <template #label>
                <tooltip-label :title="$t('label.firstname')" :tooltip="$t('label.account.firstname.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.firstname"
                :placeholder="apiParams.firstname.description" />
            </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item ref="lastname" name="lastname">
              <template #label>
                <tooltip-label :title="$t('label.lastname')" :tooltip="$t('label.account.lastname.tooltip')"/>
              </template>
              <a-input
                v-model:value="form.lastname"
                :placeholder="apiParams.lastname.description" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item v-if="isAdminOrDomainAdmin()" ref="domainid" name="domainid">
          <template #label>
            <tooltip-label :title="$t('label.domainid')" :tooltip="apiParams.domainid.description"/>
          </template>
          <a-select
            :loading="domainLoading"
            v-model:value="form.domainid"
            :placeholder="apiParams.domainid.description"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }" >
            <a-select-option v-for="domain in domainsList" :key="domain.id" :label="domain.path || domain.name || domain.description">
              <span>
                <resource-icon v-if="domain && domain.icon" :image="domain.icon.base64image" size="1x" style="margin-right: 5px"/>
                <block-outlined v-else style="margin-right: 5px"/>
                {{ domain.path || domain.name || domain.description }}
              </span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item ref="timezone" name="timezone">
          <template #label>
            <tooltip-label :title="$t('label.timezone')" :tooltip="apiParams.timezone.description"/>
          </template>
          <a-select
            v-model:value="form.timezone"
            :loading="timeZoneLoading"
            :placeholder="apiParams.timezone.description"
            showSearch
            optionFilterProp="label"
            :filterOption="(input, option) => {
              return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }">
            <a-select-option v-for="opt in timeZoneMap" :key="opt.id">
              {{ opt.name || opt.description }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- WP Implementation -->
        <!-- <a-form-item ref="networkdomain" name="networkdomain">
          <template #label>
            <tooltip-label :title="$t('label.networkdomain')" :tooltip="apiParams.networkdomain.description"/>
          </template>
          <a-input
            v-model:value="form.networkdomain"
            :placeholder="apiParams.networkdomain.description" />
        </a-form-item> -->
        <a-row :gutter="12">
          <a-col :md="24" :lg="12">
            <a-form-item v-if="isAdmin()" ref="enablePartnerStatus" name="enablePartnerStatus">
              <template #label>
                <tooltip-label :title="$t('label.enable.partner.status')" :tooltip="$t('label.enable.partner.status.tooltip')"/>
              </template>
          <a-switch v-model:checked="form.enablePartnerStatus"></a-switch>
        </a-form-item>
          </a-col>
          <a-col :md="24" :lg="12">
            <a-form-item v-if="isAdmin() && form.enablePartnerStatus" ref="standardPartnerStatus" name="standardPartnerStatus">
              <template #label>
                <tooltip-label :title="$t('label.partner.status.standard')" :tooltip="$t('label.partner.status.standard.tooltip')"/>
              </template>
              <a-select
                v-model:value="form.standardPartnerStatus"
                :placeholder="$t('label.partner.status.standard')">
                  <a-select-option v-for="item in partnerStatusList" :key="item.ID">
                    <a-tag :color="item.color">{{ item.name }}</a-tag>
                  </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <!--<a-form-item ref="setCreateInvoiceStatus" name="setCreateInvoiceStatus">
          <template #label>
            <tooltip-label :title="$t('label.should.invoice.be.created')" :tooltip="$t('label.should.invoice.be.created.tooltip')"/>
          </template>
          <a-switch v-model:checked="form.setCreateInvoiceStatus"></a-switch>
        </a-form-item> -->
        <!-- /WP Implementation -->
        <div v-if="samlAllowed">
          <a-form-item :label="$t('label.samlenable')" ref="samlenable" name="samlenable">
            <a-switch v-model:checked="form.samlenable" />
          </a-form-item>
          <a-form-item v-if="form.samlenable" ref="samlentity" name="samlentity">
            <template #label>
              <tooltip-label :title="$t('label.samlentity')" :tooltip="apiParams.entityid.description"/>
            </template>
            <a-select
              v-model:value="form.samlentity"
              :loading="idpLoading"
              :placeholder="apiParams.entityid.description"
              showSearch
              optionFilterProp="label"
              :filterOption="(input, option) => {
                return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }">
              <a-select-option v-for="idp in idps" :key="idp.id">
                {{ idp.orgName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div :span="24" class="action-button">
          <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
          <a-button :loading="loading" ref="submit" type="primary" @click="handleSubmit">{{ $t('label.ok') }}</a-button>
        </div>
      </a-form>
    </a-spin>
  </div>
</template>
<script>
import { ref, reactive, toRaw } from 'vue'
import { api } from '@/api'
import api2 from '@/wpApi/api2'
import { timeZone } from '@/utils/timezone'
import debounce from 'lodash/debounce'
import ResourceIcon from '@/components/view/ResourceIcon'
import TooltipLabel from '@/components/widgets/TooltipLabel'
import wpapi from '@/wpApi/myApi'

export default {
  name: 'AddAccountForm',
  components: {
    TooltipLabel,
    ResourceIcon
  },
  props: {
    domainInput: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  watch: {
    domainInput: function (newItem, oldItem) {
      this.selectedDomain = newItem.id
    }
  },
  data () {
    this.fetchTimeZone = debounce(this.fetchTimeZone, 800)
    return {
      // WP Implementation
      emailRegex: /^[^@]+@[^@]{2,}\.[^@]{2,}$/,
      passwordRegexUpperCase: /^(?=.*?[A-Z]).*$/, // minimum 1 Upper Case Letter
      passwordRegexLowerCase: /^(?=.*?[a-z]).*$/, // minimum 1 Lower Case Letter
      passwordRegexDigit: /^(?=.*?\d).*$/, // minimum 1 Digit
      passwordRegexSpecialCharacter: /^(?=.*?[!?@#$%^&*()\-_=+{};:,<.>]).*$/, // minimum 1 Special Character
      passwordRegexRepeatCharacter: /^(?:(.)(?!\1{2}))+$/, // not more than 2 repeated Letters
      passwordRegexWrongSpecials: /[`´€µ°²³§[\]'"|,/ß~]/, // special Characters that are not allowed
      passwordRegexLength: /^([A-Za-z0-9!?@#$%^&*()\-_=+{};:,<.>]{8,})$/, // minimum 8 Chars
      customerIdRegex: /^[0-9]*$/, // only digits
      contractNumberRegex: /^[\u00C0-\u017Fa-zA-Z0-9_.-]*$/, // only letters, digits, -_.
      cityNameRegex: /^[\u00C0-\u017Fa-zA-Z0-9- ]*$/,
      specialChars: /^[\u00C0-\u017Fa-zA-Z0-9_. -]*$/,
      specialCharsMail: /[-_@.]/,
      // WP Implementation
      loading: false,
      domainLoading: false,
      domainsList: [],
      roleLoading: false,
      roles: [],
      timeZoneLoading: false,
      timeZoneMap: [],
      idpLoading: false,
      idps: [],
      partnerStatusList: []
    }
  },
  beforeCreate () {
    this.apiParams = this.$getApiParams('createAccount', 'authorizeSamlSso')
  },
  created () {
    this.initForm()
    this.fetchData()
  },
  computed: {
    samlAllowed () {
      return 'authorizeSamlSso' in this.$store.getters.apis
    }
  },
  methods: {
    initForm () {
      this.formRef = ref()
      this.form = reactive({
        domainid: this.$store.getters.userInfo.domainid,
        account: undefined,
        city: undefined,
        postalcode: undefined,
        street: undefined,
        country: undefined,
        customerID: undefined,
        contractNumber: undefined,
        enablePartnerStatus: false,
        standardPartnerStatus: undefined
      })
      this.rules = reactive({
        roleid: [{ required: true, message: this.$t('message.error.select') }],
        account: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateAccountName }
        ],
        username: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCompanyName }
        ],
        password: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validatePassword }
        ],
        confirmpassword: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateConfirmPassword }
        ],
        city: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCity }
        ],
        postalcode: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validatePlz }
        ],
        street: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateStreet }
        ],
        country: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCountry }
        ],
        customerID: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateCustomerID }
        ],
        contractNumber: [
          { required: false, message: '' },
          { validator: this.validateContractNumber }
        ],
        email: [
          { required: true, message: this.$t('message.error.required.input') },
          { validator: this.validateEmail }
        ],
        firstname: [{ required: true, message: this.$t('message.error.required.input') }],
        lastname: [{ required: true, message: this.$t('message.error.required.input') }],
        domain: [{ required: true, message: this.$t('message.error.select') }],
        standardPartnerStatus: [{ required: true, message: this.$t('message.error.select') }]
      })
    },
    fetchData () {
      this.fetchDomains()
      this.fetchRoles()
      this.fetchTimeZone()
      this.fetchPartnerSubscriptions()
      if (this.samlAllowed) {
        this.fetchIdps()
      }
    },
    isAdminOrDomainAdmin () {
      return ['Admin', 'DomainAdmin'].includes(this.$store.getters.userInfo.roletype)
    },
    isAdmin () {
      return ['Admin'].includes(this.$store.getters.userInfo.roletype)
    },
    isDomainAdmin () {
      return this.$store.getters.userInfo.roletype === 'DomainAdmin'
    },
    isValidValueForKey (obj, key) {
      return key in obj && obj[key] != null
    },
    validateAccountName (rule, value) {
      // const errorMessage = this.$t('message.validate.special.characters')
      var errorMessage = this.$t('message.input.account.username')
      var validated = true
      if (!this.specialChars.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.special') + this.$t('message.input.account.allowed')
      }
      if (value === undefined || value.length < 4) {
        validated = false
        errorMessage += this.$t('message.input.account.length')
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    validateCompanyName (rule, value) {
      var errorMessage = this.$t('message.input.account.firmname')
      var validated = true
      if (value === undefined || value.length < 4) {
        validated = false
        errorMessage += this.$t('message.input.account.firmname.length')
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    async validatePassword (rule, value) {
      var errorMessage = this.$t('message.input.account.password')
      var validated = true
      if (!this.passwordRegexLength.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.length')
      }
      if (!this.passwordRegexUpperCase.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.uppercase')
      }
      if (!this.passwordRegexLowerCase.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.lowercase')
      }
      if (!this.passwordRegexDigit.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.number')
      }
      if (!this.passwordRegexSpecialCharacter.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.special')
      }
      if (this.passwordRegexWrongSpecials.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.special.error')
      }
      if (!this.passwordRegexRepeatCharacter.test(value)) {
        validated = false
        errorMessage += this.$t('message.input.account.password.repetition')
      }
      if (value === undefined || this.form.account === undefined || value.toLowerCase().includes(this.form.account.toLowerCase())) {
        validated = false
        errorMessage += this.$t('message.input.account.password.contains.username')
      }
      if (validated) {
        return Promise.resolve()
      } else {
        return Promise.reject(errorMessage)
      }
    },
    async validateConfirmPassword (rule, value) {
      if (!value || value.length === 0) {
        return Promise.resolve()
      } else if (rule.field === 'confirmpassword') {
        const messageConfirm = this.$t('error.password.not.match')
        const passwordVal = this.form.password
        if (passwordVal && passwordVal !== value) {
          return Promise.reject(messageConfirm)
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.resolve()
      }
    },
    validateCustomerID (rule, value) {
      const errorMessage = this.$t('message.input.account.customerid')
      if (value !== undefined && this.customerIdRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateContractNumber (rule, value) {
      const errorMessage = this.$t('message.input.account.contractnumber')
      if (this.contractNumberRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateStreet (rule, value) {
      const errorMessage = this.$t('message.input.account.address')
      if (value !== undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validatePlz (rule, value) {
      const errorMessage = this.$t('message.input.account.postalcode')
      if (value !== undefined && value.length <= 8) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCity (rule, value) {
      const errorMessage = this.$t('message.input.account.city')
      if (value !== undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateCountry (rule, value) {
      const errorMessage = this.$t('message.input.account.country')
      if (value !== undefined && this.cityNameRegex.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    validateEmail (rule, value) {
      const errorMessage = this.$t('message.input.account.email')
      if (this.emailRegex.test(value) && this.specialCharsMail.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(errorMessage)
    },
    fetchPartnerSubscriptions () {
      api2.getAllPartnerPlans().then(response => {
        this.partnerStatusList = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchDomains () {
      this.domainLoading = true
      api('listDomains', {
        listAll: true,
        showicon: true,
        details: 'min'
      }).then(response => {
        this.domainsList = response.listdomainsresponse.domain || []
        if (this.$route.path.startsWith('/domain')) {
          this.form.domain = this.domainInput.id
        } else { this.form.domain = this.domainsList[0].id || '' }
      }).catch(error => {
        this.$notification.error({
          message: `${this.$t('label.error')} ${error.response.status}`,
          description: error.response.data.errorresponse.errortext
        })
      }).finally(() => {
        this.domainLoading = false
      })
    },
    fetchRoles () {
      this.roleLoading = true
      api('listRoles').then(response => {
        this.roles = response.listrolesresponse.role || []
        var filteredRoles
        if (this.isAdmin()) {
          const filteredRolesAdmin = this.roles.filter(role => role.name === 'ResellerAdmin' || role.name === 'ResellerCustomer' || role.name === 'SubdomainAdmin')
          filteredRoles = filteredRolesAdmin
        } else if (!this.isAdmin()) {
          const filteredRolesNotAdmin = this.roles.filter(role => role.name === 'ResellerCustomer' || role.name === 'SubdomainAdmin')
          filteredRoles = filteredRolesNotAdmin
        }
        if (filteredRoles.length > 0) {
          this.roles = filteredRoles
        }
        this.form.roleid = this.roles[0].id
      }).finally(() => {
        this.roleLoading = false
      })
    },
    fetchTimeZone (value) {
      this.timeZoneMap = []
      this.timeZoneLoading = true

      timeZone(value).then(json => {
        this.timeZoneMap = json
        this.timeZoneLoading = false
      })
    },
    fetchIdps () {
      this.idpLoading = true
      this.idps = []
      this.form.samlentity = ''
      this.idpLoading = false
      /* api('listIdps').then(response => {
        this.idps = response.listidpsresponse.idp || []
        this.form.samlentity = this.idps[0].id || ''
      }).finally(() => {
        this.idpLoading = false
      }) */
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.formRef.value.validate().then(() => {
        const values = toRaw(this.form)

        this.loading = true
        const params = {
          roleid: values.roleid,
          username: values.account,
          password: values.password,
          email: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          domainid: values.domainid
        }
        // WP Implementation
        var selectedDomainName = this.domainsList.filter(domain => domain.id === values.domainid).length === 0 ? values.domainid : this.domainsList.filter(domain => domain.id === values.domainid)[0].path.split('ROOT/')[1]
        var wpParams = new FormData()
        wpParams.append('customer', values.customerID)
        wpParams.append('country', values.country)
        wpParams.append('streetName', values.street)
        wpParams.append('plz', values.postalcode)
        wpParams.append('city', values.city)
        wpParams.append('customerName', values.username)
        wpParams.append('shortName', values.account.replace(/[._ \s]/g, ''))
        wpParams.append('contractNumber', values.contractNumber)
        // /WP Implementation
        /* if (this.isValidValueForKey(values, 'account') && values.account.length > 0) {
          params.account = values.account
        } */
        if (this.isValidValueForKey(values, 'timezone') && values.timezone.length > 0) {
          params.timezone = values.timezone
        }
        if (this.isValidValueForKey(values, 'networkdomain') && values.networkdomain.length > 0) {
          params.networkdomain = values.networkdomain
        }
        api('createAccount', {}, 'POST', params).then(async response => {
          this.$emit('refresh-data')
          this.$notification.success({
            message: this.$t('label.create.account'),
            description: `${this.$t('message.success.create.account')} ${params.username}`
          })
          const users = response.createaccountresponse.account.user
          // WP Implementation
          if (users) {
            var createdAccUUID = response.createaccountresponse.account.id
            // create the AcronisUserTenant with the information from the createAccount API
            var createAcronisUserParams = new FormData()
            createAcronisUserParams.append('tenant_companyname', selectedDomainName + '/' + values.account.replace(/[._ \s]/g, ''))
            createAcronisUserParams.append('tenant_address', values.street + ', ' + values.postalcode + ' ' + values.city + ', ' + values.country)
            createAcronisUserParams.append('tenant_email', values.email)
            createAcronisUserParams.append('tenant_phone', '0000000000')
            createAcronisUserParams.append('tenant_user_login', createdAccUUID)
            createAcronisUserParams.append('tenant_user_password', values.password)
            createAcronisUserParams.append('tenant_user_email', values.email)
            createAcronisUserParams.append('tenant_user_firstname', values.firstname)
            createAcronisUserParams.append('tenant_user_lastname', values.lastname)
            api2.createAcronisTenant(createAcronisUserParams).then(response => {
              this.$notification.success({
                message: `${this.$t('message.success.create.acronis.user')}`,
                duration: 0
              })
            }).catch(error => {
              console.log(error)
              this.$notification.error({
                message: `${this.$t('message.error.create.acronis.user.message')}`,
                description: `${this.$t('message.error.create.acronis.user.description')}  ${error.response.data}  ${this.$t('message.error.create.acronis.user.description2')}`,
                duration: 0
              })
            })
            // create entries for additional parameters needed for invoices in db for the new account
            wpParams.append('accountUUID', createdAccUUID)
            wpapi.createAccountWpData(wpParams).then(response => {
            }).catch(error => {
              console.log(error)
              this.$notification.error({
                message: `${this.$t('message.error.create.wpaccount.message')}`,
                description: `${this.$t('message.error.create.wpaccount.description')}`,
                duration: 0
              })
            })

            // set first user of new account as master user
            if (users.length > 0) {
              var userParams = new FormData()
              userParams.append('userUuid', users[0].id)
              api2.setDefaultMasterUser(userParams).then(response => {
              }).catch(error => {
                console.log(error)
              })
            }

            // call Partner Portal APIs if Partner Status os enabled and user is created
            if (values.enablePartnerStatus === true) {
              var partnerParams = new FormData()
              partnerParams.append('uuid', createdAccUUID)
              var updatePartnerSubParams = new FormData()
              updatePartnerSubParams.append('uuid', createdAccUUID)
              updatePartnerSubParams.append('planid', values.standardPartnerStatus)

              await api2.createPartner(partnerParams).then(response => {
              }).catch(error => {
                console.log(error)
                this.$notification.error({
                  message: `${this.$t('message.error.create.partnerprofile')}`,
                  description: error.response.data,
                  duration: 0
                })
              })
              await api2.enablePartner(partnerParams).then(response => {
              }).catch(error => {
                console.log(error)
                this.$notification.error({
                  message: `${this.$t('message.error.activate.partnerprofile')}`,
                  description: error.response.data,
                  duration: 0
                })
              })
              await api2.setPartner(updatePartnerSubParams).then(response => {
              }).catch(error => {
                console.log(error)
                this.$notification.error({
                  message: `${this.$t('message.error.set.partnerstatus')}`,
                  description: error.response.data,
                  duration: 0
                })
              })
            }
          }
          // /WP Implementation
          if (values.samlenable && users) {
            for (var i = 0; i < users.length; i++) {
              api('authorizeSamlSso', {
                enable: values.samlenable,
                entityid: values.samlentity,
                userid: users[i].id
              }).then(response => {
                this.$notification.success({
                  message: this.$t('label.samlenable'),
                  description: this.$t('message.success.enable.saml.auth')
                })
              }).catch(error => {
                this.$notification.error({
                  message: this.$t('message.request.failed'),
                  description: (error.response && error.response.headers && error.response.headers['x-description']) || error.message,
                  duration: 0
                })
              })
            }
          }
          this.closeAction()
        }).catch(error => {
          this.$notification.error({
            message: this.$t('message.request.failed'),
            description: (error.response && error.response.headers && error.response.headers['x-description']) || error.message,
            duration: 0
          })
        }).finally(() => {
          this.loading = false
        })
      }).catch(error => {
        this.formRef.value.scrollToField(error.errorFields[0].name)
      })
    },
    closeAction () {
      this.$emit('close-action')
    }
  }
}
</script>
<style scoped lang="less">
  .form-layout {
    width: 80vw;
    white-space: pre-wrap;

    @media (min-width: 600px) {
      width: 450px;
    }
  }
</style>
