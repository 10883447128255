// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
<template>
  <div class="form-layout" v-ctrl-enter="handleSubmit">
    <a-spin :spinning="loading">
      <div style="display: flex; justify-content: right;">
        <a-input-search
          v-model:value="searchInput"
          :placeholder="$t('label.search')"
          @change="handleSearch()"
          style="border-radius: 5px; margin-bottom: 10px; width: 50%;" >
        </a-input-search>
      </div>
      <a-table
        :loading="loading"
        :columns="gpuListColumns"
        :dataSource="gpuList"
        :pageSize="10"
        :pagination="this.gpuList.length > 10 ? true : false"
        :rowKey="record => record.id">
        <template #vramString="{ record }">
          <div>{{ record.vramString }}</div>
        </template>
        <template #available="{ record }">
          <div v-if="parseInt(record.available_count) > 3">
            <a-popover>
              <div class="available-fully"></div>
              <template #content>
                <div>{{ this.$t('label.available') }}</div>
              </template>
            </a-popover>
          </div>
          <div v-if="parseInt(record.available_count) === 1 || parseInt(record.available_count) === 2 || parseInt(record.available_count) === 3">
            <a-popover>
              <div class="available-partially"></div>
              <template #content>
                <div>{{ this.$t('label.available.partially') }}</div>
              </template>
            </a-popover>
          </div>
          <div v-if="parseInt(record.available_count) === 0">
            <a-popover>
              <div class="available-not"></div>
              <template #content>
                <div>{{ this.$t('label.available.soon') }}</div>
              </template>
            </a-popover>
          </div>
        </template>
        <template #select="{ record }">
          <a-radio
            v-if="parseInt(record.available_count) === 0 ? false : true"
            @click="updateGPUSelection(record)"
            :checked="selectedGPU != null && record.id === selectedGPU.id">
          </a-radio>
          <a-button v-else type="primary"  @click="requestGpuModal(record)">{{ $t("label.request")}}</a-button>
        </template>
      </a-table>
      <div :span="24" class="action-button">
        <a-button @click="closeAction">{{ $t('label.cancel') }}</a-button>
        <a-button :loading="loading" type="primary" :disabled="this.selectedGPU === null" @click="handleSubmit" ref="submit">{{ $t('label.ok') }}</a-button>
      </div>
    </a-spin>
    <a-modal
      v-model:visible="openGpuModal"
      centered
      @cancel="openGpuModal=false">
      <p> {{ $t('label.request.gpu') + this.requestedGpu }}</p>
      <template #footer style="text-align: right;">
        <a-button :loading="loading" type="primary" @click="openGpuModal=false">{{ this.$t('label.cancel') }}</a-button>
        <a-button :loading="loading" type="primary" @click="requestGpu">{{ this.$t('label.ok') }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import api2 from '@/wpApi/api2'
import wpapi from '@/wpApi/myApi'
import { api } from '@/api'

export default {
  name: 'AllocateVGPU',
  props: {
    resource: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedGPU: null,
      searchInput: '',
      filteredlist: [],
      loading: false,
      isos: [],
      gpuList: [],
      gpuListBackup: [],
      existingTags: [],
      subAccountUuid: '',
      gpuLicenseKey: 0,
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      accountUuidAsAdminLogin: '',
      openGpuModal: false,
      requestedGpu: '',
      gpuListColumns: [
        {
          title: this.$t('label.manufacturer'),
          dataIndex: 'manufacturer',
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer)
        },
        {
          title: this.$t('label.model'),
          dataIndex: 'model'
        },
        {
          title: 'VRAM',
          dataIndex: 'vram',
          slots: { customRender: 'vramString' },
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => this.sorting(a.vram, b.vram)
        },
        {
          title: this.$t('label.available'),
          dataIndex: 'available_count',
          slots: { customRender: 'available' },
          sortDirections: ['ascend', 'descend'],
          sorter: (a, b) => this.sorting(a.available_count, b.available_count)
        },
        {
          title: this.$t('label.select'),
          slots: { customRender: 'select' }
        }
      ]
    }
  },
  async created () {
    await this.fetchData()
  },
  mounted () {
    this.getAllTagsApi()
    this.fetchResellerCustomerAccounts()
    this.getAccountUuidIfAdminLogin()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await api2.getGpuList().then(response => {
        this.gpuList = response.data.gpu_type_list
        for (let i = 0; i < this.gpuList.length; i++) {
          this.gpuList[i].id = i
          this.gpuList[i].vramString = this.gpuList[i].vram + ' GB'
        }
        this.gpuListBackup = this.gpuList
        this.loading = false
      }).catch(error => {
        this.$notifyError(error)
        this.loading = false
      })
    },
    closeAction () {
      this.$emit('close-action')
    },
    fetchResellerCustomerAccounts () {
      if (this.resource.account && this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.path.startsWith('/vm/')) {
        if (this.resource.account !== this.accountName) {
          wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
            this.subAccountUuid = response.data.filter(x => x.accountName === this.resource.account)[0].uuid
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    getAllTagsApi () {
      wpapi.getAllTags().then(response => {
        var allTags = response.data
        var tempTransferInput = []
        for (var tag in allTags) {
          const data = {
            key: allTags[tag].id,
            title: allTags[tag].tagText,
            colors: allTags[tag].tagColor,
            types: allTags[tag].tagType
          }
          tempTransferInput.push(data)
        }
        this.existingTags = tempTransferInput
        // filter for tag Ids of GPU licenses
        this.gpuLicenseKey = this.existingTags.filter(tag => tag.title === 'GPU')[0].key
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      if (this.loading) return
      this.loading = true
      if (this.$store.getters.userInfo.roletype === 'Admin') {
        if (this.accountUuidAsAdminLogin === '') {
          this.$notification.error({
            message: this.$t('label.error'),
            description: this.$t('label.error.missingAccountuuid')
          })
          this.loading = false
          return
        }
      }
      var params = new FormData()
      params.append('vmuuid', this.resource.id)
      params.append('manufacturer', this.selectedGPU.manufacturer)
      params.append('model', this.selectedGPU.model)
      params.append('passthrough', this.selectedGPU.passthrough)
      params.append('vram', this.selectedGPU.vram)

      api2.allocateGpu(params).then(async response => {
        // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
        var licenseParams = new FormData()
        var licenseParams2 = new FormData()
        if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
          licenseParams.append('accountUuid', this.subAccountUuid)
          licenseParams2.append('accountUuid', this.subAccountUuid)
        } else if (this.$store.getters.userInfo.roletype === 'Admin') {
          licenseParams.append('accountUuid', this.accountUuidAsAdminLogin)
          licenseParams2.append('accountUuid', this.accountUuidAsAdminLogin)
        } else {
          licenseParams.append('accountUuid', this.accountUuid)
          licenseParams2.append('accountUuid', this.accountUuid)
        }

        licenseParams.append('counter', 1)
        licenseParams.append('tagId', this.gpuLicenseKey)
        licenseParams.append('templateId', this.resource.templateid)
        licenseParams.append('vmUUID', this.resource.id)
        licenseParams2.append('counter', 1)
        licenseParams2.append('tagId', this.selectedGPU.licensetagid)
        licenseParams2.append('templateId', this.resource.templateid)
        licenseParams2.append('vmUUID', this.resource.id)
        // call for "GPU" license
        await wpapi.createTagEntryForVm(licenseParams).then(response => {
        }).catch(error => {
          this.$notifyError(error)
        })
        // call for specific GPU license
        await wpapi.createTagEntryForVm(licenseParams2).then(response => {
        }).catch(error => {
          this.$notifyError(error)
        })
        this.$notification.success({
          message: this.$t('message.vpgu.attach.success')
        })
        this.loading = false
        this.closeAction()
        this.$emit('refresh-data')
      }).catch(error => {
        this.$notifyError(error)
        this.loading = false
        this.closeAction()
        this.$emit('refresh-data')
      })
    },
    updateGPUSelection (record) {
      this.selectedGPU = record
    },
    handleSearch () {
      this.filteredList = this.gpuListBackup.filter(x => (x.manufacturer + ' ' + x.model).toString().toLowerCase().includes(this.searchInput.toString().toLowerCase()))
      this.gpuList = this.filteredList

      if (this.searchInput === '') {
        this.gpuList = this.gpuListBackup
      }
    },
    sorting (a, b) {
      a = parseInt(a)
      b = parseInt(b)
      if (a > b) {
        return 1
      } else if (a === b) {
        return 0
      } else {
        return -1
      }
    },
    getAccountUuidIfAdminLogin () {
      if (this.$store.getters.userInfo.roletype === 'Admin') {
        api('listAccounts', {
          listAll: true,
          name: this.resource.account,
          domainid: this.resource.domainid
        }).then(response => {
          if (response.listaccountsresponse.account.length === 1) {
            this.accountUuidAsAdminLogin = response.listaccountsresponse.account[0].id
          }
        }).catch(error => {
          this.$notifyError(error)
        })
      }
    },
    requestGpuModal (record) {
      this.selectedGPU = null
      this.openGpuModal = true
      this.requestedGpu = record.manufacturer + ' ' + record.model + ' (' + record.vram + 'GB)'
    },
    requestGpu () {
      this.openGpuModal = false
      var params = new FormData()
      params.append('customer', this.accountName)
      params.append('email', this.$store.getters.userInfo.email)
      params.append('gpu', this.requestedGpu)
      params.append('firstname', this.$store.getters.userInfo.firstname)
      params.append('lastname', this.$store.getters.userInfo.lastname)
      api2.requestUnavailableGpu(params).then(response => {
        this.$notification.success({
          message: this.$t('label.request.success')
        })
        this.closeAction()
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: this.$t('label.error'),
          description: this.$t('label.request.fail')
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.form-layout {
  width: 80vw;
  @media (min-width: 700px) {
    width: 600px;
  }
}

.form {
  margin: 10px 0;
}

.available-fully {
  width: 12px;
  height: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background: #568B6B;
  box-shadow: 0px 0px 5px #568B6B
}

.available-partially {
  width: 12px;
  height: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background: rgb(245, 168, 24);
  box-shadow: 0px 0px 5px  rgb(245, 168, 24)
}
.available-not {
  width: 12px;
  height: 12px;
  margin-top: 5px;
  border-radius: 50%;
  background: #ff4d4f;
  box-shadow: 0px 0px 5px #ff4d4f
}
</style>
