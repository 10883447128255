// Licensed to the Apache Software Foundation (ASF) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The ASF licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.
// $message.success(`${$t('label.copied.clipboard')} : ${name}`)
<template>
  <a-spin :spinning="loading">
    <div class="spin-content " :title="title" id="infoCard">
      <!-- WP Implementation -->
      <div>
        <!-- Confirmation Modal -->
        <a-modal
          v-if="confirmationModal"
          style="top: 20px;"
          centered
          :confirmLoading="loading"
          :title="'Erfolgreich!'"
          :closable="false"
          :maskClosable="false"
          v-model:visible="confirmationModal"
        >
          <CheckOutlined :style="{fontSize: '24px', color: 'green'}"></CheckOutlined>
          <p style="margin-top: 20px;"> {{ confirmationMessage }}</p>
          <template #footer style="text-align: right;">
            <a-button id="confirmationOkayButton" type="primary" @click="confirmAndReload()">OK</a-button>
          </template>
        </a-modal>
        <a-spin :spinning="acronisUserLoading">
          <!-- Create Acronis User Modal -->
          <a-modal
            v-if="$route.path.startsWith('/account')"
            :title="'Erstelle Acronis User für ' + openAccount.name"
            v-model:visible="showCreateAcronisModal"
            :closable="true"
            :destroyOnClose="true"
            :confirmLoading="acronisUserLoading"
            @cancel="showCreateAcronisModal = false"
            @ok="handleCreateAcronisUserSubmit('add')"
            okText="OK"
            cancelText="Cancel"
            centered>
            <p>Der Acronis User wird mit folgenden Daten erstellt:</p>
            <span class="bold">{{ $t('label.id') + ': ' }}</span>
            <span>{{ openAccount.id }}</span>
            <br/>
            <span class="bold">{{ $t('label.company.name') + ': ' }}</span>
            <span>{{ openAccount.name }}</span>
            <br/>
            <span class="bold">{{ $t('label.name') + ': ' }}</span>
            <span>{{ openAccountUser.firstname + ' ' + openAccountUser.lastname }}</span>
            <br/>
            <span class="bold">{{ $t('label.address') + ': ' }}</span>
            <span>{{ wpAccountData.address + ', ' + wpAccountData.postalCode + ' ' + wpAccountData.city + ', ' + wpAccountData.country }}</span>
            <br/>
            <span class="bold">{{ $t('label.email') + ': ' }}</span>
            <span>{{ openAccountUser.email }}</span>
            <br/>
            <br/>
            <span>Wir benötigen noch Ihr Passwort, mit welchem Sie sich für den Acronis Account anmelden möchten.</span>
            <a-form
              v-ctrl-enter="handleCreateAcronisUserSubmit"
              :ref="formRefAddAcronisUser"
              :model="formAddAcronisUser"
              :rules="addAcronisUserRules"
              @finish="handleCreateAcronisUserSubmit"
              layout="vertical"
            >
              <a-form-item ref="pw" name="pw" :label="'Passwort'">
                <a-input-password
                  autoFocus
                  autocomplete="false"
                  placeholder="Password"
                  v-model:value="formAddAcronisUser.pw"/>
              </a-form-item>
              <a-form-item ref="confirmpw" name="confirmpw" :label="'Passwort bestätigen'">
                <a-input-password
                  placeholder="Confirm Password"
                  v-model:value="formAddAcronisUser.confirmpw"/>
              </a-form-item>
              <span v-if="correctPasswords === false" style="color: red">Die angegebenen Passwörter sind nicht identisch.</span>
            </a-form>
          </a-modal>
        </a-spin>
        <!-- Add Account Modal -->
        <a-modal
          v-model:visible="showAddAccountModal"
          :closable="true"
          @cancel="closeAction"
          centered
          :footer="null">
          <AddAccount
            :domainInput="resource"
            @close-action="closeAction"/>
        </a-modal>
        <!-- Add License Tags Modal -->
        <a-modal
          :title="$t('label.add.license')"
          @ok="handleLicenseSubmit('add')"
          @cancel="closeAction"
          okText="OK"
          cancelText="Cancel"
          v-model:visible="showAddLicenseModal"
          :closable="true"
          centered>
          <a-spin :spinning="modalLoading">
            <!-- Select for Template Tags -->
            <a-select
              id="addLicenseSelect"
              style="width: 55%;"
              :placeholder="$t('label.licence')"
              @change="handleChange"
              showSearch
              :filter-option="(input, option) => {
                  return  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }"
              v-if="['Admin'].includes($store.getters.userInfo.roletype) && resource.templatetype">
              <a-select-option
                v-for="tag in selectedTagInfoWithoutNoBill"
                :key="tag.key"
                :title="tag.title"
                :disabled="templateTags.length > 0 && (templateTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && templateTags.filter(x => x.tagType === 'OS').length >=1))">
                <a-tag style="cursor: not-allowed" v-if="templateTags.length > 0 && (templateTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && templateTags.filter(x => x.tagType === 'OS').length >=1))" :color="templateTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && templateTags.filter(x => x.tagType === 'OS').length >=1)? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                <a-tag v-else style="cursor: pointer" :color="tag.colors">{{ tag.title }}</a-tag>
              </a-select-option>
            </a-select>
            <!-- Select for All Tags as Admin-->
            <a-select
              id="addLicenseSelect"
              style="width: 55%;"
              :placeholder="$t('label.licence')"
              @change="handleChange"
              showSearch
              :filter-option="(input, option) => {
                  return  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }"
              v-else-if="['Admin'].includes($store.getters.userInfo.roletype)">
              <a-select-option
                v-for="tag in existingTags"
                :key="tag.key"
                :title="tag.title"
                :disabled="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)">
                <a-tag style="cursor: not-allowed" v-if="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                <a-tag v-else style="cursor: pointer" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 ? 'grey' : tag.colors">{{ tag.title }}</a-tag>
              </a-select-option>
            </a-select>
            <!-- Select for All Tags as Normal Account or Reseller-->
            <a-select
              id="addLicenseSelect"
              :placeholder="$t('label.licence')"
              style="width: 55%;"
              @change="handleChange"
              showSearch
              :filter-option="(input, option) => {
                  return  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }"
              v-else>
              <a-select-option
                v-for="tag in selectedTagInfoWithoutNoBillAndOS"
                :key="tag.key"
                :title="tag.title"
                :disabled="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)">
                <a-tag style="cursor: not-allowed" v-if="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 || (tag.types === 'OS' && vmLicenceTags.filter(x => x.tagType === 'OS').length >=1)? 'grey' : tag.colors">{{ tag.title }}</a-tag>
                <a-tag v-else style="cursor: pointer" :color="vmLicenceTags.filter(x => x.tagText === tag.title).length >=1 ? 'grey' : tag.colors">{{ tag.title }}</a-tag>
              </a-select-option>
            </a-select>
            <!-- <a-form-item
              v-if="!$route.path.startsWith('/template/')"
              :validate-status="errors.addLicenseInput.status"
              :help="errors.addLicenseInput.message"> -->
            <div
              class="sliderAndInput"
              v-if="selectedTagInfo && selectedTagInfo.types === 'Software per Core'">
              <Slider
                name="slider"
                style="width: 55%;"
                :min="1"
                :max="resource.cpunumber"
                v-model:value="addSoftwareLicensesAmount"/>
              <a-input-number
                id="coreLicenses"
                style="width: 40%; margin-left: 20px;"
                oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                v-model:value="addSoftwareLicensesAmount"
                @change="testMinMaxValue(resource.cpunumber)"
                :maxLength="4"
                :min="1"
                :max="resource.cpunumber"
                required
              />
              <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ 'CPUs' }}</span>
            </div>
            <div
              class="sliderAndInput"
              v-if="selectedTagInfo && selectedTagInfo.types === 'Software per User'">
              <Slider
                name="slider"
                style="width: 55%;"
                :min="1"
                :max="1000"
                v-model:value="addSoftwareLicensesAmount"/>
              <a-input-number
                id="userLicenses"
                style="width: 40%; margin-left: 20px;"
                oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                v-model:value="addSoftwareLicensesAmount"
                @change="testMinMaxValue(1000)"
                :maxLength="4"
                :min="1"
                :max="1000"
                required
              />
              <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ $t('label.licences') }}</span>
            </div>
            <!--  </a-form-item> -->
          </a-spin>
        </a-modal>
        <!-- Edit License Tags Modal -->
        <a-modal
          :title="$t('label.edit.license')"
          @ok="handleLicenseSubmit('edit')"
          okText="OK"
          cancelText="Cancel"
          :visible="showEditLicenseModal"
          :closable="true"
          @cancel="closeAction"
          centered>
          <!-- <a-form
              v-ctrl-enter="handleLicenseSubmit('edit')"
              :ref="formRef"
              :model="form"
              @finish="handleLicenseSubmit('edit')"
              layout="vertical"
            > -->
          <a-spin :spinning="modalLoading">
            <!-- <a-form-item
              :label="$t('label.edit.license') + ' ' + $t('label.for')"
              :validate-status="errors.editLicenseInput.status"
              :help="errors.editLicenseInput.message"> -->
            <a-tag :color="modalInputTagColor">{{ modalInputTagText }}</a-tag>
            <div
              v-for="tag in existingTags"
              :key="tag.key">
              <div
                class="sliderAndInput"
                v-if="tag.key === modalInputTagId && modalInputTagType === 'Software per Core'">
                <Slider
                  name="slider"
                  style="width: 55%;"
                  :min="1"
                  :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                  v-model.number:value="currentSoftwareLicenses"/>
                <a-input-number
                  id="coreLicenses"
                  style="width: 40%; margin-left: 20px;"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model:value="currentSoftwareLicenses"
                  @change="testMinMaxValue(modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber)"
                  :maxLength="4"
                  :min="1"
                  :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                  :addonAfter="modalInputTagType === 'Software per User' ? $t('label.licences') : 'CPUs'"
                  required
                />
                <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ modalInputTagType === 'Software per User' ? $t('label.licences') : 'CPUs' }}</span>
              </div>
            </div>
            <div
              v-for="tag in existingTags"
              :key="tag.key">
              <div
                class="sliderAndInput"
                v-if="tag.key === modalInputTagId && modalInputTagType === 'Software per User'">
                <Slider
                  name="slider"
                  style="width: 55%;"
                  :min="1"
                  :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                  v-model:value="currentSoftwareLicenses"/>
                <a-input-number
                  id="userLicenses"
                  style="width: 40%; margin-left: 20px;"
                  oninput="this.value=this.value.replace(/[^0-9\\.]+/g,'');"
                  v-model:value="currentSoftwareLicenses"
                  @change="testMinMaxValue(modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber)"
                  :maxLength="4"
                  :min="1"
                  :max="modalInputTagType === 'Software per User' ? 1000 : resource.cpunumber"
                  required
                />
                <span style="margin: 6px 0 0 10px; font-weight: bold;">{{ $t('label.licences') }}</span>
              </div>
            </div>
          </a-spin>
        </a-modal>
        <!-- Delete License Tag Modal -->
        <a-modal
          :title="$t('label.remove.license')"
          @ok="handleLicenseSubmit('delete')"
          okText="OK"
          cancelText="Cancel"
          :visible="showDeleteLicenseModal"
          :closable="true"
          @cancel="closeAction"
          centered>
          <a-spin :spinning="modalLoading">
            <a-tag style="margin-bottom: 4px;" :color="modalInputTagColor">{{ modalInputTagText }}</a-tag>
            <a-alert type="warning" v-html="$t('message.action.destroy.license')" />
          </a-spin>
        </a-modal>
      </div>
      <a-row :gutter="12" class='instance-details'>
      <!-- /WP Implementation -->
        <a-col :md="24" :lg="24">
      <a-card class='instance-details-main'>
          <div class="instance-details-main-body ">
            <div class='instance-details-os-icon'>
              <div
                class="avatar"
                @click="showUploadModal(true)"
                v-clipboard:copy="name" >
                <upload-resource-icon v-if="'uploadResourceIcon' in $store.getters.apis" :visible="showUpload" :resource="resource" @handle-close="showUpload(false)"/>
                <div class="ant-upload-preview" v-if="$showIcon()">
                  <camera-outlined class="upload-icon"/>
                </div>
                <slot name="avatar">
                  <span v-if="(resource.icon && resource.icon.base64image || images.template || images.iso || resourceIcon) && !['router', 'systemvm', 'volume'].includes($route.path.split('/')[1])">
                    <resource-icon :image="getImage(resource.icon && resource.icon.base64image || images.template || images.iso || resourceIcon)" size="4x" style="margin-right: 5px"/>
                  </span>
                  <span v-else>
                    <os-logo v-if="resource.ostypeid || resource.ostypename" :osId="resource.ostypeid" :osName="resource.ostypename" size="4x" @update-osname="setResourceOsType"/>
                    <render-icon v-else-if="typeof $route.meta.icon ==='string'" style="font-size: 36px" :icon="$route.meta.icon" />
                    <render-icon v-else style="font-size: 36px" :svgIcon="$route.meta.icon" />
                  </span>
                </slot>
              </div>
              <!-- WP Implementation -->
              <a-spin :spinning="osListLoading">
                <div v-if="$route.path.startsWith('/action/deployVirtualMachine')" >
                  <h2 v-if="typeof(resource.disksizetotalgb) === 'string'" style="text-align: center;">{{ $t('label.total.costs.vm') }}<br>{{ (totalLicenseCosts + (licenceTags.filter(x => x.tagType === 'OS').length >=1 ? (resource.cpunumber * pricePerOS) : 0) + (resource.cpunumber * pricePerCPU) + (resource.memory * pricePerRAM) + ((resource.rootdisksize === null ? (resource.size * (resource.diskofferingid !== undefined && (this.ssdOfferingList.some(params => params.uuid === resource.diskofferingid)) ? priceSSDStorage : priceHDDStorage)) : (parseInt(resource.disksizetotalgb.split(' ')[0]) * priceSSDStorage)) + ((resource.size * (resource.diskofferingid !== undefined && (this.ssdOfferingList.some(params => params.uuid === resource.diskofferingid)) ? priceSSDStorage : priceHDDStorage))))).toFixed(2) + ' € ' + $t('label.monthly') }}</h2>
                  <h2 v-else-if="resource.disksizetotalgb === undefined" style="text-align: center;">{{ $t('label.total.costs.vm') }}<br>{{ (totalLicenseCosts + (licenceTags.filter(x => x.tagType === 'OS').length >=1 ? (resource.cpunumber * pricePerOS) : 0) + (resource.cpunumber * pricePerCPU) + (resource.memory * pricePerRAM)).toFixed(2) + ' € ' + $t('label.monthly') }}</h2>
                  <h2 v-else style="text-align: center;">{{ $t('label.total.costs.vm') }}<br>{{ (totalLicenseCosts + (licenceTags.filter(x => x.tagType === 'OS').length >=1 ? (resource.cpunumber * pricePerOS) : 0) + (resource.cpunumber * pricePerCPU) + (resource.memory * pricePerRAM) + ((resource.disksizetotalgb * priceSSDStorage)+ (diskSizeInput * (resource.diskofferingid !== undefined && (this.ssdOfferingList.some(params => params.uuid === resource.diskofferingid)) ? priceSSDStorage : priceHDDStorage)))).toFixed(2) + ' € ' + $t('label.monthly') }}</h2>
                </div>
              </a-spin>
              <!-- /WP Implementation -->
              <slot name="name">
                <!-- WP Implementation -->
                <span class='instance-details-os-name' v-if="$route.path.startsWith('/action/deployVirtualMachine')"></span>
                <span class='instance-details-os-name' v-else-if="['USER.LOGIN', 'USER.LOGOUT', 'ROUTER.HEALTH.CHECKS', 'FIREWALL.CLOSE', 'ALERT.SERVICE.DOMAINROUTER'].includes(resource.name)">{{ $t(resource.name.toLowerCase()) }}</span>
                <span class='instance-details-os-name' v-else>
                  <!-- /WP Implementation -->
                   {{ name }}
                </span>
              </slot>
            </div>
            <div class='instance-details-info'>
              <div class="instance-details-info-item" v-if="resource.volumes || resource.sizegb">
                <i class='cloud-icon-ssd'></i>
                <div class='instance-details-info-item-text'>
                  <span style="width: 100%;" v-if="$route.meta.name === 'vm' && resource.volumes">{{ (resource.volumes.reduce((total, item) => total += item.size, 0) / (1024 * 1024 * 1024.0)).toFixed(2) }} GB</span>
                  <span style="width: 100%;" v-else-if="resource.sizegb || resource.size">{{ resource.sizegb || (resource.size/1024.0) }}</span>

                  <span class='instance-details-info-item-text-small'>{{ $t('label.disksize') }}</span>

                <div style="margin-top: 5px" v-if="resource.diskkbsread && resource.diskkbswrite && resource.diskioread && resource.diskiowrite">
                  <a-tag style="margin-bottom: 5px;">{{ $t('label.read') + ' ' + toSize(resource.diskkbsread) }}</a-tag>
                  <a-tag style="margin-bottom: 5px;">{{ $t('label.write') + ' ' + toSize(resource.diskkbswrite) }}</a-tag><br/>
                  <a-tag style="margin-bottom: 5px;">{{ $t('label.read.io') + ' ' + resource.diskioread }}</a-tag>
                  <a-tag>{{ $t('label.writeio') + ' ' + resource.diskiowrite }}</a-tag>
                </div>
                </div>
              </div>
              <div class="instance-details-info-item" v-else-if="resource.disksizetotalgb">
                <img src="../../assets/icons-cloud/disk.png" >
                <div class='instance-details-info-item-text'>
                  <!-- WP Implementation -->
                  <span v-if="$route.path.startsWith('/action/deployVirtualMachine') && typeof(resource.disksizetotalgb) === 'string'"><database-outlined />{{ resource.disksizetotalgb + ' = ' + ((resource.rootdisksize === null ? (resource.size * (resource.diskofferingid !== undefined && (this.ssdOfferingList.some(params => params.uuid === resource.diskofferingid)) ? priceSSDStorage : priceHDDStorage)) : (parseInt(resource.disksizetotalgb.split(' ')[0]) * priceSSDStorage)) + ((resource.size * (resource.diskofferingid !== undefined && (this.ssdOfferingList.some(params => params.uuid === resource.diskofferingid)) ? priceSSDStorage : priceHDDStorage)))).toFixed(2) + ' €' }}</span>
                  <span v-else><database-outlined />{{ resource.disksizetotalgb }}</span>
                  <!-- /WP Implementation -->
                  <span class='instance-details-info-item-text-small'>{{ $t('label.disksize') }}</span>
                </div>
                <div>
              <span v-if="resource.disksizeusedgb">
                <a-progress
                  class="progress-bar"
                  size="small"
                  status="active"
                  :percent="Number(parseFloat(100.0 * parseFloat(resource.disksizeusedgb) / parseFloat(resource.disksizetotalgb)).toFixed(2))"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.disksizeusedgb')" />
              </span>
                  <span v-if="resource.disksizeallocatedgb">
                <a-progress
                  class="progress-bar"
                  size="small"
                  :percent="Number(parseFloat(100.0 * parseFloat(resource.disksizeallocatedgb) / (parseFloat(resource.disksizetotalgb) *
                    (parseFloat(resource.overprovisionfactor) || 1.0))).toFixed(2))"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.disksizeallocatedgb')" />
              </span>
                </div>
              </div>

              <div class="instance-details-info-item" v-if="resource.diskofferingname && resource.diskofferingid">
                <i class='cloud-icon-ssd'></i>
                <div class="instance-details-info-item-text">
                  <router-link v-if="!isStatic && $router.resolve('/diskoffering/' + resource.diskofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/diskoffering/' + resource.diskofferingid }">{{ resource.diskofferingname || resource.diskofferingid }} </router-link>
                  <span v-else>{{ resource.diskofferingname || resource.diskofferingid }}</span>
                  <div class="instance-details-info-item-text-small">{{ $t('label.diskoffering') }}</div>
                </div>
              </div>
            </div>

          </div>
          <div class='instance-details-main-footer'>
            <div class='instance-details-main-lizenz is-white-button'>
              <!-- License Information in the Instance Page -->
              <template v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/vm/')"> <!-- Show Only when instance Page is present -->
                <!-- Always Show The Licenses Title with the Add license Button -->
<!--                <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>-->
                <a-tooltip placement="top">
                  <template #title>
                    {{ $t('label.add.license') }}
                  </template>
                  <a-button
                    style="margin: 0 0 5px 5px;"
                    shape="circle"
                    size="small"
                    @click="openAddVMLicensesModal">
                    <template #icon>
                      <PlusOutlined class="iconToRight"/>
                    </template>            </a-button>
                </a-tooltip>
                <!-- Show all Information about License Tags if there are any licenses associated with current VM -->
                <a-spin :spinning="licenseLoading">
                  <a-collapse :bordered="true" accordion>
                    <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                      <div class="resource-detail-item__details" v-if="vmLicenceTags.length >= 1">
                        <div style="display: flex; flex-direction: column; margin-top: 8px;">
                          <div
                            v-for="tag in vmLicenceTags"
                            :key="tag.tagText">
                            <div
                              v-for="taglicenses in nrOfSoftwareLicenses"
                              :key="taglicenses.tagID">
                              <!-- If there is ever a new License Type needed, add it here! -->
                              <!-- Case for all Licenses Per User -->
                              <div v-if="tag.tagType === 'Software per User' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                <FileDoneOutlined />
                                <a-tag style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                <!-- Show Buttons for deleting and editing software licenses -->
                                <a-tooltip placement="top">
                                  <template #title>
                                    {{ $t('label.remove.license') }}
                                  </template>
                                  <a-button
                                    v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                    style="margin-right: 4px;"
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    danger
                                    @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                    <template #icon>
                                      <DeleteOutlined class="iconToRight"/>
                                    </template>
                                  </a-button>
                                </a-tooltip>
                                <a-tooltip placement="top">
                                  <template #title>
                                    {{ $t('label.edit.license') }}
                                  </template>
                                  <a-button
                                    v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                    style="margin-right: 4px;"
                                    shape="circle"
                                    size="small"
                                    @click="openEditVMLicensesModal(tag.tagText, tag.tagColor, tag.tagType, tag.id, taglicenses.licenseCounter)">
                                    <template #icon>
                                      <SettingOutlined class="iconToRight"/>
                                    </template>
                                  </a-button>
                                </a-tooltip>
                                <br>
                                <!-- this is the Amount and price for User Licenses -->
                                <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                              </div>
                              <!-- Case for all OS licenses (Should always only be one though) -->
                              <div v-if="tag.tagType === 'OS' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                <FileDoneOutlined />
                                <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                <!-- only show delete OS Button if User = Admin -->
                                <a-button
                                  v-if="tag.tagType === 'OS' && ['Admin'].includes($store.getters.userInfo.roletype)"
                                  style="margin-right: 4px;"
                                  type="primary"
                                  shape="circle"
                                  size="small"
                                  danger
                                  @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                  <template #icon>
                                    <DeleteOutlined class="iconToRight"/>
                                  </template>
                                </a-button>
                                <br>
                                <!-- this is the Amount and price for OS Licenses -->
                                <span style="margin-left: 1.5rem;">{{ resource.cpunumber || resource.cputotal }} {{ (resource.cpunumber || resource.cputotal) === 1 ? 'CPU' : 'CPUs' }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(resource.cpunumber * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                              </div>
                              <!-- Case for all Software Package licenses -->
                              <div v-if="tag.tagType === 'Software Package' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                <FileDoneOutlined />
                                <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                <!-- only show delete OS Button if User = Admin -->
                                <a-button
                                  v-if="tag.tagType === 'Software Package'"
                                  style="margin-right: 4px;"
                                  type="primary"
                                  shape="circle"
                                  size="small"
                                  danger
                                  @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                  <template #icon>
                                    <DeleteOutlined class="iconToRight"/>
                                  </template>
                                </a-button>
                                <br>
                                <!-- this is the Price for Software Package Licenses -->
                                <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                              </div>
                              <!-- Case for all licenses per COre -->
                              <div v-if="tag.tagType === 'Software per Core' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                <FileDoneOutlined />
                                <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                <!-- Show Buttons for deleting and editing software licenses -->
                                <a-tooltip placement="top">
                                  <template #title>
                                    {{ $t('label.remove.license') }}
                                  </template>
                                  <a-button
                                    v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                    style="margin-right: 4px;"
                                    type="primary"
                                    shape="circle"
                                    size="small"
                                    danger
                                    @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                    <template #icon>
                                      <DeleteOutlined class="iconToRight"/>
                                    </template>
                                  </a-button>
                                </a-tooltip>
                                <a-tooltip placement="top">
                                  <template #title>
                                    {{ $t('label.edit.license') }}
                                  </template>
                                  <a-button
                                    v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                    style="margin-right: 4px;"
                                    shape="circle"
                                    size="small"
                                    @click="openEditVMLicensesModal(tag.tagText, tag.tagColor, tag.tagType, tag.id, taglicenses.licenseCounter)">
                                    <template #icon>
                                      <SettingOutlined class="iconToRight"/>
                                    </template>
                                  </a-button>
                                </a-tooltip>
                                <br>
                                <!-- this is the Amount and price for Core Licenses -->
                                <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter + ' ' }}{{ taglicenses.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                              </div>
                              <!-- Case for noBill licenses -->
                              <div v-if="tag.tagType === 'NoBill' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                                <FileDoneOutlined />
                                <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                                <!-- only show delete OS Button if User = Admin -->
                                <a-button
                                  v-if="tag.tagType === 'NoBill'"
                                  style="margin-right: 4px;"
                                  type="primary"
                                  shape="circle"
                                  size="small"
                                  danger
                                  @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                  <template #icon>
                                    <DeleteOutlined class="iconToRight"/>
                                  </template>
                                </a-button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- if no licenses are associated with the current VM -->
                      <div v-else>
                        {{ $t('message.no.associated.licenses') }}
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                </a-spin>
              </template>
              <!-- LICENSE INFO FOR SNAPSHOTS -->
              <template v-if="$route.meta.name !== 'zone' && ($route.path.startsWith('/snapshot/') || $route.path.startsWith('/vmsnapshot/'))"> <!-- Show Only when Snapshot Page is present -->
                <!-- Always Show The Licenses Title with the Add license Button in Snapshot Page -->
<!--                <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>-->
                <!-- Show all Information about License Tags if there are any licenses associated with current Snapshot -->
                <a-spin :spinning="licenseLoading">
                  <a-collapse :bordered="true" accordion>
                    <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                      <div class="resource-detail-item__details" v-if="snapshotTags.length >= 1">
                        <div style="display: flex; flex-direction: column; margin-top: 8px;">
                          <div
                            v-for="tag in snapshotTags"
                            :key="tag.id">
                            <!-- If there is ever a new License Type needed, add it here! -->
                            <!-- Case for all Licenses Per User -->
                            <div v-if="tag.vmTagType === 'Software per User'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" style="margin-bottom: 2px;" :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                              <br>
                              <!-- this is the Amount and price for User Licenses -->
                              <span style="margin-left: 1.5rem;">{{ tag.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for all OS licenses (Should always only be one though) -->
                            <div v-if="tag.vmTagType === 'OS'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                              <br>
                              <!-- this is the Amount and price for OS Licenses -->
                              <span style="margin-left: 1.5rem;">{{ tag.cpu }} {{ (tag.cpu) === 1 ? 'CPU' : 'CPUs' }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.cpu * discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for all Software Package licenses -->
                            <div v-if="tag.vmTagType === 'Software Package'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                              <br>
                              <!-- this is the Price for Software Package Licenses -->
                              <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for all licenses per COre -->
                            <div v-if="tag.vmTagType === 'Software per Core'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                              <br>
                              <!-- this is the Amount and price for Core Licenses -->
                              <span style="margin-left: 1.5rem;">{{ tag.licenseCounter + ' ' }}{{ tag.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for NoBill license -->
                            <div v-if="tag.vmTagType === 'NoBill'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- if no licenses are associated with the current Snapshot -->
                      <div v-else>
                        {{ $t('message.no.associated.licenses') }}
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                </a-spin>
              </template>
              <!-- LICENSE INFO FOR TEMPLATES -->
              <template v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/template/')"> <!-- Show Only when Template Page is present -->
                <!-- Always Show The Licenses Title with the Add license Button in Template Page -->
<!--                <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>-->
                <!-- Show Add License Button as Admin -->
                <a-tooltip placement="top">
                  <template #title>
                    {{ $t('label.add.license') }}
                  </template>
                  <a-button
                    v-if="['Admin'].includes($store.getters.userInfo.roletype)"
                    style="margin: 0 0 5px 5px;"
                    shape="circle"
                    size="small"
                    @click="openAddVMLicensesModal">
                    <template #icon>
                      <PlusOutlined class="iconToRight"/>
                    </template>
                  </a-button>
                </a-tooltip>
                <!-- Show all Information about License Tags if there are any licenses associated with current Template -->
                <a-spin :spinning="licenseLoading">
                  <a-collapse :bordered="true" accordion>
                    <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                      <div class="resource-detail-item__details" v-if="templateTags.length >= 1">
                        <div style="display: flex; flex-direction: column; margin-top: 8px;">
                          <div
                            v-for="tag in templateTags"
                            :key="tag.id">
                            <!-- If there is ever a new License Type needed, add it here! -->
                            <!-- Case for all Licenses Per User -->
                            <div v-if="tag.tagType === 'Software per User'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <a-tooltip placement="top">
                                <template #title>
                                  {{ $t('label.remove.license') }}
                                </template>
                                <a-button
                                  v-if="['Admin'].includes($store.getters.userInfo.roletype) && (tag.tagType === 'Software per User' || tag.tagType === 'Software per Core')"
                                  style="margin-right: 4px;"
                                  type="danger"
                                  shape="circle"
                                  size="small"
                                  @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                  <template #icon>
                                    <DeleteOutlined class="iconToRight"/>
                                  </template>
                                </a-button>
                              </a-tooltip>
                            </div>
                            <!-- Case for all OS licenses (Should always only be one though) -->
                            <div v-if="tag.tagType === 'OS'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <!-- only show delete OS Button if User = Admin -->
                              <a-button
                                v-if="tag.tagType === 'OS' && ['Admin'].includes($store.getters.userInfo.roletype)"
                                style="margin-right: 4px;"
                                type="danger"
                                shape="circle"
                                size="small"
                                @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                <template #icon>
                                  <DeleteOutlined class="iconToRight"/>
                                </template>
                              </a-button>
                            </div>
                            <!-- Case for all Software Package licenses -->
                            <div v-if="tag.tagType === 'Software Package'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <!-- only show delete OS Button if User = Admin -->
                              <a-button
                                v-if="tag.tagType === 'Software Package' && ['Admin'].includes($store.getters.userInfo.roletype)"
                                style="margin-right: 4px;"
                                type="danger"
                                shape="circle"
                                size="small"
                                @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                <template #icon>
                                  <DeleteOutlined class="iconToRight"/>
                                </template>
                              </a-button>
                            </div>
                            <!-- Case for all licenses per COre -->
                            <div v-if="tag.tagType === 'Software per Core'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <a-tooltip placement="top">
                                <template #title>
                                  {{ $t('label.remove.license') }}
                                </template>
                                <a-button
                                  v-if="['Admin'].includes($store.getters.userInfo.roletype) && (tag.tagType === 'Software per User' || tag.tagType === 'Software per Core')"
                                  style="margin-right: 4px;"
                                  type="primary"
                                  shape="circle"
                                  size="small"
                                  danger
                                  @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                  <template #icon>
                                    <DeleteOutlined class="iconToRight"/>
                                  </template>
                                </a-button>
                              </a-tooltip>
                            </div>
                            <!-- Case NoBIll licenses -->
                            <div v-if="tag.tagType === 'NoBill'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <a-tooltip placement="top">
                                <template #title>
                                  {{ $t('label.remove.license') }}
                                </template>
                                <a-button
                                  v-if="['Admin'].includes($store.getters.userInfo.roletype) && tag.tagType === 'NoBIll'"
                                  style="margin-right: 4px;"
                                  type="danger"
                                  shape="circle"
                                  size="small"
                                  @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                  <template #icon>
                                    <DeleteOutlined class="iconToRight"/>
                                  </template>
                                </a-button>
                              </a-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- if no licenses are associated with the current VM -->
                      <div v-else>
                        {{ $t('message.no.associated.licenses') }}
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                </a-spin>
              </template>
              <!-- LICENSE INFO FOR ROOT VOLUMES -->
              <template v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/volume/') && resource.virtualmachineid && resource.type === 'ROOT'"> <!-- Show Only when Snapshot Page is present -->
                <!-- Always Show The Licenses Title with the Add license Button in Volume Page -->
<!--                <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>-->
                <!-- Show all Information about License Tags if there are any licenses associated with current Snapshot -->
                <a-spin :spinning="licenseLoading">
                  <a-collapse :bordered="true" accordion>
                    <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                      <div class="resource-detail-item__details" v-if="vmLicenceTags.length >= 1">
                        <div style="display: flex; flex-direction: column; margin-top: 8px;">
                          <div
                            v-for="tag in vmLicenceTags"
                            :key="tag.id">
                            <!-- If there is ever a new License Type needed, add it here! -->
                            <!-- Case for all Licenses Per User -->
                            <div v-if="tag.tagType === 'Software per User'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <br>
                              <!-- this is the Amount and price for User Licenses -->
                              <span style="margin-left: 1.5rem;">{{ tag.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for all OS licenses (Should always only be one though) -->
                            <div v-if="tag.tagType === 'OS'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            </div>
                            <!-- Case for all Software Package Licenses -->
                            <div v-if="tag.tagType === 'Software Package'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <br>
                              <!-- this is the Amount and price for User Licenses -->
                              <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for all licenses per COre -->
                            <div v-if="tag.tagType === 'Software per Core'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                              <br>
                              <!-- this is the Amount and price for Core Licenses -->
                              <span style="margin-left: 1.5rem;">{{ tag.licenseCounter + ' ' }}{{ tag.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                            </div>
                            <!-- Case for NoBill licenses -->
                            <div v-if="tag.tagType === 'NoBill'" style="margin-bottom: 0.6rem;">
                              <FileDoneOutlined />
                              <a-tag class="tagToRight" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- if no licenses are associated with the current VM -->
                      <div v-else>
                        {{ $t('message.no.associated.licenses') }}
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                </a-spin>
              </template>
              <slot name="actions">
                <div class="tags">
                <a-tag v-if="resource.instancename">
                  {{ resource.instancename }}
                </a-tag>
                <a-tag v-if="resource.type">
                  <span v-if="['USER.LOGIN', 'USER.LOGOUT', 'ROUTER.HEALTH.CHECKS', 'FIREWALL.CLOSE', 'ALERT.SERVICE.DOMAINROUTER'].includes(resource.type)">{{ $t(resource.type.toLowerCase()) }}</span>
                  <span v-else>
                    {{ resource.type }}
                  </span>
                </a-tag>
                <a-tag v-if="resource.issourcenat">
                  {{ $t('label.issourcenat') }}
                </a-tag>
                <a-tag v-if="resource.broadcasturi">
                  {{ resource.broadcasturi }}
                </a-tag>
                <a-tag v-if="resource.hypervisor">
                  {{ resource.hypervisor }}
                </a-tag>
                <a-tag v-if="resource.haenable">
                  {{ $t('label.haenable') }}
                </a-tag>
                <a-tag v-if="resource.isdynamicallyscalable">
                  {{ $t('label.isdynamicallyscalable') }}
                </a-tag>
                <a-tag v-if="resource.scope">
                  {{ resource.scope }}
                </a-tag>
                <a-tag v-if="resource.version">
                  {{ resource.version }}
                </a-tag>
                <a-tag v-if="resource.internetprotocol && ['IPv6', 'DualStack'].includes(resource.internetprotocol)">
                  {{ resource.internetprotocol ? $t('label.ip.v4.v6') : resource.internetprotocol }}
                </a-tag>
                <a-tooltip placement="right" >
                  <template #title>
                    <span>{{ $t('label.view.console') }}</span>
                  </template>
                  <console style="margin-top: -5px;" :resource="resource" size="default" v-if="resource.id" />
                </a-tooltip>
              </div>
              </slot>
            </div>
            <div class='instance-details-main-id'>

              <span><tooltip-button
                tooltipPlacement="right"
                :tooltip="$t('label.copyid')"
                icon="barcode-outlined"
                size="small"

                :copyResource="resource.id"
                @onClick="$message.success($t('label.copied.clipboard'))" /> <span style='margin-left: 8px'>{{resource.id}}</span></span>

            </div>
          </div>
        </a-card>
        </a-col>
        <a-col :md="24" :lg="12">
      <div class='card-grey'>
        <div class='card-grey-header'><info-circle-outlined class='is--blue'/> {{ $t('label.info')}}</div>
        <div class='resource-detail-list'>
        <!-- WP Implementation -->
        <!-- Proxy Information in the ProxySettings.vue Site -->
          <template v-if="$route.path.startsWith('/proxySettings/')">
            <div class="resource-detail-item" v-if="'proxyid' in resource">
              <div class="resource-detail-item__label">{{ $t('label.id') }}</div>
              <div class="resource-detail-item__details">
                <tooltip-button
                  tooltipPlacement="right"
                  :tooltip="$t('label.copyid')"
                  style="margin-left: -5px"
                  icon="barcodeOutlined"
                  type="dashed"
                  size="small"
                  @click="$message.success($t('label.copied.clipboard'))"
                  v-clipboard:copy="resource.proxyid" />
                <span style="margin-left: 10px;">{{ resource.proxyid }}</span>
              </div>
            </div>
            <!-- Account -->
            <div class="resource-detail-item" v-if="resource.accountid">
              <div class="resource-detail-item__label">{{ $t('label.account') }}</div>
              <div class="resource-detail-item__details">
                <UserOutlined/>
                <span>{{ resource.accountname }}</span>
              </div>
            </div>
            <!-- VPC -->
            <!-- <div class="resource-detail-item" v-if="resource.vpcid">
              <div class="resource-detail-item__label">{{ $t('label.vpc') }}</div>
              <div class="resource-detail-item__details">
                <a-icon type="deployment-unit-o" />
                <router-link v-if="!isStatic && $store.getters.userInfo.roletype !== 'User'" :to="{ path: '/vpc', query: { id: resource.vpcid } }">{{ resource.vpcid }}</router-link>
                <span v-else>{{ resource.vpcid }}</span>
              </div>
            </div> -->
            <!-- VM -->
            <div class="resource-detail-item" v-if="resource.vmid">
              <div class="resource-detail-item__label">{{ $t('label.vm') }}</div>
              <div class="resource-detail-item__details">
                <LaptopOutlined />
                <router-link v-if="!isStatic && $store.getters.userInfo.roletype !== 'User'" :to="{ path: '/vm/' + resource.vmid }">{{ resource.vmname }}</router-link>
                <span v-else>{{ resource.vmname }}</span>
              </div>
            </div>
            <!-- Domain -->
            <div class="resource-detail-item" v-if="resource.domain">
              <div class="resource-detail-item__label">{{ $t('label.domain') }}</div>
              <div class="resource-detail-item__details">
                <BlockOutlined/>
                <span>{{ resource.domain }}</span>
                <tooltip-button
                  style="margin-left: 10px;"
                  tooltipPlacement="top"
                  :tooltip="$t('label.proxy.edit.settings')"
                  icon="toolOutlined"
                  @click="$emit('emit-open-edit-modal')"/>
              </div>
            </div>
            <!-- Subnet -->
            <div class="resource-detail-item" v-if="resource.subnet">
              <div class="resource-detail-item__label">{{ $t('label.subnet') }}</div>
              <div class="resource-detail-item__details">
                <WifiOutlined/>
                <span>{{ resource.subnet }}</span>
                <tooltip-button
                  style="margin-left: 10px;"
                  tooltipPlacement="top"
                  :tooltip="$t('label.proxy.edit.settings')"
                  icon="toolOutlined"
                  @click="$emit('emit-open-edit-modal')"/>
              </div>
            </div>
            <!-- PublicIP -->
            <div class="resource-detail-item" v-if="resource.publicip">
              <div class="resource-detail-item__label">{{ $t('label.publicip') }}</div>
              <div class="resource-detail-item__details">
                <EnvironmentOutlined/>
                <span>{{ resource.publicip }}</span>
              </div>
            </div>
          </template>
          <a-spin :spinning="acronisUserLoading">
            <!-- Acronis User Status in Account Page -->
            <div class="resource-detail-item" v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/account/')"> <!-- Show Only when Accounts Page is present -->
              <div class="resource-detail-item__label">{{ 'Cyber Cloud Status' }}</div>
              <div class="resource-detail-item__details">
                <img class="shieldIcon" v-if="acronisStatusActive" src="../../assets/icons/FirewallShieldDefaultAllow.png"/>
                <img class="shieldIcon" v-else src="../../assets/icons/FirewallShieldDefaultDeny.png"/>
                <span>{{ acronisStatusActive ? $t('label.enabled') : $t('label.disabled') }}</span>
                <!-- Button is only available when logged in user is a ResellerAdmin -->
                <a-button v-if="!acronisStatusActive && ($store.getters.userInfo.rolename === 'ResellerAdmin' || $store.getters.userInfo.roletype === 'Admin')" @click="createAcronisUser()" style="margin-left: 10px;"><PlusOutlined/>{{ $t('label.create.user') }}</a-button>
              </div>
            </div>
            <!-- Onetime URL to Cyber Cloud for the currently open Account -->
            <div v-if="acronisStatusActive && ($store.getters.userInfo.rolename === 'ResellerAdmin' || $store.getters.userInfo.roletype === 'Admin')" style="margin-top: 10px; margin-left: 2px;">
              <div @click="linkClicked()" class="cyberCloudLink">
                <LinkOutlined style="font-size: 18px;" />
                <span>{{ 'Cyber Cloud Login' }}</span>
              </div>
            </div>
            <div class="resource-detail-item" v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/vm/')">
              <!-- Show Only when Vm Page is present
              Only show the status -->
              <div class="resource-detail-item__label">{{ 'Cyber Cloud Status' }}</div>
              <div class="resource-detail-item__details">
                <img class="shieldIcon" v-if="acronisVmPbsIsActivated" v-bind:src="require('../../assets/icons/FirewallShieldDefaultAllow.png')"/>
                <img class="shieldIcon" v-else v-bind:src="require('../../assets/icons/FirewallShieldDefaultDeny.png')">
                <span>{{ acronisVmPbsIsActivated ? $t('label.enabled') : $t('label.disabled') }}</span>
              </div>
            </div>
          </a-spin>
          <!-- License Information in the Instance Page -->
<!--          <div class="resource-detail-item" v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/vm/')"> &lt;!&ndash; Show Only when instance Page is present &ndash;&gt;
            &lt;!&ndash; Always Show The Licenses Title with the Add license Button &ndash;&gt;
            <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>
            <a-tooltip placement="top">
              <template #title>
                {{ $t('label.add.license') }}
              </template>
              <a-button
                style="margin: 0 0 5px 5px;"
                shape="circle"
                size="small"
                @click="openAddVMLicensesModal">
                <template #icon>
                  <PlusOutlined class="iconToRight"/>
                </template>            </a-button>
            </a-tooltip>
            &lt;!&ndash; Show all Information about License Tags if there are any licenses associated with current VM &ndash;&gt;
            <a-spin :spinning="licenseLoading">
              <a-collapse :bordered="true" accordion>
                <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                  <div class="resource-detail-item__details" v-if="vmLicenceTags.length >= 1">
                    <div style="display: flex; flex-direction: column; margin-top: 8px;">
                      <div
                        v-for="tag in vmLicenceTags"
                        :key="tag.tagText">
                        <div
                          v-for="taglicenses in nrOfSoftwareLicenses"
                          :key="taglicenses.tagID">
                          &lt;!&ndash; If there is ever a new License Type needed, add it here! &ndash;&gt;
                          &lt;!&ndash; Case for all Licenses Per User &ndash;&gt;
                          <div v-if="tag.tagType === 'Software per User' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                            <FileDoneOutlined />
                            <a-tag style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            &lt;!&ndash; Show Buttons for deleting and editing software licenses &ndash;&gt;
                            <a-tooltip placement="top">
                              <template #title>
                                {{ $t('label.remove.license') }}
                              </template>
                              <a-button
                                v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                style="margin-right: 4px;"
                                type="primary"
                                shape="circle"
                                size="small"
                                danger
                                @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                <template #icon>
                                  <DeleteOutlined class="iconToRight"/>
                                </template>
                              </a-button>
                            </a-tooltip>
                            <a-tooltip placement="top">
                              <template #title>
                                {{ $t('label.edit.license') }}
                              </template>
                              <a-button
                                v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                style="margin-right: 4px;"
                                shape="circle"
                                size="small"
                                @click="openEditVMLicensesModal(tag.tagText, tag.tagColor, tag.tagType, tag.id, taglicenses.licenseCounter)">
                                <template #icon>
                                  <SettingOutlined class="iconToRight"/>
                                </template>
                              </a-button>
                            </a-tooltip>
                            <br>
                            &lt;!&ndash; this is the Amount and price for User Licenses &ndash;&gt;
                            <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                          </div>
                          &lt;!&ndash; Case for all OS licenses (Should always only be one though) &ndash;&gt;
                          <div v-if="tag.tagType === 'OS' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                            <FileDoneOutlined />
                            <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            &lt;!&ndash; only show delete OS Button if User = Admin &ndash;&gt;
                            <a-button
                              v-if="tag.tagType === 'OS' && ['Admin'].includes($store.getters.userInfo.roletype)"
                              style="margin-right: 4px;"
                              type="primary"
                              shape="circle"
                              size="small"
                              danger
                              @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                              <template #icon>
                                <DeleteOutlined class="iconToRight"/>
                              </template>
                            </a-button>
                            <br>
                            &lt;!&ndash; this is the Amount and price for OS Licenses &ndash;&gt;
                            <span style="margin-left: 1.5rem;">{{ resource.cpunumber || resource.cputotal }} {{ (resource.cpunumber || resource.cputotal) === 1 ? 'CPU' : 'CPUs' }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(resource.cpunumber * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                          </div>
                          &lt;!&ndash; Case for all Software Package licenses &ndash;&gt;
                          <div v-if="tag.tagType === 'Software Package' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                            <FileDoneOutlined />
                            <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            &lt;!&ndash; only show delete OS Button if User = Admin &ndash;&gt;
                            <a-button
                              v-if="tag.tagType === 'Software Package'"
                              style="margin-right: 4px;"
                              type="primary"
                              shape="circle"
                              size="small"
                              danger
                              @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                              <template #icon>
                                <DeleteOutlined class="iconToRight"/>
                              </template>
                            </a-button>
                            <br>
                            &lt;!&ndash; this is the Price for Software Package Licenses &ndash;&gt;
                            <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                          </div>
                          &lt;!&ndash; Case for all licenses per COre &ndash;&gt;
                          <div v-if="tag.tagType === 'Software per Core' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                            <FileDoneOutlined />
                            <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            &lt;!&ndash; Show Buttons for deleting and editing software licenses &ndash;&gt;
                            <a-tooltip placement="top">
                              <template #title>
                                {{ $t('label.remove.license') }}
                              </template>
                              <a-button
                                v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                style="margin-right: 4px;"
                                type="primary"
                                shape="circle"
                                size="small"
                                danger
                                @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                                <template #icon>
                                  <DeleteOutlined class="iconToRight"/>
                                </template>
                              </a-button>
                            </a-tooltip>
                            <a-tooltip placement="top">
                              <template #title>
                                {{ $t('label.edit.license') }}
                              </template>
                              <a-button
                                v-if="tag.tagType === 'Software per User' || tag.tagType === 'Software per Core'"
                                style="margin-right: 4px;"
                                shape="circle"
                                size="small"
                                @click="openEditVMLicensesModal(tag.tagText, tag.tagColor, tag.tagType, tag.id, taglicenses.licenseCounter)">
                                <template #icon>
                                  <SettingOutlined class="iconToRight"/>
                                </template>
                              </a-button>
                            </a-tooltip>
                            <br>
                            &lt;!&ndash; this is the Amount and price for Core Licenses &ndash;&gt;
                            <span style="margin-left: 1.5rem;">{{ taglicenses.licenseCounter + ' ' }}{{ taglicenses.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(taglicenses.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                          </div>
                          &lt;!&ndash; Case for noBill licenses &ndash;&gt;
                          <div v-if="tag.tagType === 'NoBill' && taglicenses.tagID === tag.id" style="margin-bottom: 0.6rem;">
                            <FileDoneOutlined />
                            <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                            &lt;!&ndash; only show delete OS Button if User = Admin &ndash;&gt;
                            <a-button
                              v-if="tag.tagType === 'NoBill'"
                              style="margin-right: 4px;"
                              type="primary"
                              shape="circle"
                              size="small"
                              danger
                              @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                              <template #icon>
                                <DeleteOutlined class="iconToRight"/>
                              </template>
                            </a-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash; if no licenses are associated with the current VM &ndash;&gt;
                  <div v-else>
                    {{ $t('message.no.associated.licenses') }}
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </a-spin>
          </div>
          &lt;!&ndash; LICENSE INFO FOR SNAPSHOTS &ndash;&gt;
          <div class="resource-detail-item" v-if="$route.meta.name !== 'zone' && ($route.path.startsWith('/snapshot/') || $route.path.startsWith('/vmsnapshot/'))"> &lt;!&ndash; Show Only when Snapshot Page is present &ndash;&gt;
            &lt;!&ndash; Always Show The Licenses Title with the Add license Button in Snapshot Page &ndash;&gt;
            <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>
            &lt;!&ndash; Show all Information about License Tags if there are any licenses associated with current Snapshot &ndash;&gt;
            <a-spin :spinning="licenseLoading">
              <a-collapse :bordered="true" accordion>
                <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                  <div class="resource-detail-item__details" v-if="snapshotTags.length >= 1">
                    <div style="display: flex; flex-direction: column; margin-top: 8px;">
                      <div
                        v-for="tag in snapshotTags"
                        :key="tag.id">
                        &lt;!&ndash; If there is ever a new License Type needed, add it here! &ndash;&gt;
                        &lt;!&ndash; Case for all Licenses Per User &ndash;&gt;
                        <div v-if="tag.vmTagType === 'Software per User'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag style="margin-bottom: 2px;" :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Amount and price for User Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ tag.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for all OS licenses (Should always only be one though) &ndash;&gt;
                        <div v-if="tag.vmTagType === 'OS'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Amount and price for OS Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ tag.cpu }} {{ (tag.cpu) === 1 ? 'CPU' : 'CPUs' }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.cpu * discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for all Software Package licenses &ndash;&gt;
                        <div v-if="tag.vmTagType === 'Software Package'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Price for Software Package Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for all licenses per COre &ndash;&gt;
                        <div v-if="tag.vmTagType === 'Software per Core'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Amount and price for Core Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ tag.licenseCounter + ' ' }}{{ tag.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.vmTagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for NoBill license &ndash;&gt;
                        <div v-if="tag.vmTagType === 'NoBill'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.vmTagColor">{{ tag.vmTagText }}</a-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash; if no licenses are associated with the current Snapshot &ndash;&gt;
                  <div v-else>
                    {{ $t('message.no.associated.licenses') }}
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </a-spin>
          </div>
          &lt;!&ndash; LICENSE INFO FOR TEMPLATES &ndash;&gt;
          <div class="resource-detail-item" v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/template/')"> &lt;!&ndash; Show Only when Template Page is present &ndash;&gt;
            &lt;!&ndash; Always Show The Licenses Title with the Add license Button in Template Page &ndash;&gt;
            <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>
            &lt;!&ndash; Show Add License Button as Admin &ndash;&gt;
            <a-tooltip placement="top">
              <template #title>
                {{ $t('label.add.license') }}
              </template>
              <a-button
                v-if="['Admin'].includes($store.getters.userInfo.roletype)"
                style="margin: 0 0 5px 5px;"
                shape="circle"
                size="small"
                @click="openAddVMLicensesModal">
                <template #icon>
                  <PlusOutlined class="iconToRight"/>
                </template>
              </a-button>
            </a-tooltip>
            &lt;!&ndash; Show all Information about License Tags if there are any licenses associated with current Template &ndash;&gt;
            <a-spin :spinning="licenseLoading">
              <a-collapse :bordered="true" accordion>
                <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                  <div class="resource-detail-item__details" v-if="templateTags.length >= 1">
                    <div style="display: flex; flex-direction: column; margin-top: 8px;">
                      <div
                        v-for="tag in templateTags"
                        :key="tag.id">
                        &lt;!&ndash; If there is ever a new License Type needed, add it here! &ndash;&gt;
                        &lt;!&ndash; Case for all Licenses Per User &ndash;&gt;
                        <div v-if="tag.tagType === 'Software per User'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          <a-tooltip placement="top">
                            <template #title>
                              {{ $t('label.remove.license') }}
                            </template>
                            <a-button
                              v-if="['Admin'].includes($store.getters.userInfo.roletype) && (tag.tagType === 'Software per User' || tag.tagType === 'Software per Core')"
                              style="margin-right: 4px;"
                              type="danger"
                              shape="circle"
                              size="small"
                              @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                              <template #icon>
                                <DeleteOutlined class="iconToRight"/>
                              </template>
                            </a-button>
                          </a-tooltip>
                        </div>
                        &lt;!&ndash; Case for all OS licenses (Should always only be one though) &ndash;&gt;
                        <div v-if="tag.tagType === 'OS'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          &lt;!&ndash; only show delete OS Button if User = Admin &ndash;&gt;
                          <a-button
                            v-if="tag.tagType === 'OS' && ['Admin'].includes($store.getters.userInfo.roletype)"
                            style="margin-right: 4px;"
                            type="danger"
                            shape="circle"
                            size="small"
                            @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                            <template #icon>
                              <DeleteOutlined class="iconToRight"/>
                            </template>
                          </a-button>
                        </div>
                        &lt;!&ndash; Case for all Software Package licenses &ndash;&gt;
                        <div v-if="tag.tagType === 'Software Package'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          &lt;!&ndash; only show delete OS Button if User = Admin &ndash;&gt;
                          <a-button
                            v-if="tag.tagType === 'Software Package' && ['Admin'].includes($store.getters.userInfo.roletype)"
                            style="margin-right: 4px;"
                            type="danger"
                            shape="circle"
                            size="small"
                            @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                            <template #icon>
                              <DeleteOutlined class="iconToRight"/>
                            </template>
                          </a-button>
                        </div>
                        &lt;!&ndash; Case for all licenses per COre &ndash;&gt;
                        <div v-if="tag.tagType === 'Software per Core'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          <a-tooltip placement="top">
                            <template #title>
                              {{ $t('label.remove.license') }}
                            </template>
                            <a-button
                              v-if="['Admin'].includes($store.getters.userInfo.roletype) && (tag.tagType === 'Software per User' || tag.tagType === 'Software per Core')"
                              style="margin-right: 4px;"
                              type="primary"
                              shape="circle"
                              size="small"
                              danger
                              @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                              <template #icon>
                                <DeleteOutlined class="iconToRight"/>
                              </template>
                            </a-button>
                          </a-tooltip>
                        </div>
                        &lt;!&ndash; Case NoBIll licenses &ndash;&gt;
                        <div v-if="tag.tagType === 'NoBill'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          <a-tooltip placement="top">
                            <template #title>
                              {{ $t('label.remove.license') }}
                            </template>
                            <a-button
                              v-if="['Admin'].includes($store.getters.userInfo.roletype) && tag.tagType === 'NoBIll'"
                              style="margin-right: 4px;"
                              type="danger"
                              shape="circle"
                              size="small"
                              @click="openDeleteVMLicenseModal(tag.tagText, tag.tagColor, tag.id)">
                              <template #icon>
                                <DeleteOutlined class="iconToRight"/>
                              </template>
                            </a-button>
                          </a-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash; if no licenses are associated with the current VM &ndash;&gt;
                  <div v-else>
                    {{ $t('message.no.associated.licenses') }}
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </a-spin>
          </div>
          LICENSE INFO FOR ROOT VOLUMES
          <div class="resource-detail-item" v-if="$route.meta.name !== 'zone' && $route.path.startsWith('/volume/') && resource.virtualmachineid && resource.type === 'ROOT'"> &lt;!&ndash; Show Only when Snapshot Page is present &ndash;&gt;
            &lt;!&ndash; Always Show The Licenses Title with the Add license Button in Volume Page &ndash;&gt;
            <span class="resource-detail-item__label">{{ $t('label.licences') }}</span>
            &lt;!&ndash; Show all Information about License Tags if there are any licenses associated with current Snapshot &ndash;&gt;
            <a-spin :spinning="licenseLoading">
              <a-collapse :bordered="true" accordion>
                <a-collapse-panel key="1" :header="$t('label.license.click.to.show')">
                  <div class="resource-detail-item__details" v-if="vmLicenceTags.length >= 1">
                    <div style="display: flex; flex-direction: column; margin-top: 8px;">
                      <div
                        v-for="tag in vmLicenceTags"
                        :key="tag.id">
                        &lt;!&ndash; If there is ever a new License Type needed, add it here! &ndash;&gt;
                        &lt;!&ndash; Case for all Licenses Per User &ndash;&gt;
                        <div v-if="tag.tagType === 'Software per User'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Amount and price for User Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ tag.licenseCounter }} User x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for all OS licenses (Should always only be one though) &ndash;&gt;
                        <div v-if="tag.tagType === 'OS'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                        </div>
                        &lt;!&ndash; Case for all Software Package Licenses &ndash;&gt;
                        <div v-if="tag.tagType === 'Software Package'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag style="margin-bottom: 2px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Amount and price for User Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for all licenses per COre &ndash;&gt;
                        <div v-if="tag.tagType === 'Software per Core'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                          <br>
                          &lt;!&ndash; this is the Amount and price for Core Licenses &ndash;&gt;
                          <span style="margin-left: 1.5rem;">{{ tag.licenseCounter + ' ' }}{{ tag.licenseCounter === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores') }} x {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} € = {{ ['Admin'].includes($store.getters.userInfo.roletype) ? 0 : parseFloat(tag.licenseCounter * discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) }} €</span>
                        </div>
                        &lt;!&ndash; Case for NoBill licenses &ndash;&gt;
                        <div v-if="tag.tagType === 'NoBill'" style="margin-bottom: 0.6rem;">
                          <FileDoneOutlined />
                          <a-tag :color="tag.tagColor">{{ tag.tagText }}</a-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                  &lt;!&ndash; if no licenses are associated with the current VM &ndash;&gt;
                  <div v-else>
                    {{ $t('message.no.associated.licenses') }}
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </a-spin>
          </div>-->
          <!-- /WP Implementation -->
          <div class="resource-detail-item" v-if="(resource.state || resource.status) && $route.meta.name !== 'zone'">
            <div class="resource-detail-item__label">{{ $t('label.status') }}</div>
            <div class="resource-detail-item__details">
              <status class="status" :text="resource.state || resource.status" displayText/>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.allocationstate">
            <div class="resource-detail-item__label">{{ $t('label.allocationstate') }}</div>
            <div class="resource-detail-item__details">
              <status class="status" :text="resource.allocationstate" displayText/>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.resourcestate">
            <div class="resource-detail-item__label">{{ $t('label.resourcestate') }}</div>
            <div class="resource-detail-item__details">
              <status class="status" :text="resource.resourcestate" displayText/>
            </div>
          </div>

<!--          <div class="resource-detail-item" v-if="resource.id">
            <div class="resource-detail-item__label">{{ $t('label.id') }}</div>
            <div class="resource-detail-item__details">
              <tooltip-button
                tooltipPlacement="right"
                :tooltip="$t('label.copyid')"
                icon="barcode-outlined"
                type="dashed"
                size="small"
                :copyResource="resource.id"
                @onClick="$message.success($t('label.copied.clipboard'))" />
              <span style="margin-left: 10px;">{{ resource.id }}</span>
            </div>
          </div>-->
          <div class="resource-detail-item" v-if="resource.ostypename && resource.ostypeid">
            <div class="resource-detail-item__label">{{ $t('label.ostypename') }}</div>
            <div class="resource-detail-item__details">
              <span v-if="resource.icon && resource.icon.base64image || images.template || images.iso">
                <resource-icon :image="getImage(images.template || images.iso)" size="1x" style="margin-right: 5px"/>
              </span>
              <os-logo v-else :osId="resource.ostypeid" :osName="resource.ostypename" size="lg" style="margin-left: -1px" />
              <span style="margin-left: 8px">{{ resource.ostypename }}</span>
            </div>
          </div>
          <!-- WP Implementation -->
          <!-- License Info at the right InfoCard of DeployVM.vue -->
          <div class="resource-detail-item" v-if="$route.path.startsWith('/action/deployVirtualMachine') && resource.isoName === null && this.licenceTags.length >= 1">
            <div class="resource-detail-item__label">{{ $t('label.licences') }}</div>
            <div class="resource-detail-item__details">
              <!-- is template one of Windows10 or Windows Server AND SoftwareLizeenz -->
              <div style="display: flex; flex-direction: column;">
                <div
                  v-for="tag in licenceTags"
                  :key="tag.tagText">
                  <!-- If there is ever a new License Type needed, add it here! -->
                  <span v-if="tag.tagType === 'OS'" style="font-size: 13px;">
                    <FileDoneOutlined /><a-tag style="font-size: 8px; margin-right: 4px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>{{ resource.cpunumber + (resource.cpunumber === 1 ? ' CPU' : ' CPUs') + ' = ' + (resource.cpunumber * parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated)).toFixed(2) + ' €' }}
                  </span>
                  <span v-if="tag.tagType === 'Software per User'" style="font-size: 13px;">
                    <FileDoneOutlined /><a-tag style="font-size: 8px; margin-right: 4px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>{{ (licenseAmount[tag.id] === undefined ? 0 : licenseAmount[tag.id]) + ' ' + (licenseAmount[tag.id] === 1 ? $t('label.licence') : $t('label.licences')) + ' = ' + ((licenseAmount[tag.id] === undefined ? 0 : licenseAmount[tag.id]) * parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated)).toFixed(2) + ' €' }}
                  </span>
                  <span v-if="tag.tagType === 'Software per Core'" style="font-size: 13px;">
                    <FileDoneOutlined /><a-tag style="font-size: 8px; margin-right: 4px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>{{ (licenseAmount[tag.id] === undefined ? 0 : licenseAmount[tag.id]) + ' ' + (licenseAmount[tag.id] === 1 ? $t('label.metrics.num.cpu.core') : $t('label.metrics.num.cpu.cores')) + ' = ' + ((licenseAmount[tag.id] === undefined ? 0 : licenseAmount[tag.id]) * parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated)).toFixed(2) + ' €' }}
                  </span>
                  <span v-if="tag.tagType === 'Software Package'" style="font-size: 13px;">
                    <FileDoneOutlined /><a-tag style="font-size: 8px; margin-right: 4px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>{{ parseFloat(discountList.filter(x => x.usageName === tag.tagText)[0].discountCalculated).toFixed(2) + ' €' }}
                  </span>
                  <span v-if="tag.tagType === 'NoBill'" style="font-size: 13px;">
                    <FileDoneOutlined /><a-tag style="font-size: 8px; margin-right: 4px;" :color="tag.tagColor">{{ tag.tagText }}</a-tag>{{ 0+ ' €' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- /WP Implementation -->
          <div class="resource-detail-item" v-if="('cpunumber' in resource && 'cpuspeed' in resource) || resource.cputotal">
            <div class="resource-detail-item__label">{{ $t('label.cpu') }}</div>
            <div class="resource-detail-item__details">
              <appstore-outlined />
              <!-- WP Implementation -->
              <span v-if="$route.path.startsWith('/action/deployVirtualMachine')">{{ resource.cpunumber + ' CPU = ' + (pricePerCPU * resource.cpunumber).toFixed(2) + ' €' }}</span>
              <span v-else>{{ (resource.cpunumber || resource.cputotal) === 1 ? (resource.cpunumber || resource.cputotal) + ' CPU' : (resource.cpunumber || resource.cputotal) + ' CPUs' }}</span>
              <!-- /WP Implementation -->
            </div>
            <!-- <div>
              <span v-if="resource.cpuused">
                <a-progress
                  v-if="resource.cpuused"
                  class="progress-bar"
                  size="small"
                  status="active"
                  :percent="parseFloat(resource.cpuused)"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.used')"
                />
              </span>
              <span v-if="resource.cpuallocated">
                <a-progress
                  class="progress-bar"
                  size="small"
                  :percent="parseFloat(resource.cpuallocated)"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.allocated')"
                />
              </span>
            </div> -->
          </div>
          <div class="resource-detail-item" v-if="'memory' in resource">
            <div class="resource-detail-item__label">{{ $t('label.memory') }}</div>
            <div class="resource-detail-item__details">
              <!-- WP Implementation -->
              <span v-if="$route.path.startsWith('/action/deployVirtualMachine')"><SaveOutlined />{{ (resource.memory / 1024) + ' GB ' + $t('label.memory') + ' = ' + (resource.memory * pricePerRAM).toFixed(2) + ' €' }}</span>
              <span v-else><SaveOutlined />{{ (resource.memory / 1024) + ' GB ' + $t('label.memory') }}</span>
            </div>
            <!-- <div>
              <span v-if="resource.memorykbs && resource.memoryintfreekbs">
                <a-progress
                  class="progress-bar"
                  size="small"
                  status="active"
                  :percent="Number(parseFloat(100.0 * (resource.memorykbs - resource.memoryintfreekbs) / resource.memorykbs).toFixed(2))"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.used')"
                />
              </span>
            </div> -->
            <!-- /WP Implementation -->
          </div>
          <div class="resource-detail-item" v-else-if="resource.memorytotalgb">
            <div class="resource-detail-item__label">{{ $t('label.memory') }}</div>
            <div class="resource-detail-item__details">
              <bulb-outlined />{{ resource.memorytotalgb + ' ' + $t('label.memory') }}
            </div>
            <!-- <div>
              <span v-if="resource.memoryusedgb">
                <a-progress
                  class="progress-bar"
                  size="small"
                  status="active"
                  :percent="Number(parseFloat(100.0 * parseFloat(resource.memoryusedgb) / parseFloat(resource.memorytotalgb)).toFixed(2))"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.used')"
                />
              </span>
              <span v-if="resource.memoryallocatedgb">
                <a-progress
                  class="progress-bar"
                  size="small"
                  :percent="Number(parseFloat(100.0 * parseFloat(resource.memoryallocatedgb) / parseFloat(resource.memorytotalgb)).toFixed(2))"
                  :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.allocated')"
                />
              </span>
            </div> -->
          </div>
          <div class="resource-detail-item" v-else-if="resource.memorytotal">
            <div class="resource-detail-item__label">{{ $t('label.memory') }}</div>
            <div class="resource-detail-item__details">

              <div style="display: flex; flex-direction: column; width: 100%;">
                <div>
                  <bulb-outlined />{{ resource.memorytotal + ' ' + $t('label.memory') }}
                </div>
                <div>
                  <span
                    v-if="resource.memoryused">
                    <a-progress
                      class="progress-bar"
                      size="small"
                      status="active"
                      :percent="parseFloat(resource.memoryused)"
                      :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.used')" />
                  </span>
                  <span
                    v-if="resource.memoryallocated">
                    <a-progress
                      class="progress-bar"
                      size="small"
                      :percent="parseFloat(resource.memoryallocated)"
                      :format="(percent, successPercent) => parseFloat(percent).toFixed(2) + '% ' + $t('label.allocated')" />
                  </span>
                </div>
              </div>

            </div>
          </div>

          <div class="resource-detail-item" v-if="resource.nic || ('networkkbsread' in resource && 'networkkbswrite' in resource)">
            <div class="resource-detail-item__label">{{ $t('label.network') }}</div>
            <div class="resource-detail-item__details resource-detail-item__details--start">
              <wifi-outlined />
              <div>
                <div v-if="'networkkbsread' in resource && 'networkkbswrite' in resource">
                  <a-tag><ArrowDownOutlined />RX {{ toSize(resource.networkkbsread) }}</a-tag>
                  <a-tag><ArrowUpOutlined />TX {{ toSize(resource.networkkbswrite) }}</a-tag>
                </div>
                <div v-else>{{ resource.nic.length }} NIC(s)</div>
                <div
                  v-for="(eth, index) in resource.nic"
                  :key="eth.id"
                  style="margin-left: -24px; margin-top: 5px;">
                  <api-outlined /><strong>eth{{ index }}</strong> {{ eth.ip6address ? eth.ipaddress + ', ' + eth.ip6address : eth.ipaddress }}
                  <router-link v-if="!isStatic && eth.networkname && eth.networkid" :to="{ path: '/guestnetwork/' + eth.networkid }">({{ eth.networkname }})</router-link>
                  <a-tag v-if="eth.isdefault">
                    {{ $t('label.default') }}
                  </a-tag >
                </div>
              </div>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.networks && resource.networks.length > 0">
            <div class="resource-detail-item__label">{{ $t('label.networks') }}</div>
            <div class="resource-detail-item__details resource-detail-item__details--start">
              <div>
                <div
                  v-for="network in resource.networks"
                  :key="network.id"
                  style="margin-top: 5px;">
                  <api-outlined />{{ network.name }}
                  <span v-if="resource.defaultnetworkid === network.id">
                    ({{ $t('label.default') }})
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.ipaddress">
            <div class="resource-detail-item__label">{{ $t('label.ip') }}</div>
            <div class="resource-detail-item__details">
              <environment-outlined
                @click="$message.success(`${$t('label.copied.clipboard')} : ${ ipaddress }`)"
                v-clipboard:copy="ipaddress" />
              <router-link v-if="!isStatic && resource.ipaddressid" :to="{ path: '/publicip/' + resource.ipaddressid }">{{ ipaddress }}</router-link>
              <span v-else>{{ ipaddress }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.projectid || resource.projectname">
            <div class="resource-detail-item__label">{{ $t('label.project') }}</div>
            <div class="resource-detail-item__details">
              <span v-if="images.project">
                <resource-icon :image="getImage(images.project)" size="1x" style="margin-right: 5px"/>
              </span>
              <project-outlined v-else />
              <router-link v-if="!isStatic && resource.projectid" :to="{ path: '/project/' + resource.projectid }">{{ resource.project || resource.projectname || resource.projectid }}</router-link>
              <router-link v-else :to="{ path: '/project', query: { name: resource.projectname }}">{{ resource.projectname }}</router-link>
            </div>
          </div>

          <div class="resource-detail-item">
            <slot name="details">
            </slot>
          </div>

          <div class="resource-detail-item" v-if="resource.groupid">
            <div class="resource-detail-item__label">{{ $t('label.group') }}</div>
            <div class="resource-detail-item__details">
              <gold-outlined />
              <router-link :to="{ path: '/vmgroup/' + resource.groupid }">{{ resource.group || resource.groupid }}</router-link>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.keypairs && resource.keypairs.length > 0">
            <div class="resource-detail-item__label">{{ $t('label.keypairs') }}</div>
            <div class="resource-detail-item__details">
              <key-outlined />
              <li v-for="keypair in keypairs" :key="keypair">
                <router-link :to="{ path: '/ssh/' + keypair }" style="margin-right: 5px">{{ keypair }}</router-link>
              </li>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.resourcetype && resource.resourceid && routeFromResourceType">
            <div class="resource-detail-item__label">{{ $t('label.resource') }}</div>
            <div class="resource-detail-item__details">
              <resource-label :resourceType="resource.resourcetype" :resourceId="resource.resourceid" :resourceName="resource.resourcename" />
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.virtualmachineid">
            <div class="resource-detail-item__label">{{ $t('label.vmname') }}</div>
            <div class="resource-detail-item__details">
              <desktop-outlined />
              <router-link :to="{ path: '/vm/' + resource.virtualmachineid }">{{ resource.vmname || resource.vm || resource.virtualmachinename || resource.virtualmachineid }} </router-link>
              <status class="status status--end" :text="resource.vmstate" v-if="resource.vmstate"/>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.volumeid">
            <div class="resource-detail-item__label">{{ $t('label.volume') }}</div>
            <div class="resource-detail-item__details">
              <hdd-outlined />
              <router-link :to="{ path: '/volume/' + resource.volumeid }">{{ resource.volumename || resource.volume || resource.volumeid }} </router-link>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.associatednetworkid">
            <div class="resource-detail-item__label">{{ $t('label.associatednetwork') }}</div>
            <div class="resource-detail-item__details">
              <wifi-outlined />
              <router-link :to="{ path: '/guestnetwork/' + resource.associatednetworkid }">{{ resource.associatednetworkname || resource.associatednetwork || resource.associatednetworkid }} </router-link>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.sourceipaddressnetworkid">
            <div class="resource-detail-item__label">{{ $t('label.network') }}</div>
            <div class="resource-detail-item__details">
              <wifi-outlined />
              <router-link :to="{ path: '/guestnetwork/' + resource.sourceipaddressnetworkid }">{{ resource.sourceipaddressnetworkname || resource.sourceipaddressnetworkid }} </router-link>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.guestnetworkid">
            <div class="resource-detail-item__label">{{ $t('label.guestnetwork') }}</div>
            <div class="resource-detail-item__details">
              <gateway-outlined />
              <router-link :to="{ path: '/guestnetwork/' + resource.guestnetworkid }">{{ resource.guestnetworkname || resource.guestnetworkid }} </router-link>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.vpcid">
            <div class="resource-detail-item__label">{{ $t('label.vpcname') }}</div>
            <div class="resource-detail-item__details">
              <span v-if="images.vpc">
                <resource-icon :image="getImage(images.vpc)" size="1x" style="margin-right: 5px"/>
              </span>
              <deployment-unit-outlined v-else />
              <!-- WP Implementation -->
              <router-link v-if="$route.path.startsWith('/proxySettings/')" :to="{ path: '/vpc/' + resource.vpcid }">{{ resource.vpcname }}</router-link>
              <router-link v-else :to="{ path: '/vpc/' + resource.vpcid }">{{ resource.vpcname || resource.vpcid }}</router-link>
            </div>
          </div>

          <div class="resource-detail-item" v-if="resource.aclid">
            <div class="resource-detail-item__label">{{ $t('label.aclid') }}</div>
            <div class="resource-detail-item__details">
              <span v-if="images.acl">
                <resource-icon :image="getImage(images.acl)" size="1x" style="margin-right: 5px"/>
              </span>
              <deployment-unit-outlined v-else />
              <router-link :to="{ path: '/acllist/' + resource.aclid }">{{ resource.aclname || resource.aclid }}</router-link>
            </div>
          </div>

          <div class="resource-detail-item" v-if="resource.affinitygroup && resource.affinitygroup.length > 0">
            <div class="resource-detail-item__label">{{ $t('label.affinitygroup') }}</div>
            <SwapOutlined />
            <span
              v-for="(group, index) in resource.affinitygroup"
              :key="group.id"
            >
              <router-link :to="{ path: '/affinitygroup/' + group.id }">{{ group.name }}</router-link>
              <span v-if="index + 1 < resource.affinitygroup.length">, </span>
            </span>
          </div>
          <div class="resource-detail-item" v-if="resource.templateid">
            <div class="resource-detail-item__label">{{ resource.isoid ? $t('label.iso') : $t('label.templatename') }}</div>
            <div class="resource-detail-item__details">
              <resource-icon v-if="resource.icon" :image="getImage(resource.icon.base64image)" size="1x" style="margin-right: 5px"/>
              <PictureOutlined v-else />
              <div v-if="resource.isoid">
                <router-link :to="{ path: '/iso/' + resource.isoid }">{{ resource.isodisplaytext || resource.isoname || resource.isoid }} </router-link>
              </div>
              <div v-else>
                <router-link :to="{ path: '/template/' + resource.templateid }">{{ resource.templatedisplaytext || resource.templatename || resource.templateid }} </router-link>
              </div>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.serviceofferingname && resource.serviceofferingid">
            <div class="resource-detail-item__label">{{ $t('label.serviceofferingname') }}</div>
            <div class="resource-detail-item__details">
              <cloud-outlined />
              <router-link v-if="!isStatic && $route.meta.name === 'router'" :to="{ path: '/computeoffering/' + resource.serviceofferingid, query: { issystem: true } }">{{ resource.serviceofferingname || resource.serviceofferingid }} </router-link>
              <router-link v-else-if="$router.resolve('/computeoffering/' + resource.serviceofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/computeoffering/' + resource.serviceofferingid }">{{ resource.serviceofferingname || resource.serviceofferingid }} </router-link>
              <span v-else>{{ resource.serviceofferingname || resource.serviceofferingid }}</span>
            </div>
          </div>
<!--          <div class="resource-detail-item" v-if="resource.diskofferingname && resource.diskofferingid">
            <div class="resource-detail-item__label">{{ $t('label.diskoffering') }}</div>
            <div class="resource-detail-item__details">
              <hdd-outlined />
              <router-link v-if="!isStatic && $router.resolve('/diskoffering/' + resource.diskofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/diskoffering/' + resource.diskofferingid }">{{ resource.diskofferingname || resource.diskofferingid }} </router-link>
              <span v-else>{{ resource.diskofferingname || resource.diskofferingid }}</span>
            </div>
          </div>-->
          <div class="resource-detail-item" v-if="resource.backupofferingid">
            <div class="resource-detail-item__label">{{ $t('label.backupofferingid') }}</div>
            <cloud-upload-outlined />
            <router-link v-if="!isStatic && $router.resolve('/backupoffering/' + resource.backupofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/backupoffering/' + resource.backupofferingid }">{{ resource.backupofferingname || resource.backupofferingid }} </router-link>
            <span v-else>{{ resource.backupofferingname || resource.backupofferingid }}</span>
          </div>
          <div class="resource-detail-item" v-if="resource.networkofferingid">
            <div class="resource-detail-item__label">{{ $t('label.networkofferingid') }}</div>
            <div class="resource-detail-item__details">
              <wifi-outlined />
              <router-link v-if="!isStatic && $router.resolve('/networkoffering/' + resource.networkofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/networkoffering/' + resource.networkofferingid }">{{ resource.networkofferingname || resource.networkofferingid }} </router-link>
              <span v-else>{{ resource.networkofferingname || resource.networkofferingid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.vpcofferingid">
            <div class="resource-detail-item__label">{{ $t('label.vpcoffering') }}</div>
            <div class="resource-detail-item__details">
              <DeploymentUnitOutlined />
              <router-link v-if="!isStatic && $router.resolve('/vpcoffering/' + resource.vpcofferingid).matched[0].redirect !== '/exception/404'" :to="{ path: '/vpcoffering/' + resource.vpcofferingid }">{{ resource.vpcofferingname || resource.vpcofferingid }} </router-link>
              <span v-else>{{ resource.vpcofferingname || resource.vpcofferingid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.storageid">
            <div class="resource-detail-item__label">{{ $t('label.storagepool') }}</div>
            <div class="resource-detail-item__details">
              <database-outlined />
              <router-link v-if="!isStatic && $router.resolve('/storagepool/' + resource.storageid).matched[0].redirect !== '/exception/404'" :to="{ path: '/storagepool/' + resource.storageid }">{{ resource.storage || resource.storageid }} </router-link>
              <span v-else>{{ resource.storage || resource.storageid }}</span>
              <a-tag style="margin-left: 5px;" v-if="resource.storagetype">
                {{ resource.storagetype }}
              </a-tag>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.hostid">
            <div class="resource-detail-item__label">{{ $t('label.hostname') }}</div>
            <div class="resource-detail-item__details">
              <desktop-outlined />
              <router-link v-if="!isStatic && $router.resolve('/host/' + resource.hostid).matched[0].redirect !== '/exception/404'" :to="{ path: '/host/' + resource.hostid }">{{ resource.hostname || resource.hostid }} </router-link>
              <span v-else>{{ resource.hostname || resource.hostid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.clusterid">
            <div class="resource-detail-item__label">{{ $t('label.clusterid') }}</div>
            <div class="resource-detail-item__details">
              <cluster-outlined />
              <router-link v-if="!isStatic && $router.resolve('/cluster/' + resource.clusterid).matched[0].redirect !== '/exception/404'" :to="{ path: '/cluster/' + resource.clusterid }">{{ resource.clustername || resource.cluster || resource.clusterid }}</router-link>
              <span v-else>{{ resource.clustername || resource.cluster || resource.clusterid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.podid">
            <div class="resource-detail-item__label">{{ $t('label.podid') }}</div>
            <div class="resource-detail-item__details">
              <appstore-outlined />
              <router-link v-if="!isStatic && $router.resolve('/pod/' + resource.podid).matched[0].redirect !== '/exception/404'" :to="{ path: '/pod/' + resource.podid }">{{ resource.podname || resource.pod || resource.podid }}</router-link>
              <span v-else>{{ resource.podname || resource.pod || resource.podid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.zoneid">
            <div class="resource-detail-item__label">{{ $t('label.zone') }}</div>
            <div class="resource-detail-item__details">
              <span v-if="images.zone">
                <resource-icon :image="getImage(images.zone)" size="1x" style="margin-right: 5px"/>
              </span>
              <global-outlined v-else />
              <router-link v-if="!isStatic && $router.resolve('/zone/' + resource.zoneid).matched[0].redirect !== '/exception/404'" :to="{ path: '/zone/' + resource.zoneid }">{{ resource.zone || resource.zonename || resource.zoneid }}</router-link>
              <span v-else>{{ resource.zone || resource.zonename || resource.zoneid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.owner">
            <div class="resource-detail-item__label">{{ $t('label.owners') }}</div>
            <div class="resource-detail-item__details">
              <user-outlined />
              <template v-for="(item, idx) in resource.owner" :key="idx">
                <span style="margin-right:5px">
                  <span v-if="$store.getters.userInfo.roletype !== 'User'">
                    <router-link v-if="!isStatic && 'user' in item" :to="{ path: '/accountuser', query: { username: item.user, domainid: resource.domainid }}">{{ item.account + '(' + item.user + ')' }}</router-link>
                    <router-link v-else :to="{ path: '/account', query: { name: item.account, domainid: resource.domainid } }">{{ item.account }}</router-link>
                  </span>
                  <span v-else>{{ item.user ? item.account + '(' + item.user + ')' : item.account }}</span>
                </span>
              </template>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.account && !resource.account.startsWith('PrjAcct-')">
            <div class="resource-detail-item__label">{{ $t('label.account') }}</div>
            <div class="resource-detail-item__details">
              <span v-if="images.account">
                <resource-icon :image="getImage(images.account)" size="1x" style="margin-right: 5px"/>
              </span>
              <user-outlined v-else />
              <router-link v-if="!isStatic && $store.getters.userInfo.roletype !== 'User'" :to="{ path: '/account', query: { name: resource.account, domainid: resource.domainid } }">{{ resource.account }}</router-link>
              <span v-else>{{ resource.account }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.roleid">
            <div class="resource-detail-item__label">{{ $t('label.role') }}</div>
            <div class="resource-detail-item__details">
              <idcard-outlined />
              <router-link v-if="!isStatic && $router.resolve('/role/' + resource.roleid).matched[0].redirect !== '/exception/404'" :to="{ path: '/role/' + resource.roleid }">{{ resource.rolename || resource.role || resource.roleid }}</router-link>
              <span v-else>{{ resource.rolename || resource.role || resource.roleid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.domainid">
            <div class="resource-detail-item__label">{{ $t('label.domain') }}</div>
            <div class="resource-detail-item__details">
              <resource-icon v-if="images.domain" :image="getImage(images.domain)" size="1x" style="margin-right: 5px"/>
              <block-outlined v-else />
              <router-link v-if="!isStatic && $store.getters.userInfo.roletype !== 'User'" :to="{ path: '/domain/' + resource.domainid, query: { tab: 'details'}  }">{{ resource.domain || resource.domainid }}</router-link>
              <span v-else>{{ resource.domain || resource.domainid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.managementserverid">
            <div class="resource-detail-item__label">{{ $t('label.management.servers') }}</div>
            <div class="resource-detail-item__details">
              <rocket-outlined />
              <router-link v-if="!isStatic && $router.resolve('/managementserver/' + resource.managementserverid).matched[0].redirect !== '/exception/404'" :to="{ path: '/managementserver/' + resource.managementserverid }">{{ resource.managementserver || resource.managementserverid }}</router-link>
              <span v-else>{{ resource.managementserver || resource.managementserverid }}</span>
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.created">
            <div class="resource-detail-item__label">{{ $t('label.created') }}</div>
            <div class="resource-detail-item__details">
              <calendar-outlined />{{ $toLocaleDate(resource.created) }}
            </div>
          </div>
          <div class="resource-detail-item" v-if="resource.lastupdated">
            <div class="resource-detail-item__label">{{ $t('label.last.updated') }}</div>
            <div class="resource-detail-item__details">
              <calendar-outlined />{{ $toLocaleDate(resource.lastupdated) }}
            </div>
          </div>
        </div>
        </div>
        </a-col>
        <a-col :md="24" :lg="12">
      <div class="account-center-tags" v-if="$route.meta.related">
        <div class='card-grey'>
          <div class='card-grey-header'><appstore-outlined class='is--blue'/> {{ $t('label.actions')}}</div>

          <div v-for="item in $route.meta.related" :key="item.path">
            <router-link
              v-if="$router.resolve('/' + item.name).matched[0].redirect !== '/exception/404'"
              :to="{ name: item.name, query: getRouterQuery(item) }">
              <a-button style="margin-right: 10px">
                <template #icon>
                  <render-icon :icon="$router.resolve('/' + item.name).meta.icon" />
                </template>
                {{ $t('label.view') + ' ' + $t(item.title) }}
              </a-button>
            </router-link>
          </div>
        </div>
      </div>

      <div class="account-center-tags" v-if="showKeys">
        <div class='card-grey'>
          <div class='card-grey-header'><key-outlined /> {{ $t('label.UserKeys')}}</div>
          <div class="user-keys">
          <key-outlined />
          <strong>
            {{ $t('label.apikey') }}
            <tooltip-button
              tooltipPlacement="right"
              :tooltip="$t('label.copy') + ' ' + $t('label.apikey')"
              icon="CopyOutlined"
              type="dashed"
              size="small"
              @onClick="$message.success($t('label.copied.clipboard'))"
              :copyResource="resource.apikey" />
          </strong>
          <div>
            {{ resource.apikey.substring(0, 20) }}...
          </div>
        </div> <br/>
          <div class="user-keys">
          <lock-outlined />
          <strong>
            {{ $t('label.secretkey') }}
            <tooltip-button
              tooltipPlacement="right"
              :tooltip="$t('label.copy') + ' ' + $t('label.secretkey')"
              icon="CopyOutlined"
              type="dashed"
              size="small"
              @onClick="$message.success($t('label.copied.clipboard'))"
              :copyResource="resource.secretkey" />
          </strong>
          <div>
            {{ resource.secretkey.substring(0, 20) }}...
          </div>
        </div>
        </div>
      </div>

      <div class="account-center-tags" v-if="!isStatic && resourceType && tagsSupportingResourceTypes.includes(this.resourceType) && 'listTags' in $store.getters.apis">
        <div class='card-grey'>
          <div class='card-grey-header'><tag-outlined class='is--blue'/> {{ $t('label.tags')}}</div>
          <a-spin :spinning="loadingTags">
          <div>
            <template v-for="(tag, index) in tags" :key="index">
              <a-tag :closable="isAdminOrOwner() && 'deleteTags' in $store.getters.apis" @close="() => handleDeleteTag(tag)">
                {{ tag.key }} = {{ tag.value }}
              </a-tag>
            </template>

            <div v-if="inputVisible">
              <a-input-group
                type="text"
                size="small"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
                compact>
                <a-input ref="input" :value="inputKey" @change="handleKeyChange" style="width: 30%; text-align: center" :placeholder="$t('label.key')" />
                <a-input
                  class="tag-disabled-input"
                  style="width: 30px; border-left: 0; pointer-events: none; text-align: center"
                  placeholder="="
                  disabled />
                <a-input :value="inputValue" @change="handleValueChange" style="width: 30%; text-align: center; border-left: 0" :placeholder="$t('label.value')" />
                <tooltip-button :tooltip="$t('label.ok')" icon="CheckOutlined" size="small" @onClick="handleInputConfirm" />
                <tooltip-button :tooltip="$t('label.cancel')" icon="CloseOutlined" size="small" @onClick="inputVisible=false" />
              </a-input-group>
            </div>
            <a-tag
              @click="showInput"
              class="btn-add-tag"
              style="borderStyle: dashed;"
              v-else-if="isAdminOrOwner() && 'createTags' in $store.getters.apis">
              <plus-outlined  /> {{ $t('label.new.tag') }}
            </a-tag>
          </div>
        </a-spin>
        </div>
      </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import { api } from '@/api'
import Console from '@/components/widgets/Console'
import OsLogo from '@/components/widgets/OsLogo'
import Status from '@/components/widgets/Status'
import TooltipButton from '@/components/widgets/TooltipButton'
import UploadResourceIcon from '@/components/view/UploadResourceIcon'
import eventBus from '@/config/eventBus'
import ResourceIcon from '@/components/view/ResourceIcon'
import ResourceLabel from '@/components/widgets/ResourceLabel'
/* WP Implementation */
import wpapi from '../../wpApi/myApi'
import api2 from '@/wpApi/api2'
import AddAccount from '@/views/iam/AddAccount.vue'
import { Icon, Slider } from 'ant-design-vue'
import { LaptopOutlined, FileDoneOutlined } from '@ant-design/icons-vue'
import ActionButton from './ActionButton.vue'
import { reactive, ref, toRaw } from 'vue'

export default {
  name: 'InfoCard',
  components: {
    Console,
    OsLogo,
    Status,
    TooltipButton,
    UploadResourceIcon,
    ResourceIcon,
    ResourceLabel,
    Icon,
    Slider,
    ActionButton,
    AddAccount,
    LaptopOutlined,
    FileDoneOutlined
  },
  /* /WP Implementation */
  props: {
    resource: {
      type: Object,
      required: true
    },
    // WP Implementation
    diskSizeInput: {
      type: Number,
      default: () => 0
    },
    // this is the Nr of licenses for each tag with softwarelicenses from DeployVM.vue
    licenseAmount: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    licenceTags: {
      type: Array,
      required: false,
      default: Array
    },
    osListLoading: {
      type: Boolean,
      default: false
    },
    // /WP Implementation
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    bordered: {
      type: Boolean,
      default: true
    },
    isStatic: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tag: '',
      ipaddress: '',
      resourceType: '',
      inputVisible: false,
      inputKey: '',
      inputValue: '',
      tags: [],
      showKeys: false,
      loadingTags: false,
      showUpload: false,
      images: {
        zone: '',
        template: '',
        iso: '',
        domain: '',
        account: '',
        project: '',
        vpc: '',
        network: ''
      },
      newResource: {},
      // WP Implementation
      subAccountUuid: '',
      hddOfferingList: [],
      ssdOfferingList: [],
      openAccount: [],
      openAccountUser: [],
      acronisVmPbsIsActivated: false,
      acronisStatusActive: false,
      acronisUserLoading: false,
      acronisTenantIdCheck: /^[A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12}$/,
      showCreateAcronisModal: false,
      correctPasswords: true,
      confirmationMessage: '',
      confirmationModal: false,
      wpAccountData: [],
      accountUuid: this.$store.getters.userInfo.accountid,
      accountName: this.$store.getters.userInfo.account,
      vmLicenceTags: [],
      discountList: [],
      showAddLicenseModal: false,
      showEditLicenseModal: false,
      showDeleteLicenseModal: false,
      showAddAccountModal: false,
      modalInputTagColor: String,
      modalInputTagText: String,
      modalInputTagType: String,
      modalInputTagId: String,
      existingTags: [],
      snapshotTags: [],
      templateTags: [],
      selectedTag: [],
      tagReservedForAdmin: false,
      addSoftwareLicensesAmount: 1,
      modalLoading: false,
      licenseLoading: false,
      // this data param is the actual number of defined userLicenses for a allready existing VM
      nrOfSoftwareLicenses: {},
      submitTagInput: 0,
      currentSoftwareLicenses: 1,
      errors: {
        addLicenseInput: {
          status: '',
          message: ''
        },
        editLicenseInput: {
          status: '',
          message: ''
        }
      }
    }
  },
  // /WP Implementation
  watch: {
    '$route.fullPath': function (path) {
      if (path === '/user/login') {
        return
      }
      this.getIcons()
    },
    resource: {
      deep: true,
      async handler (newData, oldData) {
        if (newData === oldData) return
        this.newResource = newData
        this.showKeys = false
        this.setData()
        // WP Implementation
        if (this.$route.path.startsWith('/vm/')) {
          this.fetchAcronisVmStatus()
          await this.getTagsFromVm(this.resource.id)
          this.fetchNrOfSoftwareLicencesAllVm(this.resource.id)
          this.getAllTagsApi()
        }
        if (this.$route.path.startsWith('/snapshot/') || this.$route.path.startsWith('/vmsnapshot/')) {
          this.getSnapshotLicenseTagInfos()
        }
        if (this.$route.path.startsWith('/template/')) {
          this.getTemplateLicenseTagInfos()
          this.getAllTagsApi()
        }
        if (this.$route.path.startsWith('/volume/') && this.resource.virtualmachineid) {
          this.getTagsFromVm(this.resource.virtualmachineid)
          this.fetchNrOfSoftwareLicencesAllVm(this.resource.virtualmachineid)
          this.getAllTagsApi()
        }
        // /WP Implementation
        if (this.tagsSupportingResourceTypes.includes(this.resourceType)) {
          if ('tags' in this.resource) {
            this.tags = this.resource.tags
          } else if (this.resourceType) {
            this.getTags()
          }
        }
        if ('apikey' in this.resource) {
          this.getUserKeys()
        }
        this.updateResourceAdditionalData()
        // WP Implementation
        this.fetchResellerCustomerAccounts()
        // /WP Implementation
      }
    },
    async templateIcon () {
      this.getIcons()
    },
    // WP Implementation
    nrOfSoftwareLicenses: function (newItem, oldItem) {
      if (newItem !== oldItem) {
        this.$forceUpdate()
      }
    },
    vmLicenceTags: function (newItem, oldItem) {
      if (newItem !== oldItem) {
        this.$forceUpdate()
      }
    }
  },
  // /WP Implementation
  async created () {
    this.initForm()
    this.setData()
    eventBus.on('handle-close', (showModal) => {
      this.showUploadModal(showModal)
    })
    this.updateResourceAdditionalData()
    await this.getIcons()
    if (this.$route.path.startsWith('/account')) {
      await this.getOpenAccountAccId()
      await this.fetchAcronisUserStatus()
    }
  },
  computed: {
    selectedTagInfoWithoutNoBill () {
      const tagInfo = this.existingTags.filter(tag => tag.types !== 'NoBill')
      return tagInfo
    },
    selectedTagInfoWithoutNoBillAndOS () {
      const tagInfo = this.existingTags.filter(tag => tag.types !== 'NoBill' && tag.types !== 'OS')
      return tagInfo
    },
    selectedTagInfo () {
      const tagInfo = this.existingTags.filter(tag => tag.key === this.selectedTag)[0]
      return tagInfo === undefined ? undefined : tagInfo
    },
    tagsSupportingResourceTypes () {
      return ['UserVm', 'Template', 'ISO', 'Volume', 'Snapshot', 'Backup', 'Network',
        'LoadBalancer', 'PortForwardingRule', 'FirewallRule', 'SecurityGroup', 'SecurityGroupRule',
        'PublicIpAddress', 'Project', 'Account', 'Vpc', 'NetworkACL', 'StaticRoute', 'VMSnapshot',
        'RemoteAccessVpn', 'User', 'SnapshotPolicy', 'VpcOffering']
    },
    name () {
      return this.resource.displayname || this.resource.displaytext || this.resource.name || this.resource.username ||
        this.resource.ipaddress || this.resource.virtualmachinename || this.resource.templatetype
    },
    keypairs () {
      if (!this.resource.keypairs) {
        return null
      }
      if (typeof this.resource.keypairs === 'string' || this.resource.keypairs instanceof String) {
        return this.resource.keypairs.split(',')
      }
      return [this.resource.keypairs.toString()]
    },
    templateIcon () {
      return this.resource.templateid
    },
    resourceIcon () {
      if (this.$showIcon() && this.resource?.icon?.base64image) {
        return this.resource.icon.base64image
      }
      return null
    },
    routeFromResourceType () {
      return this.$getRouteFromResourceType(this.resource.resourcetype)
    },
    // WP Implementation
    pricePerOS: function () {
      if (this.resource.isoName === null) {
        if (this.licenceTags.length >= 1 && this.discountList.length) {
          var osUsage = this.licenceTags.filter(x => x.tagType === 'OS')[0]
          return this.discountList.filter(x => x.usageName === osUsage.tagText)[0].discountCalculated
        } else { return 0 }
      } else { return 0 }
    },
    pricePerCPU: function () {
      // is there data in discountList yet?
      const vCPUUsage = this.discountList.filter(x => x.usageName === 'vCPU')[0]
      if (this.discountList.length) {
        // no discount?
        if (vCPUUsage.discount === 0) {
          return vCPUUsage.quotaTariff
          // discount?
        } else if (vCPUUsage.discount !== 0) {
          return vCPUUsage.discountCalculated
        } else {
          return 0
        }
      } else return 0
    },
    pricePerRAM: function () {
      const ramUsage = this.discountList.filter(x => x.usageName === 'MEMORY')[0]
      if (this.discountList.length) {
        if (ramUsage.discount === 0) {
          return ramUsage.quotaTariff
        } else if (ramUsage.discount !== 0) {
          return ramUsage.discountCalculated
        } else {
          return 0
        }
      } else return 0
    },
    priceSSDStorage: function () {
      const ssdUsage = this.discountList.filter(x => x.usageName.toLowerCase().includes('ssd'))[0]
      if (this.discountList.length) {
        if (ssdUsage.discount === 0) {
          return ssdUsage.quotaTariff
        } else if (ssdUsage.discount !== 0) {
          return ssdUsage.discountCalculated
        } else {
          return 0
        }
      } else return 0
    },
    priceHDDStorage: function () {
      const hddUsage = this.discountList.filter(x => x.usageName.toLowerCase().includes('hdd'))[0]
      if (this.discountList.length) {
        if (hddUsage.discount === 0) {
          return hddUsage.quotaTariff
        } else if (hddUsage.discount !== 0) {
          return hddUsage.discountCalculated
        } else {
          return 0
        }
      } else return 0
    },
    // adds up the costs for all tags with softwarelicenses because a template can have multiple software tags of the same type
    totalLicenseCosts: function () {
      if (this.resource.isoName === null) {
        var tags = this.licenceTags
        var totalCost = 0
        if (this.discountList.length) {
          for (var tag in tags) {
            if (tags[tag].tagType === 'Software per User') {
              totalCost += (this.licenseAmount[tags[tag].id] === undefined ? 0 : this.licenseAmount[tags[tag].id]) * this.discountList.filter(x => x.usageName === tags[tag].tagText)[0].discountCalculated
            } else if (tags[tag].tagType === 'Software per Core') {
              totalCost += (this.licenseAmount[tags[tag].id] === undefined ? 0 : this.licenseAmount[tags[tag].id]) * this.discountList.filter(x => x.usageName === tags[tag].tagText)[0].discountCalculated
            } else if (tags[tag].tagType === 'OS') {
              totalCost += 0
            } else if (tags[tag].tagType === 'Software Package') {
              totalCost += this.discountList.filter(x => x.usageName === tags[tag].tagText)[0].discountCalculated
            } else {
              return 0
            }
          }
          return totalCost
        } else {
          return 0
        }
      } else return 0
    }
    // /WP Implementation
  },
  async mounted () {
    this.getIcons()
    // WP Implementation
    if (this.$route.path.startsWith('/action/deployVirtualMachine') || this.$route.path.startsWith('/vm') || this.$route.path.startsWith('/snapshot') || this.$route.path.startsWith('/volume')) {
      if (!['Admin'].includes(this.$store.getters.userInfo.roletype)) {
        await this.getDiscountList()
      }
    }
    if (this.$route.path.startsWith('/action/deployVirtualMachine')) {
      this.getDiskOfferingList()
    }
    // /WP Implementation
  },
  methods: {
    getDiskOfferingList () {
      api2.getDiskofferingList().then(response => {
        if (response.data) {
          this.hddOfferingList = response.data.HDD
          this.ssdOfferingList = response.data.SSD
        } else {
          console.log('Error fetching DiskOfferingList!')
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    updateResourceAdditionalData () {
      if (!this.resource) return
      this.resourceType = this.$route.meta.resourceType
      if (this.tagsSupportingResourceTypes.includes(this.resourceType)) {
        if ('tags' in this.resource) {
          this.tags = this.resource.tags
        } else if (this.resourceType) {
          this.getTags()
        }
      }
      this.getIcons()
    },
    initForm () {
      this.formRefAddAcronisUser = ref()
      this.formRefAddLicenseTag = ref()
      this.formAddAcronisUser = reactive({
        pw: undefined,
        confirmpw: undefined
      })
      this.formAddLicenseTag = reactive({
        tag: undefined,
        tagColor: undefined
      })
      this.addAcronisUserRules = reactive({
        pw: [{ required: true, message: 'Bitte geben Sie ein Passwort an!' }],
        confirmpw: [{ required: true, message: 'Bitte bestätigen Sie ihr Passwort!' }]
      })
      this.addLicenseTagRules = reactive({
        tag: [{ required: true, message: this.$t('label.required') }]
      })
    },
    // WP Implementation
    async linkClicked () {
      this.acronisUserLoading = true
      var urlParams = new FormData()
      urlParams.append('accountid', this.resource.id)
      await api2.generateOneTimeUrl(urlParams).then(response => {
        if (response.data && response.data.status !== 'error' && response.data.includes('acronis.com')) {
          var a = document.createElement('a')
          a.href = response.data
          a.setAttribute('target', '_blank')
          a.click()
        } else {
          console.log('Error creating one time Cyber Cloud login!')
        }
        this.acronisUserLoading = false
      }).catch((error) => {
        console.log(error)
        this.acronisUserLoading = false
      })
    },
    confirmAndReload () {
      this.confirmationModal = false
      location.reload()
    },
    async getOpenAccountAccId () {
      await api('listAccounts', { listall: true, id: this.$route.params.id }).then(response => {
        var responseAcc = response.listaccountsresponse.account ? response.listaccountsresponse.account[0] : []
        this.openAccount = responseAcc !== undefined ? responseAcc : []
        this.openAccountUser = responseAcc.user !== undefined ? responseAcc.user[0] : []
      }).catch(error => {
        console.log(error)
      })
    },
    async fetchAcronisUserStatus () {
      var getUserParams = new FormData()
      getUserParams.append('account', this.openAccount.id)
      await api2.getAcronisUser(getUserParams).then(response => {
        if (response.data.status === 'ok' && this.acronisTenantIdCheck.test(response.data.tenant_id)) {
          this.acronisStatusActive = true
        } else { this.acronisStatusActive = false }
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async fetchAcronisVmStatus () {
      var getUserParams = new FormData()
      getUserParams.append('vmid', this.resource.id)
      await api2.getAcronisVM(getUserParams).then(response => {
        if (response.data && response.data.status === 'error') {
          this.acronisVmPbsIsActivated = false
        } else if (response.data && response.data.id && this.acronisTenantIdCheck.test(response.data.id)) {
          this.acronisVmPbsIsActivated = true
        } else {
          this.acronisVmPbsIsActivated = false
          console.log('Failed to request Acronis VM Status')
        }
      }).catch(error => {
        this.$notifyError(error)
      })
    },
    async fetchWpAccountData () {
      await wpapi.getWpAccountData(this.openAccount.id).then(response => {
        this.wpAccountData = response.data
      }).catch(error => {
        console.log(error)
      })
    },
    async createAcronisUser () {
      this.acronisUserLoading = true
      await this.fetchWpAccountData()
      // createAcronisUserParams = new FormData()
      this.showCreateAcronisModal = true
      this.acronisUserLoading = false
    },
    createConfirmationModal (msg) {
      this.confirmationMessage = msg
      this.confirmationModal = true
    },
    handleCreateAcronisUserSubmit () {
      this.formRefAddAcronisUser.value.validate().then(async () => {
        const values = toRaw(this.formAddAcronisUser)
        if (values.pw !== values.confirmpw) {
          this.correctPasswords = false
          return
        }
        this.correctPasswords = true
        this.acronisUserLoading = true
        var createAcronisUserParams = new FormData()
        createAcronisUserParams.append('tenant_companyname', this.openAccount.name)
        createAcronisUserParams.append('tenant_address', this.wpAccountData.address + ', ' + this.wpAccountData.postalCode + ' ' + this.wpAccountData.city + ', ' + this.wpAccountData.country)
        createAcronisUserParams.append('tenant_email', this.openAccountUser.email)
        createAcronisUserParams.append('tenant_phone', '0000000000')
        createAcronisUserParams.append('tenant_user_login', this.openAccount.id)
        createAcronisUserParams.append('tenant_user_password', values.pw)
        createAcronisUserParams.append('tenant_user_email', this.openAccountUser.email)
        createAcronisUserParams.append('tenant_user_firstname', this.openAccountUser.firstname)
        createAcronisUserParams.append('tenant_user_lastname', this.openAccountUser.lastname)

        await api2.createAcronisTenant(createAcronisUserParams).then(response => {
          if (response.data && this.acronisTenantIdCheck.test(response.data)) {
            this.acronisUserLoading = false
            this.showCreateAcronisModal = false
            this.createConfirmationModal('Acronis User wurde erfolgreich erstellt')
            this.fetchAcronisUserStatus()
          } else if (response.data) {
            this.$notifyError(response.data)
            this.acronisUserLoading = false
            this.showCreateAcronisModal = false
          }
        }).catch(error => {
          this.$notifyError(error)
          this.showCreateAcronisModal = false
          this.acronisUserLoading = false
        })
      })
    },
    // /WP Implementation
    showUploadModal (show) {
      if (show) {
        if (this.$showIcon()) {
          this.showUpload = true
        }
      } else {
        this.showUpload = false
      }
    },
    getImage (image) {
      return (image || this.resource?.icon?.base64image)
    },
    async getIcons () {
      this.images = {
        zone: '',
        template: '',
        iso: '',
        domain: '',
        account: '',
        project: '',
        vpc: '',
        network: ''
      }
      if (this.resource.templateid) {
        await this.fetchResourceIcon(this.resource.templateid, 'template')
      }
      if (this.resource.isoid) {
        await this.fetchResourceIcon(this.resource.isoid, 'iso')
      }
      if (this.resource.zoneid) {
        await this.fetchResourceIcon(this.resource.zoneid, 'zone')
      }
      if (this.resource.domainid) {
        await this.fetchResourceIcon(this.resource.domainid, 'domain')
      }
      if (this.resource.account) {
        await this.fetchAccount()
      }
      if (this.resource.projectid) {
        await this.fetchResourceIcon(this.resource.projectid, 'project')
      }
      if (this.resource.vpcid) {
        await this.fetchResourceIcon(this.resource.vpcid, 'vpc')
      }
      if (this.resource.networkid) {
        await this.fetchResourceIcon(this.resource.networkid, 'network')
      }
    },
    fetchAccount () {
      return new Promise((resolve, reject) => {
        api('listAccounts', {
          name: this.resource.account,
          domainid: this.resource.domainid,
          showicon: true
        }).then(async json => {
          const response = json?.listaccountsresponse?.account || []
          if (response?.[0]?.icon) {
            this.images.account = response[0].icon.base64image
          }
        })
      })
    },
    fetchResourceIcon (resourceid, type) {
      if (resourceid) {
        return new Promise((resolve, reject) => {
          api('listResourceIcon', {
            resourceids: resourceid,
            resourcetype: type
          }).then(json => {
            const response = json.listresourceiconresponse.icon || []
            if (response?.[0]) {
              this.images[type] = response[0].base64image
              resolve(this.images)
            } else {
              this.images[type] = ''
              resolve(this.images)
            }
          }).catch(error => {
            reject(error)
          })
        })
      } else {
        this.images.type = ''
      }
    },
    // WP Implementation
    testMinMaxValue (max) {
      if (this.showAddLicenseModal && max) {
        if (this.addSoftwareLicensesAmount > max) {
          this.addSoftwareLicensesAmount = max
        } else if (this.addSoftwareLicensesAmount < 1) {
          this.addSoftwareLicensesAmount = 1
        }
      } else {
        var maxValue = this.modalInputTagType === 'Software per User' ? 1000 : this.resource.cpunumber
        if (this.showEditLicenseModal) {
          if (this.currentSoftwareLicenses < 1) {
            this.currentSoftwareLicenses = 1
          } else if (this.currentSoftwareLicenses > maxValue) {
            this.currentSoftwareLicenses = maxValue
          }
        }
      }
    },
    handleChange (value) {
      this.addSoftwareLicensesAmount = 1
      // set addSoftwareLicenses to 'noValue' if OS Tag is selected
      if (this.existingTags.filter(x => x.key === value)[0].types === 'OS') {
        this.addSoftwareLicensesAmount = 'noValue'
        // set addSoftwareLicenses to 1 if Software Package Tag is selected
      } else if (this.existingTags.filter(x => x.key === value)[0].types === 'Software Package') {
        this.addSoftwareLicensesAmount = 1
      }
      this.selectedTag = value
      this.submitTagInput = value
      this.errors.addLicenseInput.status = ''
      this.errors.addLicenseInput.message = ''
    },
    // get ALL possible Tags from the Tag DB
    getAllTagsApi () {
      wpapi.getAllTags().then(response => {
        var allTags = response.data
        var tempTransferInput = []
        for (var tag in allTags) {
          const data = {
            key: allTags[tag].id,
            title: allTags[tag].tagText,
            colors: allTags[tag].tagColor,
            types: allTags[tag].tagType
          }
          tempTransferInput.push(data)
        }
        this.existingTags = tempTransferInput
      })
    },
    getSnapshotLicenseTagInfos () {
      this.licenseLoading = true
      wpapi.fetchSnapshotLicenseTags(this.resource.id).then(response => {
        this.snapshotTags = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
      this.licenseLoading = false
    },
    getTemplateLicenseTagInfos () {
      this.licenseLoading = true
      wpapi.fetchTemplateLicenseTags(this.resource.id).then(response => {
        this.templateTags = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
      this.licenseLoading = false
    },
    openDeleteVMLicenseModal (tagName, tagColor, tagId) {
      this.modalInputTagColor = tagColor
      this.modalInputTagText = tagName
      this.modalInputTagId = tagId
      this.showDeleteLicenseModal = true
    },
    openEditVMLicensesModal (tagName, tagColor, tagType, tagId, currentLicenses) {
      this.currentSoftwareLicenses = currentLicenses
      this.modalInputTagColor = tagColor
      this.modalInputTagText = tagName
      this.modalInputTagId = tagId
      this.modalInputTagType = tagType
      this.showEditLicenseModal = true
    },
    openAddVMLicensesModal () {
      this.showAddLicenseModal = true
    },
    // WP Implementation
    async fetchResellerCustomerAccounts () {
      if (this.resource.account && this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.$route.path.startsWith('/vm/')) {
        if (this.resource.account !== this.accountName) {
          wpapi.getResellerSubAccounts(this.accountUuid).then(response => {
            this.subAccountUuid = response.data.filter(x => x.accountName === this.resource.account)[0].uuid
          }).catch(error => {
            console.log(error)
          })
        }
      }
    },
    // /WP Implementation
    async handleLicenseSubmit (submitType) {
      // if user === ResellerAdmin
      var params = new FormData()
      switch (submitType) {
        case 'add':
          if (!this.resource.templatetype) {
            if (!this.validateInput('addLicenseInput', this.addSoftwareLicensesAmount)) {
              return
            }
          }
          this.modalLoading = true
          if (['Admin'].includes(this.$store.getters.userInfo.roletype)) {
            // Case if admin changes VM Licenses
            // if admin adds to template
            if (this.resource.templatetype) {
              params.append('tagId', this.submitTagInput)
              params.append('templateUUID', this.resource.id)
              wpapi.addLicenseTagToTemplate(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            } else {
              // if resource is VM
              params.append('accountName', this.resource.account)
              if (this.addSoftwareLicensesAmount === 'noValue') {
                params.append('counter', 0)
                params.append('tagId', this.submitTagInput)
                params.append('templateId', this.resource.templateid)
                params.append('vmUUID', this.resource.id)
              } else {
                params.append('counter', this.addSoftwareLicensesAmount)
                params.append('tagId', this.submitTagInput)
                params.append('templateId', this.resource.templateid)
                params.append('vmUUID', this.resource.id)
              }
              // add other API here
              await wpapi.createTagEntryForVmByAdmin(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            }
          } else {
            // Case if User adds VM Licenses
            // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
            if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
              params.append('accountUuid', this.subAccountUuid)
            } else { params.append('accountUuid', this.accountUuid) }
            if (this.addSoftwareLicensesAmount === 'noValue') {
              params.append('counter', 0)
              params.append('tagId', this.submitTagInput)
              params.append('templateId', this.resource.templateid)
              params.append('vmUUID', this.resource.id)
            } else {
              params.append('counter', this.addSoftwareLicensesAmount)
              params.append('tagId', this.submitTagInput)
              params.append('templateId', this.resource.templateid)
              params.append('vmUUID', this.resource.id)
            }
            await wpapi.createTagEntryForVm(params).then(response => {
            }).catch(error => {
              this.$notifyError(error)
            })
            break
          }

        case 'delete':
          this.modalLoading = true
          if (['Admin'].includes(this.$store.getters.userInfo.roletype)) {
            // Case if Admin deletes VM Licenses
            if (this.resource.templatetype) {
              // if resource is Template
              params.append('tagId', this.modalInputTagId)
              params.append('templateUUID', this.resource.id)
              wpapi.deleteLicenseTagFromTemplate(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            } else {
              // if resource is VM
              params.append('accountName ', this.resource.account)
              params.append('tagId', this.modalInputTagId)
              params.append('vmUUID', this.resource.id)
              await wpapi.deleteVmTagFromVmDbAsAdmin(params).then(response => {
              }).catch(error => {
                this.$notifyError(error)
              })
              break
            }
          } else {
            // Case if User deletes VM Licenses
            // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
            if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
              params.append('accountUUID', this.subAccountUuid)
            } else { params.append('accountUUID', this.accountUuid) }
            params.append('tagId', this.modalInputTagId)
            params.append('vmUUID', this.resource.id)
            await wpapi.deleteVmTagFromVmDb(params).then(response => {
            }).catch(error => {
              this.$notifyError(error)
            })
          }
          break
        case 'edit':
          if (!this.validateInput('editLicenseInput', this.currentSoftwareLicenses)) {
            return
          }
          this.modalLoading = true
          if (['Admin'].includes(this.$store.getters.userInfo.roletype)) {
            // Case if Admin edits VM Licenses
            params.append('accountName', this.resource.account)
            params.append('counter', this.currentSoftwareLicenses)
            params.append('tagId', this.modalInputTagId)
            params.append('vmUUID', this.resource.id)
            wpapi.editVmLicenseTagInVmDbAsAdmin(params).then(response => {
            }).catch(error => {
              this.$notifyError(error)
            })
            break
          } else {
            // Case if User edits VM Licenses
            // if logged in user is a ResellerAdmin, use Account uuid of the VM Owner instead of accountUuid of the Account that is logged in currently
            if (this.$store.getters.userInfo.rolename === 'ResellerAdmin' && this.resource.account !== this.accountName) {
              params.append('accountUUID', this.subAccountUuid)
            } else { params.append('accountUUID', this.accountUuid) }
            params.append('counter', this.currentSoftwareLicenses)
            params.append('tagId', this.modalInputTagId)
            params.append('vmUUID', this.resource.id)
            wpapi.editVmLicenseTagInVmDb(params).then(response => {
              this.getAllTagsApi()
            }).catch(error => {
              this.$notifyError(error)
            })
            break
          }
      }
      this.reloadTagData()
      this.errors.addLicenseInput.status = ''
      this.errors.editLicenseInput.status = ''
    },
    /**
     * Close all possible Modals
     * @public
     */
    closeAction () {
      this.showAddLicenseModal = false
      this.showEditLicenseModal = false
      this.showDeleteLicenseModal = false
      this.showAddAccountModal = false
      this.showCreateAcronisModal = false
      this.modalInputTagColor = ''
      this.modalInputTagText = ''
      this.modalInputTagType = ''
      this.modalInputTagId = ''
      this.modalLoading = false
    },
    validateInput (input, value) {
      this.errors[input].status = ''
      this.errors[input].message = ''

      if (value === null || value === undefined || value.length === 0) {
        this.errors[input].status = 'error'
        this.errors[input].message = this.$t('message.error.required.input')
        return false
      } else return true
    },
    async reloadTagData () {
      await this.getTagsFromVm(this.resource.id)
      await this.fetchNrOfSoftwareLicencesAllVm(this.resource.id)
      if (this.resource.templatetype) {
        this.getTemplateLicenseTagInfos()
      }
      this.showAddLicenseModal = false
      this.showEditLicenseModal = false
      this.showDeleteLicenseModal = false
      this.modalInputTagColor = ''
      this.modalInputTagText = ''
      this.modalInputTagType = ''
      this.modalInputTagId = ''
      this.modalLoading = false
    },
    async fetchNrOfSoftwareLicencesAllVm (vmUuid) {
      this.licenseLoading = true
      var tags = this.vmLicenceTags
      var licenseList = []
      for (var tag in tags) {
        await wpapi.getSoftwareLicenceByVm(vmUuid, tags[tag].id).then(response => {
          var tempobject = { tagID: tags[tag].id, licenseCounter: response.data }
          licenseList.push(tempobject)
        })
      }
      this.nrOfSoftwareLicenses = licenseList
      this.licenseLoading = false
    },
    async getDiscountList () {
      await wpapi.discountListApi(this.accountUuid).then(response => {
        this.discountList = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
    },
    async getTagsFromVm (id) {
      this.licenseLoading = true
      await wpapi.getAllTagsForVM(id).then(response => {
        this.vmLicenceTags = response.data
      })
        .catch(error => {
          this.$notifyError(error)
        })
      this.licenseLoading = false
    },
    // /WP Implementation
    setData () {
      if (this.resource.nic && this.resource.nic.length > 0) {
        this.ipaddress = this.resource.nic.filter(e => { return e.ipaddress }).map(e => { return e.ipaddress }).join(', ')
      } else {
        this.ipaddress = this.resource.ipaddress
      }
    },
    toSize (kb) {
      if (!kb) {
        return '0 KB'
      }
      if (kb < 1024) {
        return kb + ' KB'
      }
      if (kb < 1024 * 1024) {
        return parseFloat(kb / 1024.0).toFixed(2) + ' MB'
      }
      return parseFloat(kb / (1024.0 * 1024.0)).toFixed(2) + ' GB'
    },
    getUserKeys () {
      if (!('getUserKeys' in this.$store.getters.apis)) {
        return
      }
      api('getUserKeys', { id: this.resource.id }).then(json => {
        this.showKeys = true
        this.newResource.secretkey = json.getuserkeysresponse.userkeys.secretkey
        this.$emit('change-resource', this.newResource)
      })
    },
    getTags () {
      if (!('listTags' in this.$store.getters.apis) || !this.resource || !this.resource.id) {
        return
      }
      this.loadingTags = true
      this.tags = []
      const params = {
        listall: true,
        resourceid: this.resource.id,
        resourcetype: this.resourceType
      }
      if (this.$route.meta.name === 'project') {
        params.projectid = this.resource.id
      }
      api('listTags', params).then(json => {
        if (json.listtagsresponse && json.listtagsresponse.tag) {
          this.tags = json.listtagsresponse.tag
        }
      }).finally(() => {
        this.loadingTags = false
      })
    },
    isAdminOrOwner () {
      return ['Admin'].includes(this.$store.getters.userInfo.roletype) ||
        (this.resource.domainid === this.$store.getters.userInfo.domainid && this.resource.account === this.$store.getters.userInfo.account) ||
        (this.resource.project && this.resource.projectid === this.$store.getters.project.id)
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },
    handleKeyChange (e) {
      this.inputKey = e.target.value
    },
    handleValueChange (e) {
      this.inputValue = e.target.value
    },
    handleInputConfirm () {
      if (this.inputKey === 'marketplace' && this.inputValue === 'true' && !['Admin'].includes(this.$store.getters.userInfo.roletype)) {
        this.tagReservedForAdmin = true
      } else {
        this.tagReservedForAdmin = false
        const args = {}
        this.loadingTags = true
        args.resourceids = this.resource.id
        args.resourcetype = this.resourceType
        args['tags[0].key'] = this.inputKey
        args['tags[0].value'] = this.inputValue
        api('createTags', args).then(json => {
        }).finally(e => {
          this.getTags()
        })

        this.inputVisible = false
        this.inputKey = ''
        this.inputValue = ''
      }
    },
    handleDeleteTag (tag) {
      const args = {}
      this.loadingTags = true
      args.resourceids = this.resource.id
      args.resourcetype = this.resourceType
      args['tags[0].key'] = tag.key
      args['tags[0].value'] = tag.value
      api('deleteTags', args).then(json => {
      }).finally(e => {
        this.getTags()
      })
    },
    setResourceOsType (name) {
      this.newResource.ostypename = name
      this.$emit('change-resource', this.newResource)
    },
    getRouterQuery (item) {
      const query = {}
      if (item.value) {
        query[item.param] = this.resource[item.value]
      } else {
        if (item.param === 'account') {
          query[item.param] = this.resource.name
          query.domainid = this.resource.domainid
        } else if (item.param === 'keypair') {
          query[item.param] = this.resource.name
        } else {
          query[item.param] = this.resource.id
        }
      }

      return query
    }
  }
}
</script>

<style lang="scss" scoped>
.cyberCloudLink:hover {
  color: #3D7CAF;
  cursor: pointer;
}
.iconToRight {
  margin-left: 4px;
}
.shieldIcon {
  width:1.5rem;
  height:1.7rem;
  margin-right: 10px;
}
.bold {
  font-weight: bold;
}
.tagToRight {
  margin-left: 8px;
}
.sliderAndInput {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
:deep(.ant-card-body) {
  padding: 30px;
}

.resource-details {
  text-align: center;
  margin-bottom: 20px;

  &__name {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 20px;
      overflow: hidden;
      min-width: 50px;
      cursor: pointer;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .name {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 1;
      word-break: break-all;
      text-align: left;
    }

  }
}
.resource-detail-item {
  margin-bottom: 20px;
  word-break: break-all;

  &__details {
    display: flex;
    align-items: center;

    &--start {
      align-items: flex-start;

      i {
        margin-top: 4px;
      }

    }

  }

  .anticon {
    margin-right: 10px;
  }

  &__label {
    margin-bottom: 5px;
    font-weight: bold;
  }

}
.user-keys {
  word-wrap: break-word;
}
.account-center-tags {
  .ant-tag {
    margin-bottom: 8px;
  }

  a {
    display: block;
    margin-bottom: 10px;
  }

}
.title {
  margin-bottom: 5px;
  font-weight: bold;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  //margin-top: 20px;
  margin-bottom: -10px;

  .ant-tag {
    margin-right: 10px;
    margin-bottom: 10px;
    height: auto;
  }

}

.progress-bar {
  padding-right: 60px;
  width: 100%;
}

.upload-icon {
  position: absolute;
  top: 70px;
  opacity: 0.75;
  left: 70px;
  font-size: 0.75em;
  padding: 0.25rem;
  background: rgba(247, 245, 245, 0.767);
  border-radius: 50%;
  border: 1px solid rgba(177, 177, 177, 0.788);
}
.resource-detail-item:empty {
  display: none;
}
</style>
